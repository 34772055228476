import { FC } from 'react';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import { blueGray400 } from '@guestyci/foundation/es/theme/colors';

import { ReactComponent as StarFilled } from '../../assets/star_filled.svg';
import { IListing } from '../../common/types';

const useStyles = createStyles(() => ({
  root: {
    minWidth: '65px',
    marginTop: 7,
  },
  gray: {
    color: blueGray400,
  },
}));

interface IListingRate {
  listing: IListing;
  className?: string;
}

export const ListingRate: FC<IListingRate> = ({ listing, className }) => {
  const { root, gray } = useStyles();
  const { total, avg } = listing?.reviews || {};
  
  if (!total) {
    return null;
  }

  return (
    <Row align="center" key={listing._id} className={cn(className, root)}>
      <TextField className={gray} variant="h7" color="secondary">{avg}</TextField>
      <StarFilled height={12} width={12} fontSize={12} className="ml-1 mr-1" />
      <TextField className={gray} variant="h7" color="secondary">{total ? `(${ total })` : ''}</TextField>
    </Row>
  );
};

export default ListingRate;

import { FC } from 'react';
import cn from 'classnames';
import createStyles from '@guestyci/foundation/createStyles';
import { IPicture } from '../../common/types';
import noImage from '../../assets/no_image.png';

interface IListingImage {
  pictures: IPicture[];
  name: string;
  isGridView?: boolean;
  className?: string;
}

interface IListingImageProps {
  isGridView: boolean;
}

const useStyles = createStyles(() => ({
  root: {
    width: ({ isGridView }: IListingImageProps) => isGridView ? 320 : 300,
    height: ({ isGridView }: IListingImageProps) => isGridView ? 303 : 200,
    overflow: 'hidden',
    display: 'flex',

    '& > img': {
      maxWidth: 'inherit',
      maxHeight: 'inherit',
      height: 'inherit',
      width: '100%',
      objectPosition: 'center center',
      objectFit: 'cover',
      borderRadius: 16,
    }
  },
}));

export const ListingImage: FC<IListingImage> = ({ pictures, name, isGridView = false, className = '' }) => {
  const classes = useStyles({ isGridView });
  const src = pictures[0]?.thumbnail;
  return (
    <div className={cn(classes.root, className)}>
      <img alt={name} src={src || noImage} />
    </div>
  );
};

export default ListingImage;

import React from 'react';
import { createRoot } from 'react-dom/client';
import qs from 'qs';

import '@guestyci/foundation/style/styles.css';

import App from './app/App';
import { IAppData } from './app/common/types';
import { getSupportedLocales } from './app/common/utils';

const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

const defaultData: IAppData = {
  elementId: queryParams?.elementId as string,
  locale: queryParams?.locale as string,
  siteId: queryParams?.siteId as string,
  widgetId: queryParams?.widgetId as string,
};

function OnlineBookingSolutionsLandingWidget({ rootElement = document.getElementById('root'), data = defaultData }: {
  rootElement: Element | null,
  data: IAppData,
}) {
  if (!rootElement) {
    throw new Error('Root element for OnlineBookingSolutionsLandingWidget is missing!');
  }

  const root = createRoot(rootElement);
  root.render(<App data={{
    ...data,
    locale: getSupportedLocales(data.locale),
  }} />);
}

export default OnlineBookingSolutionsLandingWidget;

export const translations = {
  en: {
    'Load more': 'Load more',
    'Sort by': 'Sort by',
    'Amenities: All': 'Amenities: All',
    'Property type: All': 'Property type: All',
    'Categories: All': 'Categories: All',
    'Bedrooms: All': 'Bedrooms: All',
    'Bathrooms: All': 'Bathrooms: All',
    'Price per night: All': 'Price per night: All',
    Max: 'Max',
    'Starting from': 'Starting from',
    'View prices': 'View prices',
    'Floor surface/Property size - high to low':
      'Floor surface/Property size - high to low',
    'Price - high to low': 'Price - high to low',
    'Price - low to high': 'Price - low to high',
    'Maximum occupancy - high to low': 'Maximum occupancy - high to low',
    'Count of bedrooms - high to low': 'Count of bedrooms - high to low',
    'Swimming pool': 'Swimming pool',
    'Accessible-height bed': 'Accessible-height bed',
    'Accessible-height toilet': 'Accessible-height toilet',
    'Air conditioning': 'Air conditioning',
    'Babysitter recommendations': 'Babysitter recommendations',
    'Baby bath': 'Baby bath',
    'Baby monitor': 'Baby monitor',
    Bathtub: 'Bathtub',
    'BBQ grill': 'BBQ grill',
    'Beach essentials': 'Beach essentials',
    'Bed linens': 'Bed linens',
    Breakfast: 'Breakfast',
    'Cable TV': 'Cable TV',
    'Carbon monoxide detector': 'Carbon monoxide detector',
    'Changing table': 'Changing table',
    'Children’s books and toys': 'Children’s books and toys',
    'Children’s dinnerware': 'Children’s dinnerware',
    'Cleaning before checkout': 'Cleaning before checkout',
    'Coffee maker': 'Coffee maker',
    'Cooking basics': 'Cooking basics',
    Crib: 'Crib',
    'Disabled parking spot': 'Disabled parking spot',
    'Dishes and silverware': 'Dishes and silverware',
    Dishwasher: 'Dishwasher',
    'Dog(s)': 'Dog(s)',
    Doorman: 'Doorman',
    Dryer: 'Dryer',
    'Elevator in building': 'Elevator in building',
    Essentials: 'Essentials',
    'Ethernet connection': 'Ethernet connection',
    'EV charger': 'EV charger',
    'Extra pillows and blankets': 'Extra pillows and blankets',
    'Fireplace guards': 'Fireplace guards',
    'Fire extinguisher': 'Fire extinguisher',
    'Firm mattress': 'Firm mattress',
    'First aid kit': 'First aid kit',
    'Flat smooth pathway to front door': 'Flat smooth pathway to front door',
    'Free parking on premises': 'Free parking on premises',
    'Game console': 'Game console',
    'Garden or backyard': 'Garden or backyard',
    'Grab-rails for shower and toilet': 'Grab-rails for shower and toilet',
    Gym: 'Gym',
    'Hair dryer': 'Hair dryer',
    Hangers: 'Hangers',
    Heating: 'Heating',
    'High chair': 'High chair',
    'Hot tub': 'Hot tub',
    'Hot water': 'Hot water',
    'Indoor fireplace': 'Indoor fireplace',
    Internet: 'Internet',
    Iron: 'Iron',
    Kitchen: 'Kitchen',
    'Laptop friendly workspace': 'Laptop friendly workspace',
    'Long term stays allowed': 'Long term stays allowed',
    'Luggage dropoff allowed': 'Luggage dropoff allowed',
    Microwave: 'Microwave',
    'Outlet covers': 'Outlet covers',
    Oven: 'Oven',
    'Pack ’n Play/travel crib': 'Pack ’n Play/travel crib',
    'Path to entrance lit at night': 'Path to entrance lit at night',
    'Patio or balcony': 'Patio or balcony',
    'Pets allowed': 'Pets allowed',
    'Pets live on this property': 'Pets live on this property',
    'Pocket wifi': 'Pocket wifi',
    Pool: 'Pool',
    'Private entrance': 'Private entrance',
    Refrigerator: 'Refrigerator',
    'Roll-in shower with shower bench or chair':
      'Roll-in shower with shower bench or chair',
    'Room-darkening shades': 'Room-darkening shades',
    Shampoo: 'Shampoo',
    'Single level home': 'Single level home',
    'Smoke detector': 'Smoke detector',
    'Smoking allowed': 'Smoking allowed',
    'Stair gates': 'Stair gates',
    'Step-free access': 'Step-free access',
    Stove: 'Stove',
    'Suitable for children (2-12 years)': 'Suitable for children (2-12 years)',
    'Suitable for infants (under 2 years)':
      'Suitable for infants (under 2 years)',
    'Table corner guards': 'Table corner guards',
    'Tub with shower bench': 'Tub with shower bench',
    Washer: 'Washer',
    'Wide clearance to bed': 'Wide clearance to bed',
    'Wide clearance to shower and toilet':
      'Wide clearance to shower and toilet',
    'Wide doorway': 'Wide doorway',
    'Wide hallway clearance': 'Wide hallway clearance',
    'Window guards': 'Window guards',
    'Wireless Internet': 'Wireless Internet',
    'Grab-rails in toilet': 'Grab-rails in toilet',
    'Shower bench': 'Shower bench',
    'Shower chair': 'Shower chair',
    'Wheelchair accessible': 'Wheelchair accessible',
    'Body soap': 'Body soap',
    'Cleaning products': 'Cleaning products',
    Conditioner: 'Conditioner',
    'Shower gel': 'Shower gel',
    'Towels provided': 'Towels provided',
    'Clothing storage': 'Clothing storage',
    'Coin Laundry': 'Coin Laundry',
    'Dryer in common space': 'Dryer in common space',
    'Mosquito net': 'Mosquito net',
    'Washer in common space': 'Washer in common space',
    'DVD player': 'DVD player',
    'Foosball table': 'Foosball table',
    'Game room': 'Game room',
    Piano: 'Piano',
    'Ping pong table': 'Ping pong table',
    'Pool table': 'Pool table',
    'Sound system': 'Sound system',
    'Board games': 'Board games',
    'Family/kid friendly': 'Family/kid friendly',
    'Portable fans': 'Portable fans',
    'Emergency exit': 'Emergency exit',
    'Baking sheet': 'Baking sheet',
    'Barbeque utensils': 'Barbeque utensils',
    Blender: 'Blender',
    Coffee: 'Coffee',
    Cookware: 'Cookware',
    'Dining table': 'Dining table',
    Freezer: 'Freezer',
    'Ice maker': 'Ice maker',
    Kettle: 'Kettle',
    'Mini fridge': 'Mini fridge',
    'Rice maker': 'Rice maker',
    Toaster: 'Toaster',
    'Trash compactor': 'Trash compactor',
    'Wine glasses': 'Wine glasses',
    Beach: 'Beach',
    'Beach Front': 'Beach Front',
    'Beach View': 'Beach View',
    'Beach access': 'Beach access',
    'City View': 'City View',
    'Desert View': 'Desert View',
    Downtown: 'Downtown',
    'Garden View': 'Garden View',
    'Golf course front': 'Golf course front',
    'Golf view': 'Golf view',
    Lake: 'Lake',
    'Lake Front': 'Lake Front',
    'Lake access': 'Lake access',
    'Laundromat nearby': 'Laundromat nearby',
    Mountain: 'Mountain',
    'Mountain view': 'Mountain view',
    'Near Ocean': 'Near Ocean',
    'Ocean Front': 'Ocean Front',
    Resort: 'Resort',
    'Resort access': 'Resort access',
    Rural: 'Rural',
    'Sea view': 'Sea view',
    'Ski In': 'Ski In',
    'Ski Out': 'Ski Out',
    'Ski in/Ski out': 'Ski in/Ski out',
    Town: 'Town',
    Village: 'Village',
    'Water View': 'Water View',
    Waterfront: 'Waterfront',
    'Cleaning Disinfection': 'Cleaning Disinfection',
    Desk: 'Desk',
    'Enhanced cleaning practices': 'Enhanced cleaning practices',
    'High touch surfaces disinfected': 'High touch surfaces disinfected',
    'Bicycles available': 'Bicycles available',
    Bikes: 'Bikes',
    'Boat slip': 'Boat slip',
    'Fire Pit': 'Fire Pit',
    Hammock: 'Hammock',
    Kayak: 'Kayak',
    'Outdoor kitchen': 'Outdoor kitchen',
    'Outdoor seating (furniture)': 'Outdoor seating (furniture)',
    River: 'River',
    'Free parking on street': 'Free parking on street',
    Garage: 'Garage',
    'Paid parking': 'Paid parking',
    'Paid parking off premises': 'Paid parking off premises',
    'Communal pool': 'Communal pool',
    'Indoor pool': 'Indoor pool',
    'Outdoor pool': 'Outdoor pool',
    'Private pool': 'Private pool',
    'Rooftop pool': 'Rooftop pool',
    Elevator: 'Elevator',
    TV: 'TV',
    'Ceiling fan': 'Ceiling fan',
    'Pocket Wifi': 'Pocket Wifi',
    Safe: 'Safe',
    'Stereo system': 'Stereo system',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Casinos',
    Cycling: 'Cycling',
    Fishing: 'Fishing',
    'Golf - Optional': 'Golf - Optional',
    'Horseback Riding': 'Horseback Riding',
    'Mountain Climbing': 'Mountain Climbing',
    Museums: 'Museums',
    'Rock Climbing': 'Rock Climbing',
    Shopping: 'Shopping',
    'Theme Parks': 'Theme Parks',
    'Water Parks': 'Water Parks',
    'Water Sports': 'Water Sports',
    Zoo: 'Zoo',
    Apartment: 'Apartment',
    House: 'House',
    Loft: 'Loft',
    Boat: 'Boat',
    'Camper/RV': 'Camper/RV',
    Condominium: 'Condominium',
    Chalet: 'Chalet',
    'Bed & Breakfast': 'Bed & Breakfast',
    Villa: 'Villa',
    Tent: 'Tent',
    Other: 'Other',
    Cabin: 'Cabin',
    Townhouse: 'Townhouse',
    Bungalow: 'Bungalow',
    Hut: 'Hut',
    Dorm: 'Dorm',
    'Parking Space': 'Parking Space',
    Plane: 'Plane',
    Treehouse: 'Treehouse',
    Yurt: 'Yurt',
    Tipi: 'Tipi',
    Igloo: 'Igloo',
    'Earth House': 'Earth House',
    Island: 'Island',
    Cave: 'Cave',
    Castle: 'Castle',
    Studio: 'Studio',
    Cottage: 'Cottage',
  },
  de: {
    'Load more': 'Mehr laden',
    'Sort by': 'Sortieren nach',
    'Amenities: All': 'Ausstattungsmerkmale: Alle',
    'Property type: All': 'Immobilientyp: Alle',
    'Categories: All': 'Kategorien: Alle',
    'Bedrooms: All': 'Schlafzimmer: Alle',
    'Bathrooms: All': 'Badezimmer: Alle',
    'Price per night: All': 'Preis pro Nacht: Alle',
    Max: 'Max',
    'Starting from': 'Ab',
    'View prices': 'Preise anzeigen',
    'Floor surface/Property size - high to low':
      'Grundfläche/Immobiliengröße – absteigend',
    'Price - high to low': 'Preis – absteigend',
    'Price - low to high': 'Preis – aufsteigend',
    'Maximum occupancy - high to low': 'Maximale Belegung – absteigend',
    'Count of bedrooms - high to low': 'Anzahl Schlafzimmer – absteigend',
    'Swimming pool': 'Schwimmbad',
    'Accessible-height bed': 'Bett in komfortabler Höhe',
    'Accessible-height toilet': 'Toilette in komfortabler Höhe',
    'Air conditioning': 'Klimaanlage',
    'Babysitter recommendations': 'Babysitter-Empfehlungen',
    'Baby bath': 'Baby-Badewanne',
    'Baby monitor': 'Babyphone',
    Bathtub: 'Badewanne',
    'BBQ grill': 'Barbecue-Grill',
    'Beach essentials': 'Strandzubehör',
    'Bed linens': 'Bettwäsche',
    Breakfast: 'Frühstück',
    'Cable TV': 'Kabelfernsehen',
    'Carbon monoxide detector': 'Kohlenmonoxidmelder',
    'Changing table': 'Wickeltisch',
    'Children’s books and toys': 'Kinderbücher und Spielzeug',
    'Children’s dinnerware': 'Kinder-Geschirr',
    'Cleaning before checkout': 'Reinigung vor der Abreise',
    'Coffee maker': 'Kaffeemaschine',
    'Cooking basics': 'Küchenutensilien',
    Crib: 'Babybett',
    'Disabled parking spot': 'Behinderten-Parkplatz',
    'Dishes and silverware': 'Geschirr und Besteck',
    Dishwasher: 'Spülmaschine',
    'Dog(s)': 'Hund(e)',
    Doorman: 'Portier',
    Dryer: 'Trockner',
    'Elevator in building': 'Aufzug im Gebäude',
    Essentials: 'Notwendiges',
    'Ethernet connection': 'Ethernet-Verbindung',
    'EV charger': 'EV-Ladegerät',
    'Extra pillows and blankets': 'Zusätzliche Kissen und Decken',
    'Fireplace guards': 'Kaminschutzgitter',
    'Fire extinguisher': 'Feuerlöscher',
    'Firm mattress': 'Feste Matratze',
    'First aid kit': 'Erste-Hilfe-Set',
    'Flat smooth pathway to front door': 'Flacher, ebener Weg zur Haustür',
    'Free parking on premises': 'Kostenlose Parkplätze auf dem Gelände',
    'Game console': 'Spielkonsole',
    'Garden or backyard': 'Garten oder Hinterhof',
    'Grab-rails for shower and toilet': 'Haltegriffe für Dusche und Toilette',
    Gym: 'Fitnessstudio',
    'Hair dryer': 'Haartrockner',
    Hangers: 'Kleiderbügel',
    Heating: 'Heizung',
    'High chair': 'Hochstuhl',
    'Hot tub': 'Whirlpool',
    'Hot water': 'Warmwasser',
    'Indoor fireplace': 'Innenkamin',
    Internet: 'Internet',
    Iron: 'Bügeleisen',
    Kitchen: 'Küche',
    'Laptop friendly workspace': 'Laptop-freundlicher Arbeitsplatz',
    'Long term stays allowed': 'Langzeitaufenthalte gestattet',
    'Luggage dropoff allowed': 'Gepäckabgabe gestattet',
    Microwave: 'Mikrowelle',
    'Outlet covers': 'Steckdosenabdeckungen',
    Oven: 'Ofen',
    'Pack ’n Play/travel crib': "Pack'n Play/Reise-Kinderbett",
    'Path to entrance lit at night': 'Weg zum Eingang nachts beleuchtet',
    'Patio or balcony': 'Patio oder Balkon',
    'Pets allowed': 'Haustiere gestattet',
    'Pets live on this property': 'In der Immobilie leben Haustiere',
    'Pocket wifi': 'Pocket WiFi',
    Pool: 'Pool',
    'Private entrance': 'Privateingang',
    Refrigerator: 'Kühlschrank',
    'Roll-in shower with shower bench or chair':
      'Barrierefreie Dusche mit Duschbank oder -stuhl',
    'Room-darkening shades': 'Raumverdunkelnde Rollladen',
    Shampoo: 'Shampoo',
    'Single level home': 'Einstöckiges Haus',
    'Smoke detector': 'Rauchmelder',
    'Smoking allowed': 'Rauchen gestattet',
    'Stair gates': 'Treppenschutzgitter',
    'Step-free access': 'Stufenloser Zugang',
    Stove: 'Herd',
    'Suitable for children (2-12 years)': 'Für Kinder geeignet (2-12 Jahre)',
    'Suitable for infants (under 2 years)':
      'Für Kleinkinder geeignet (unter 2 Jahren)',
    'Table corner guards': 'Tischkantenschutz',
    'Tub with shower bench': 'Wanne mit Duschbank',
    Washer: 'Waschmaschine',
    'Wide clearance to bed': 'Weiter Abstand zum Bett',
    'Wide clearance to shower and toilet':
      'Weiter Abstand zur Dusche und Toilette',
    'Wide doorway': 'Breite Türöffnung',
    'Wide hallway clearance': 'Breiter Flur',
    'Window guards': 'Fenstergitter',
    'Wireless Internet': 'WLAN',
    'Grab-rails in toilet': 'Haltegriffe für WC',
    'Shower bench': 'Duschbank',
    'Shower chair': 'Duschstuhl',
    'Wheelchair accessible': 'Zugänglich für Rollstuhlfahrer',
    'Body soap': 'Körperseife',
    'Cleaning products': 'Reinigungsprodukte',
    Conditioner: 'Pflegespülung',
    'Shower gel': 'Duschgel',
    'Towels provided': 'Handtücher vorhanden',
    'Clothing storage': 'Aufbewahrung von Kleidung',
    'Coin Laundry': 'Münzwäscherei',
    'Dryer in common space': 'Trockner im Gemeinschaftsbereich',
    'Mosquito net': 'Moskitonetz',
    'Washer in common space': 'Waschmaschine im Gemeinschaftsbereich',
    'DVD player': 'DVD-Player',
    'Foosball table': 'Tischfußball',
    'Game room': 'Spielzimmer',
    Piano: 'Klavier',
    'Ping pong table': 'Tischtennisplatte',
    'Pool table': 'Billardtisch',
    'Sound system': 'Stereoanlage',
    'Board games': 'Brettspiele',
    'Family/kid friendly': 'Familien-/Kinderfreundlich',
    'Portable fans': 'Tragbare Ventilatoren',
    'Emergency exit': 'Notausgang',
    'Baking sheet': 'Backblech',
    'Barbeque utensils': 'Grillutensilien',
    Blender: 'Mixer',
    Coffee: 'Kaffee',
    Cookware: 'Kochgeschirr',
    'Dining table': 'Esstisch',
    Freezer: 'Gefriertruhe',
    'Ice maker': 'Eismaschine',
    Kettle: 'Wasserkocher',
    'Mini fridge': 'Mini-Kühlschrank',
    'Rice maker': 'Reiskocher',
    Toaster: 'Toaster',
    'Trash compactor': 'Abfallpresse',
    'Wine glasses': 'Weingläser',
    Beach: 'Strand',
    'Beach Front': 'Strandnähe',
    'Beach View': 'Blick auf den Strand',
    'Beach access': 'Zugang zum Strand',
    'City View': 'Blick auf die Stadt',
    'Desert View': 'Wüstenblick',
    Downtown: 'Innenstadt',
    'Garden View': 'Blick auf den Garten',
    'Golf course front': 'Golfplatzfront',
    'Golf view': 'Golfblick',
    Lake: 'See',
    'Lake Front': 'Seefront',
    'Lake access': 'Zugang zum See',
    'Laundromat nearby': 'Wäscherei in der Nähe',
    Mountain: 'Berg',
    'Mountain view': 'Bergblick',
    'Near Ocean': 'Nähe zum Meer',
    'Ocean Front': 'Ozeanfront',
    Resort: 'Resort',
    'Resort access': 'Zugang zum Resort',
    Rural: 'Ländlich',
    'Sea view': 'Meerblick',
    'Ski In': 'Ski-In',
    'Ski Out': 'Ski-Out',
    'Ski in/Ski out': 'Ski-In/Ski-Out',
    Town: 'Stadt',
    Village: 'Dorf',
    'Water View': 'Wasserblick',
    Waterfront: 'Wasserfront',
    'Cleaning Disinfection': 'Desinfizierende Reinigung',
    Desk: 'Schreibtisch',
    'Enhanced cleaning practices': 'Erweiterte Reinigungspraktiken',
    'High touch surfaces disinfected': 'Desinfizierte häufig berührte Flächen',
    'Bicycles available': 'Fahrräder erhältlich',
    Bikes: 'Fahrräder',
    'Boat slip': 'Bootsplatz',
    'Fire Pit': 'Feuerstelle',
    Hammock: 'Hängematte',
    Kayak: 'Kajak',
    'Outdoor kitchen': 'Outdoor-Küche',
    'Outdoor seating (furniture)': 'Sitzgelegenheiten im Freien (Möbel)',
    River: 'Fluss',
    'Free parking on street': 'Kostenlose Parkplätze auf der Straße',
    Garage: 'Garage',
    'Paid parking': 'Bezahltes Parken',
    'Paid parking off premises': 'Bezahltes Parken außerhalb des Geländes',
    'Communal pool': 'Gemeinschaftspool',
    'Indoor pool': 'Innenpool',
    'Outdoor pool': 'Außenpool',
    'Private pool': 'Privater Pool',
    'Rooftop pool': 'Dachpool',
    Elevator: 'Aufzug',
    TV: 'TV',
    'Ceiling fan': 'Deckenventilator',
    'Pocket Wifi': 'Pocket-WLAN',
    Safe: 'Safe',
    'Stereo system': 'Stereoanlage',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Casinos',
    Cycling: 'Radfahren',
    Fishing: 'Angeln',
    'Golf - Optional': 'Golf – optional',
    'Horseback Riding': 'Reiten',
    'Mountain Climbing': 'Bergsteigen',
    Museums: 'Museen',
    'Rock Climbing': 'Klettern',
    Shopping: 'Einkaufen',
    'Theme Parks': 'Freizeitparks',
    'Water Parks': 'Wasserparks',
    'Water Sports': 'Wassersport',
    Zoo: 'Zoo',
    Apartment: 'Wohnung',
    House: 'Haus',
    Loft: 'Loft',
    Boat: 'Boot',
    'Camper/RV': 'Wohnmobil/Campingbus',
    Condominium: 'Eigentumswohnung',
    Chalet: 'Chalet',
    'Bed & Breakfast': 'Zimmer mit Frühstück',
    Villa: 'Villa',
    Tent: 'Zelt',
    Other: 'Sonstiges',
    Cabin: 'Kabine',
    Townhouse: 'Stadthaus',
    Bungalow: 'Bungalow',
    Hut: 'Hütte',
    Dorm: 'Mehrbettzimmer',
    'Parking Space': 'Parkplatz',
    Plane: 'Flugzeug',
    Treehouse: 'Baumhaus',
    Yurt: 'Jurte',
    Tipi: 'Tipi',
    Igloo: 'Iglu',
    'Earth House': 'Erdhaus',
    Island: 'Insel',
    Cave: 'Höhle',
    Castle: 'Schloss',
    Studio: 'Studio',
    Cottage: 'Hütte',
  },
  el: {
    'Load more': 'Φορτώστε περισσότερα',
    'Sort by': 'Ταξινόμηση κατά',
    'Amenities: All': 'Παροχές: Όλες',
    'Property type: All': 'Τύπος καταλύματος: Όλα',
    'Categories: All': 'Κατηγορίες: Όλες',
    'Bedrooms: All': 'Υπνοδωμάτια: Όλα',
    'Bathrooms: All': 'Μπάνια: Όλα',
    'Price per night: All': 'Τιμή ανά διανυκτέρευση: Όλες',
    Max: 'Μέγιστο',
    'Starting from': 'Από',
    'View prices': 'Εμφάνιση τιμών',
    'Floor surface/Property size - high to low':
      'Εμβαδόν/Μέγεθος καταλύματος - από το υψηλότερο στο χαμηλότερο',
    'Price - high to low': 'Τιμή - από την υψηλότερη στη χαμηλότερη',
    'Price - low to high': 'Τιμή - από τη χαμηλότερη στην υψηλότερη',
    'Maximum occupancy - high to low':
      'Μέγιστη χωρητικότητα - από την υψηλότερη στη χαμηλότερη',
    'Count of bedrooms - high to low':
      'Αριθμός υπνοδωματίων - από τον υψηλότερο στον χαμηλότερο',

    'Accessible-height bed': 'Κρεβάτι προσιτού ύψους',
    'Accessible-height toilet': 'Τουαλέτα προσιτού ύψους',
    'Air conditioning': 'Κλιματισμός',
    'Babysitter recommendations': 'Συστάσεις για μπέιμπι σίτερ',
    'Baby bath': 'Μπανιέρα για μωρά',
    'Baby monitor': 'Βρεφικό μόνιτορ',
    Bathtub: 'Μπανιέρα',
    'BBQ grill': 'Ψησταριά',
    'Beach essentials': 'Τα απαραίτητα για την παραλία',
    'Bed linens': 'Κλινοσκεπάσματα',
    Breakfast: 'Πρωινό',
    'Cable TV': 'Καλωδιακή τηλεόραση',
    'Carbon monoxide detector': 'Ανιχνευτής μονοξειδίου του άνθρακα',
    'Changing table': 'Αλλαξιέρα',
    'Children’s books and toys': 'Παιδικά βιβλία και παιχνίδια',
    'Children’s dinnerware': 'Παιδικά σερβίτσια',
    'Cleaning before checkout': 'Καθαρισμός πριν από το checkout',
    'Coffee maker': 'Καφετιέρα',
    'Cooking basics': 'Βασικά είδη μαγειρέματος',
    Crib: 'Βρεφική κούνια',
    'Disabled parking spot': 'Σημείο στάθμευσης για άτομα με αναπηρίες',
    'Dishes and silverware': 'Πιάτα και μαχαιροπίρουνα',
    Dishwasher: 'Πλυντήριο πιάτων',
    'Dog(s)': 'Σκύλοι',
    Doorman: 'Θυρωρός',
    Dryer: 'Στεγνωτήριο',
    'Elevator in building': 'Ανελκυστήρας στο κτίριο',
    Essentials: 'Είδη πρώτης ανάγκης',
    'Ethernet connection': 'Σύνδεση Ethernet',
    'EV charger': 'Φορτιστής ΗΟ',
    'Extra pillows and blankets': 'Επιπλέον μαξιλάρια και κουβέρτες',
    'Fireplace guards': 'Προστατευτικά τζακιού',
    'Fire extinguisher': 'Πυροσβεστήρας',
    'Firm mattress': 'Στρώμα αέρα',
    'First aid kit': 'Κουτί πρώτων βοηθειών',
    'Flat smooth pathway to front door':
      'Επίπεδη ομαλή διαδρομή έως την μπροστινή πόρτα',
    'Free parking on premises': 'Δωρεάν στάθμευση στις εγκαταστάσεις',
    'Game console': 'Κονσόλα παιχνιδιών',
    'Garden or backyard': 'Κήπος ή αυλή',
    'Grab-rails for shower and toilet': 'Λαβές μπάνιου και τουαλέτας',
    Gym: 'Γυμναστήριο',
    'Hair dryer': 'Πιστολάκι για τα μαλλιά',
    Hangers: 'Κρεμάστρες',
    Heating: 'Θέρμανση',
    'High chair': 'Παιδικό καρεκλάκι',
    'Hot tub': 'Υδρομασάζ',
    'Hot water': 'Ζεστό νερό',
    'Indoor fireplace': 'Εσωτερικό τζάκι',
    Internet: 'Διαδίκτυο',
    Iron: 'Σίδερο',
    Kitchen: 'Κουζίνα',
    'Laptop friendly workspace':
      'Χώρος εργασίας κατάλληλος για φορητούς υπολογιστές',
    'Long term stays allowed': 'Επιτρέπεται η μακροπρόθεσμη διαμονή',
    'Luggage dropoff allowed': 'Επιτρέπεται να αφήσετε τις αποσκευές σας',
    Microwave: 'Φούρνος μικροκυμάτων',
    'Outlet covers': 'Καλύμματα πρίζας',
    Oven: 'Φούρνος',
    'Pack ’n Play/travel crib': 'Πάρκο/παρκοκρέβατο',
    'Path to entrance lit at night':
      'Η διαδρομή προς την είσοδο φωτίζεται το βράδυ',
    'Patio or balcony': 'Βεράντα ή μπαλκόνι',
    'Pets allowed': 'Επιτρέπονται τα κατοικίδια',
    'Pets live on this property': 'Ζουν κατοικίδια σε αυτό το κατάλυμα',
    'Pocket wifi': 'Pocket wifi',
    Pool: 'Πισίνα',
    'Private entrance': 'Ιδιωτική είσοδος',
    Refrigerator: 'Ψυγείο',
    'Roll-in shower with shower bench or chair':
      'Επίπεδο ντους με πάγκο ή καρέκλα ντους',
    'Room-darkening shades': 'Στόρια',
    Shampoo: 'Σαμπουάν',
    'Single level home': 'Ισόγεια κατοικία',
    'Smoke detector': 'Ανιχνευτής καπνού',
    'Smoking allowed': 'Επιτρέπεται το κάπνισμα',
    'Stair gates': 'Πύλες ασφαλείας για τις σκάλες',
    'Step-free access': 'Πρόσβαση χωρίς σκαλοπάτια',
    Stove: 'Κουζίνα',
    'Suitable for children (2-12 years)': 'Κατάλληλο για παιδιά (2-12 ετών)',
    'Suitable for infants (under 2 years)':
      'Κατάλληλο για βρέφη (κάτω των 2 ετών)',
    'Table corner guards': 'Προστατευτικά γωνιών τραπεζιού',
    'Tub with shower bench': 'Μπανιέρα με πάγκο ντους',
    Washer: 'Πλυντήριο',
    'Wide clearance to bed': 'Άφθονος χώρος γύρω από το κρεβάτι',
    'Wide clearance to shower and toilet':
      'Άφθονος χώρος σε ντους και τουαλέτα',
    'Wide doorway': 'Μεγάλη πόρτα',
    'Wide hallway clearance': 'Φαρδύς διάδρομος',
    'Window guards': 'Προστατευτικά παραθύρων',
    'Wireless Internet': 'Ασύρματο ίντερνετ',
    Cookware: 'Μαγειρικά σκεύη',
    Elevator: 'Ανελκυστήρας',
    TV: 'Τηλεόραση',
    Apartment: 'Διαμέρισμα',
    House: 'Σπίτι',
    Loft: 'Λοφτ',
    Boat: 'Σκάφος',
    'Camper/RV': 'Τροχόσπιτο/Όχημα αναψυχής',
    Condominium: 'Συγκρότημα κατοικιών',
    Chalet: 'Σαλέ',
    'Bed & Breakfast': 'Bed & Breakfast',
    Villa: 'Βίλα',
    Tent: 'Σκηνή',
    Other: 'Άλλο',
    Cabin: 'Ξύλινη καλύβα',
    Townhouse: 'Εκατέρωθεν εφαπτόμενη μεζονέτα',
    Bungalow: 'Μπάνγκαλοου',
    Hut: 'Καλύβα',
    Dorm: 'Κοιτώνας',
    'Parking Space': 'Χώρος στάθμευσης',
    Plane: 'Αεροπλάνο',
    Treehouse: 'Δεντρόσπιτο',
    Yurt: 'Yurt (παραδοσιακή μογγολική σκηνή)',
    Tipi: 'Ινδιάνικη σκηνή',
    Igloo: 'Ιγκλού',
    'Earth House': 'Σπίτι μέσα στο έδαφος',
    Island: 'Νησί',
    Cave: 'Σπήλαιο',
    Castle: 'Κάστρο',
    Studio: 'Στούντιο',
    Cottage: 'Εξοχικό σπίτι',
  },
  it: {
    'Load more': 'Carica altro',
    'Sort by': 'Ordina per',
    'Amenities: All': 'Servizi: Tutto',
    'Property type: All': 'Tipo di proprietà: Tutto',
    'Categories: All': 'Categorie: Tutto',
    'Bedrooms: All': 'Camere da letto: Tutto',
    'Bathrooms: All': 'Bagni: Tutto',
    'Price per night: All': 'Prezzo per notte: Tutto',
    Max: 'Max',
    'Starting from': 'A partire da',
    'View prices': 'Visualizza prezzi',
    'Floor surface/Property size - high to low':
      'Superficie/Dimensioni della proprietà: dalla più grande alla più piccola',
    'Price - high to low': 'Prezzo: dal più alto al più basso',
    'Price - low to high': 'Prezzo: dal più basso al più alto',
    'Maximum occupancy - high to low':
      'Numero max di persone: dal più alto al più basso',
    'Count of bedrooms - high to low':
      'Numero di camere da letto: dal più alto al più basso',

    'Swimming pool': 'Piscina',
    'Accessible-height bed': 'Letto ad altezza accessibile',
    'Accessible-height toilet': 'Servizi igienici ad altezza accessibile',
    'Air conditioning': 'Aria condizionata',
    'Babysitter recommendations': 'Baby sitter consigliate',
    'Baby bath': 'Vaschetta per il bagnetto',
    'Baby monitor': 'Baby monitor',
    Bathtub: 'Vasca da bagno',
    'BBQ grill': 'Griglia per barbecue',
    'Beach essentials': 'Accessori spiaggia',
    'Bed linens': 'Biancheria da letto',
    Breakfast: 'Colazione',
    'Cable TV': 'TV via cavo',
    'Carbon monoxide detector': 'Rilevatore di monossido di carbonio',
    'Changing table': 'Fasciatoio',
    'Children’s books and toys': 'Libri e giocattoli per bambini',
    'Children’s dinnerware': 'Stoviglie per bambini',
    'Cleaning before checkout': 'Pulizia prima del check out',
    'Coffee maker': 'Macchina per il caffè',
    'Cooking basics': 'Servizi di base per cucinare',
    Crib: 'Culla',
    'Disabled parking spot': 'Posto auto per disabili',
    'Dishes and silverware': 'Piatti e posate',
    Dishwasher: 'Lavastoviglie',
    'Dog(s)': 'Cani',
    Doorman: 'Portineria',
    Dryer: 'Asciugatrice',
    'Elevator in building': 'Ascensore nella palazzina',
    Essentials: 'Servizi essenziali',
    'Ethernet connection': 'Connessione Ethernet',
    'EV charger': 'Stazione di ricarica EV',
    'Extra pillows and blankets': 'Coperte e cuscini extra',
    'Fireplace guards': 'Parascintille camino',
    'Fire extinguisher': 'Estintore',
    'Firm mattress': 'Materasso rigido',
    'First aid kit': 'Kit di primo soccorso',
    'Flat smooth pathway to front door':
      "Vialetto di accesso alla porta d'ingresso liscio e pianeggiante",
    'Free parking on premises': 'Parcheggio gratuito in sede',
    'Game console': 'Console videogiochi',
    'Garden or backyard': 'Giardino o cortile',
    'Grab-rails for shower and toilet': 'Maniglione doccia e servizi igienici',
    Gym: 'Palestra',
    'Hair dryer': 'Asciugacapelli',
    Hangers: 'Appendiabiti',
    Heating: 'Riscaldamento',
    'High chair': 'Seggiolone',
    'Hot tub': 'Vasca idromassaggio',
    'Hot water': 'Acqua calda',
    'Indoor fireplace': 'Caminetto interno',
    Internet: 'Internet',
    Iron: 'Ferro da stiro',
    Kitchen: 'Cucina',
    'Laptop friendly workspace': 'Area di lavoro adatta ai computer portatili',
    'Long term stays allowed': 'Sono consentiti soggiorni di lunga durata',
    'Luggage dropoff allowed': 'È consentito il deposito dei bagagli',
    Microwave: 'Forno a microonde',
    'Outlet covers': 'Copriprese',
    Oven: 'Forno',
    'Pack ’n Play/travel crib': 'Box/lettino da viaggio',
    'Path to entrance lit at night':
      "Vialetto d'ingresso con illuminazione notturna",
    'Patio or balcony': 'Patio o balcone',
    'Pets allowed': 'Sono ammessi animali domestici',
    'Pets live on this property': 'Animali domestici presenti nella proprietà',
    'Pocket wifi': 'Wi-Fi portatile',
    Pool: 'Piscina',
    'Private entrance': 'Ingresso indipendente',
    Refrigerator: 'Frigorifero',
    'Roll-in shower with shower bench or chair':
      'Doccia accessibile corredata da panca o sedia',
    'Room-darkening shades': 'Tende oscuranti in camera',
    Shampoo: 'Shampoo',
    'Single level home': 'Casa a un piano',
    'Smoke detector': 'Rilevatore di fumo',
    'Smoking allowed': 'È permesso fumare',
    'Stair gates': 'Cancello di sicurezza per le scale',
    'Step-free access': 'Accesso facilitato privo di gradini',
    Stove: 'Fornelli',
    'Suitable for children (2-12 years)':
      'Adatto ai bambini (dai 2 ai 12 anni)',
    'Suitable for infants (under 2 years)':
      'Adatto ai neonati (minori di due anni)',
    'Table corner guards': 'Paraspigoli tavolo',
    'Tub with shower bench': 'Vasca con panca doccia',
    Washer: 'Lavatrice',
    'Wide clearance to bed': 'Ampio spazio per accedere al letto',
    'Wide clearance to shower and toilet':
      'Ampio spazio per accedere alla doccia e ai servizi igienici',
    'Wide doorway': "Porta d'ingresso ampia",
    'Wide hallway clearance': "Ampio spazio nel corridoio d'ingresso",
    'Window guards': 'Inferriate sulle finestre',
    'Wireless Internet': 'Internet wireless',
    'Grab-rails in toilet': 'Maniglione servizi igienici',
    'Shower bench': 'Panca doccia',
    'Shower chair': 'Sedia da doccia',
    'Wheelchair accessible': 'Accessibile a persone su sedia a rotelle',
    'Body soap': 'Detergente corpo',
    'Cleaning products': 'Prodotti per la pulizia',
    Conditioner: 'Balsamo',
    'Shower gel': 'Docciaschiuma',
    'Towels provided': 'Asciugamani a disposizione',
    'Clothing storage': 'Armadio per vestiti',
    'Coin Laundry': 'Lavanderia a gettoni',
    'Dryer in common space': "Asciugatrice nell'area comune",
    'Mosquito net': 'Zanzariera',
    'Washer in common space': "Lavatrice nell'area comune",
    'DVD player': 'Lettore DVD',
    'Foosball table': 'Calcio balilla',
    'Game room': 'Sala giochi',
    Piano: 'Pianoforte',
    'Ping pong table': 'Tavolo da ping pong',
    'Pool table': 'Tavolo da biliardo',
    'Sound system': 'Impianto audio',
    'Board games': 'Giochi da tavolo',
    'Family/kid friendly': 'Adatto a famiglie e bambini',
    'Portable fans': 'Ventilatori portatili',
    'Emergency exit': 'Uscita di emergenza',
    'Baking sheet': 'Placca da forno',
    'Barbeque utensils': 'Utensili per barbecue',
    Blender: 'Frullatore',
    Coffee: 'Caffè',
    Cookware: 'Utensili da cucina',
    'Dining table': 'Tavolo da pranzo',
    Freezer: 'Congelatore',
    'Ice maker': 'Macchina per il ghiaccio',
    Kettle: 'Bollitore',
    'Mini fridge': 'Minifrigo',
    'Rice maker': 'Cuociriso',
    Toaster: 'Tostapane',
    'Trash compactor': 'Compattatore rifiuti',
    'Wine glasses': 'Bicchieri da vino',
    Beach: 'Spiaggia',
    'Beach Front': 'Fronte spiaggia',
    'Beach View': 'Vista spiaggia',
    'Beach access': 'Accesso alla spiaggia',
    'City View': 'Vista città',
    'Desert View': 'Vista deserto',
    Downtown: 'Centro città',
    'Garden View': 'Vista giardino',
    'Golf course front': 'Fronte campo da golf',
    'Golf view': 'Vista campo da golf',
    Lake: 'Lago',
    'Lake Front': 'Fronte lago',
    'Lake access': 'Accesso al lago',
    'Laundromat nearby': 'Lavanderia automatica nelle vicinanze',
    Mountain: 'Montagna',
    'Mountain view': 'Vista montagne',
    'Near Ocean': "Vicino all'oceano",
    'Ocean Front': 'Fronte oceano',
    Resort: 'Resort',
    'Resort access': 'Accesso al resort',
    Rural: 'Campagna',
    'Sea view': 'Vista mare',
    'Ski In': 'Sci ai piedi',
    'Ski Out': 'Sci ai piedi',
    'Ski in/Ski out': 'Sci ai piedi',
    Town: 'Paese',
    Village: 'Villaggio',
    'Water View': 'Vista acqua',
    Waterfront: 'Lungomare',
    'Cleaning Disinfection': 'Pulizia disinfettante',
    Desk: 'Scrivania',
    'Enhanced cleaning practices': 'Pulizia più approfondita',
    'High touch surfaces disinfected':
      'Disinfezione delle superfici più esposte',
    'Bicycles available': 'Biciclette disponibili',
    Bikes: 'Biciclette',
    'Boat slip': 'Scivolo per barca',
    'Fire Pit': 'Braciere da giardino',
    Hammock: 'Amaca',
    Kayak: 'Kajak',
    'Outdoor kitchen': 'Cucina esterna',
    'Outdoor seating (furniture)': 'Sedie da esterno (mobili)',
    River: 'Fiume',
    'Free parking on street': 'Parcheggio gratuito in strada',
    Garage: 'Garage',
    'Paid parking': 'Parcheggio a pagamento',
    'Paid parking off premises': 'Parcheggio a pagamento fuori sede',
    'Communal pool': 'Piscina comunale',
    'Indoor pool': 'Piscina coperta',
    'Outdoor pool': 'Piscina esterna',
    'Private pool': 'Piscina privata',
    'Rooftop pool': 'Piscina sul tetto',
    Elevator: 'Ascensore',
    TV: 'Televisione',
    'Ceiling fan': 'Ventilatore a soffitto',
    'Pocket Wifi': 'Wi-Fi portatile',
    Safe: 'Cassetta di sicurezza',
    'Stereo system': 'Impianto stereo',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Casinò',
    Cycling: 'Ciclismo',
    Fishing: 'Pesca',
    'Golf - Optional': 'Golf - Facoltativo',
    'Horseback Riding': 'Equitazione',
    'Mountain Climbing': 'Alpinismo',
    Museums: 'Musei',
    'Rock Climbing': 'Arrampicata',
    Shopping: 'Shopping',
    'Theme Parks': 'Parchi tematici',
    'Water Parks': 'Parchi acquatici',
    'Water Sports': 'Sport acquatici',
    Zoo: 'Zoo',
    Apartment: 'Appartamento',
    House: 'Casa',
    Loft: 'Loft',
    Boat: 'Barca',
    'Camper/RV': 'Camper',
    Condominium: 'Condominio',
    Chalet: 'Chalet',
    'Bed & Breakfast': 'Bed & Breakfast',
    Villa: 'Villa',
    Tent: 'Tenda',
    Other: 'Altro',
    Cabin: 'Capanna',
    Townhouse: 'Casa a schiera',
    Bungalow: 'Bungalow',
    Hut: 'Baracca',
    Dorm: 'Dormitorio',
    'Parking Space': 'Parcheggio',
    Plane: 'Aereo',
    Treehouse: "Casa sull'albero",
    Yurt: 'Yurta',
    Tipi: 'Tipi',
    Igloo: 'Iglù',
    'Earth House': 'Casa ipogea',
    Island: 'Isola',
    Cave: 'Grotta',
    Castle: 'Castello',
    Studio: 'Studio',
    Cottage: 'Cottage',
  },
  ja: {
    'Load more': 'さらに読み込む',
    'Sort by': '並べ替え',
    'Amenities: All': 'アメニティ：すべて',
    'Property type: All': '物件のタイプ：すべて',
    'Categories: All': 'カテゴリー：すべて',
    'Bedrooms: All': 'ベッドルーム：すべて',
    'Bathrooms: All': 'バスルーム：すべて',
    'Price per night: All': '1泊あたりの価格：すべて',
    Max: '最大',
    'Starting from': '最低価格',
    'View prices': '価格を表示',
    'Floor surface/Property size - high to low': '床面積/物件サイズ：大きい順',
    'Price - high to low': '価格：高い順',
    'Price - low to high': '価格：安い順',
    'Maximum occupancy - high to low': '最大収容人数：多い順',
    'Count of bedrooms - high to low': 'ベッドルーム数：多い順',

    'Swimming pool': 'スイミングプール',
    'Accessible-height bed': '身障者用ベッド',
    'Accessible-height toilet': '身障者用トイレ',
    'Air conditioning': 'エアコン',
    'Babysitter recommendations': 'ベビーシッターの推奨事項',
    'Baby bath': '赤ちゃん用お風呂',
    'Baby monitor': '���ビーモニター',
    Bathtub: 'バスタブ',
    'BBQ grill': 'バーベキューグリル',
    'Beach essentials': 'ビーチ用品',
    'Bed linens': '寝具',
    Breakfast: '朝食',
    'Cable TV': 'ケーブルテレビ',
    'Carbon monoxide detector': '一酸化炭素検知器',
    'Changing table': 'おむつ交換台',
    'Children’s books and toys': '子供向けの本とおもちゃ',
    'Children’s dinnerware': '子供用食器',
    'Cleaning before checkout': 'チェックアウト前の清掃',
    'Coffee maker': 'コーヒーメーカー',
    'Cooking basics': '基本的なキッチン用品',
    Crib: 'ベビーベッド',
    'Disabled parking spot': '身体障害者用駐車スペース',
    'Dishes and silverware': '皿および食卓用銀器',
    Dishwasher: '食器洗い機',
    'Dog(s)': '犬',
    Doorman: 'ドアマン',
    Dryer: '乾燥機',
    'Elevator in building': '建物内のエレベーター',
    Essentials: '必需品',
    'Ethernet connection': 'イーサネット接続',
    'EV charger': 'EV充電器',
    'Extra pillows and blankets': '予備の枕と毛布',
    'Fireplace guards': '暖炉の火よけ',
    'Fire extinguisher': '消火器',
    'Firm mattress': '硬めのマットレス',
    'First aid kit': '応急処置キット',
    'Flat smooth pathway to front door': '正面玄関までの平坦な道',
    'Free parking on premises': '敷地内無料駐車場',
    'Game console': 'ゲーム機',
    'Garden or backyard': '庭または裏庭',
    'Grab-rails for shower and toilet': 'シャワーおよびトイレの手すり',
    Gym: 'ジム',
    'Hair dryer': 'ヘアドライヤー',
    Hangers: 'ハンガー',
    Heating: '暖房',
    'High chair': '子供用椅子',
    'Hot tub': '温水浴槽',
    'Hot water': 'お湯',
    'Indoor fireplace': '屋内暖炉',
    Internet: 'インターネット',
    Iron: 'アイロン',
    Kitchen: 'キッチン',
    'Laptop friendly workspace': 'ノートパソコンが使い易い作業場',
    'Long term stays allowed': '長期滞在可',
    'Luggage dropoff allowed': '荷物の預け入れ可',
    Microwave: '電子レンジ',
    'Outlet covers': 'コンセントカバー',
    Oven: 'オーブン',
    'Pack ’n Play/travel crib': '折り畳み式/旅行用ベビーベッド',
    'Path to entrance lit at night': '玄関までの道の夜間照明',
    'Patio or balcony': 'パティオまたはバルコニー',
    'Pets allowed': 'ペット可',
    'Pets live on this property': 'ペット同居可',
    'Pocket wifi': 'ポケットWi-Fi',
    Pool: 'プール',
    'Private entrance': '専用の入り口',
    Refrigerator: '冷蔵庫',
    'Roll-in shower with shower bench or chair':
      '車椅子で入れるシャワーベンチまたは椅子付きシャワー室',
    'Room-darkening shades': '部屋を暗くするためのシェード',
    Shampoo: 'シャンプー',
    'Single level home': '平屋建て',
    'Smoke detector': '煙探知器',
    'Smoking allowed': '喫煙可',
    'Stair gates': '階段用柵',
    'Step-free access': 'バリアフリー',
    Stove: 'ストーブ',
    'Suitable for children (2-12 years)': '子供（2歳～12歳）に最適',
    'Suitable for infants (under 2 years)': '乳幼児（2歳未満）に最適',
    'Table corner guards': 'テーブルコーナーガード',
    'Tub with shower bench': 'シャワーベンチ付き浴槽',
    Washer: '洗濯機',
    'Wide clearance to bed': 'ベッド周りの広い空間',
    'Wide clearance to shower and toilet': 'シャワーとトイレの広い空間',
    'Wide doorway': '広い出入り口',
    'Wide hallway clearance': '広い廊下',
    'Window guards': '窓柵',
    'Wireless Internet': 'ワイヤレスインターネット',
    'Grab-rails in toilet': 'トイレ内に手すり有',
    'Shower bench': 'シャワーベンチ',
    'Shower chair': 'シャワーチェア',
    'Wheelchair accessible': '車椅子対応',
    'Body soap': 'ボディソープ',
    'Cleaning products': '清掃用品',
    Conditioner: 'コンディショナー',
    'Shower gel': 'シャワージェル',
    'Towels provided': 'タオル提供',
    'Clothing storage': '衣類収納',
    'Coin Laundry': 'コインランドリー',
    'Dryer in common space': '共用スペースに乾燥機',
    'Mosquito net': '蚊帳',
    'Washer in common space': '共用スペースに洗濯機',
    'DVD player': 'DVDプレーヤー',
    'Foosball table': 'テーブルサッカー',
    'Game room': 'ゲームルーム',
    Piano: 'ピアノ',
    'Ping pong table': '卓球台',
    'Pool table': 'ビリヤード台',
    'Sound system': '音響システム',
    'Board games': 'ボードゲーム',
    'Family/kid friendly': '家族/子供対応',
    'Portable fans': '携帯型扇風機',
    'Emergency exit': '非常口',
    'Baking sheet': 'ベーキングシート',
    'Barbeque utensils': 'バーベキュー用品',
    Blender: 'ブレンダー',
    Coffee: 'コーヒー',
    Cookware: '調理器具',
    'Dining table': 'ダイニングテーブル',
    Freezer: '冷凍庫',
    'Ice maker': '製氷機',
    Kettle: 'やかん',
    'Mini fridge': 'ミニ冷蔵庫',
    'Rice maker': '炊飯器',
    Toaster: 'トースター',
    'Trash compactor': 'ゴミ圧縮機',
    'Wine glasses': 'ワイングラス',
    Beach: 'ビーチ',
    'Beach Front': 'ビーチフロント',
    'Beach View': 'ビーチビュー',
    'Beach access': 'ビーチへのアクセス',
    'City View': 'シティビュー',
    'Desert View': 'デザートビュー',
    Downtown: 'ダウンタウン',
    'Garden View': 'ガーデンビュー',
    'Golf course front': 'ゴルフコース前',
    'Golf view': 'ゴルフビュー',
    Lake: '湖',
    'Lake Front': '湖畔',
    'Lake access': '湖へのアクセス',
    'Laundromat nearby': '近くにコインランドリー有',
    Mountain: '山',
    'Mountain view': 'マウンテンビュー',
    'Near Ocean': '海の近く',
    'Ocean Front': 'オーシャンフロント',
    Resort: 'リゾート',
    'Resort access': 'リゾートへのアクセス',
    Rural: '田舎',
    'Sea view': 'シービュー',
    Town: '町',
    Village: '村',
    'Water View': 'ウォータービュー',
    Waterfront: 'ウォーターフロント',
    'Cleaning Disinfection': '清掃消毒',
    Desk: 'デスク',
    'Enhanced cleaning practices': '徹底した清掃作業',
    'High touch surfaces disinfected': '頻繁に触れる表面の消毒',
    'Bicycles available': '自転車利用可',
    Bikes: 'バイク',
    'Fire Pit': '暖炉',
    Hammock: 'ハンモック',
    Kayak: 'カヤック',
    'Outdoor kitchen': '屋外キッチン',
    'Outdoor seating (furniture)': '屋外席（家具）',
    River: '川',
    'Free parking on street': '無料の路上駐車場',
    Garage: 'ガレージ',
    'Paid parking': '有料駐車場',
    'Paid parking off premises': '敷地外有料駐車場',
    'Communal pool': '共用プール',
    'Indoor pool': '屋内プール',
    'Outdoor pool': '屋外プール',
    'Private pool': 'プライベートプール',
    'Rooftop pool': '屋上プール',
    Elevator: 'エレベーター',
    TV: 'テレビ',
    'Ceiling fan': 'シーリングファン',
    'Pocket Wifi': 'ポケットWi-Fi',
    Safe: '金庫',
    'Stereo system': 'ステレオ装置',
    Spa: 'スパ',
    Sauna: 'サウナ',
    Casinos: 'カジノ',
    Cycling: 'サイクリング',
    Fishing: '釣り',
    'Golf - Optional': 'ゴルフ - オプション',
    'Horseback Riding': '乗馬',
    'Mountain Climbing': '登山',
    Museums: '博物館',
    'Rock Climbing': 'ロッククライミング',
    Shopping: 'ショッピング',
    'Theme Parks': 'テーマパーク',
    'Water Parks': 'ウォーターパーク',
    'Water Sports': 'ウォータースポーツ',
    Zoo: '動物園',
    Apartment: 'アパート',
    House: '家',
    Loft: 'ロフト',
    Boat: 'ボート',
    'Camper/RV': 'キャンピングカー/RV',
    Condominium: 'コンドミニアム',
    Chalet: 'シャレー',
    'Bed & Breakfast': 'ベッド＆朝食',
    Villa: 'ヴィラ',
    Tent: 'テント',
    Other: 'その他',
    Cabin: 'キャビン',
    Townhouse: 'タウンハウス',
    Bungalow: 'バンガロー',
    Hut: '小屋',
    Dorm: '寮',
    'Parking Space': '駐車スペース',
    Plane: '飛行機',
    Treehouse: 'ツリーハウス',
    Yurt: 'ユルト',
    Tipi: 'ティピー',
    Igloo: 'イグルー',
    'Earth House': 'アースハウス',
    Island: '島',
    Cave: '地下貯蔵室',
    Castle: '大邸宅',
    Studio: 'スタジオ',
    Cottage: 'コテージ',
  },
  ko: {
    'Load more': '더 많은 것을 로드합니다',
    'Sort by': '정렬 기준',
    'Amenities: All': '편의 시설: 전체',
    'Property type: All': '숙박 시설 유형: 전체',
    'Categories: All': '카테고리: 전체',
    'Bedrooms: All': '침실: 전체',
    'Bathrooms: All': '욕실: 전체',
    'Price per night: All': '1박 요금: 전체',
    Max: '최대',
    'Starting from': '최저가',
    'View prices': '가격 보기',
    'Floor surface/Property size - high to low':
      '바닥 표면/숙박 시설 크기 - 내림차순',
    'Price - high to low': '가격 - 내림차순',
    'Price - low to high': '가격 - 올림차순',
    'Maximum occupancy - high to low': '최대 수용 인원 - 내림차순',
    'Count of bedrooms - high to low': '침실 개수 - 내림차순',

    'Swimming pool': '수영장',
    'Accessible-height bed': '장애인용 침대',
    'Accessible-height toilet': '장애인용 화장실',
    'Air conditioning': '에어컨',
    'Babysitter recommendations': '베이비 시터 추천',
    'Baby bath': '아기 욕조',
    'Baby monitor': '아기 모니터',
    Bathtub: '욕조',
    'BBQ grill': '바비큐 그릴',
    'Beach essentials': '해변 필수품',
    'Bed linens': '침대 시트',
    Breakfast: '조식',
    'Cable TV': '케이블TV',
    'Carbon monoxide detector': '일산화탄소 탐지기',
    'Changing table': '기저귀 교체대',
    'Children’s books and toys': '아동 도서 및 장난감',
    'Children’s dinnerware': '어린이 식기류',
    'Cleaning before checkout': '체크아웃 전 청소',
    'Coffee maker': '커피메이커',
    'Cooking basics': '기본 조리 도구',
    Crib: '아기 침대',
    'Disabled parking spot': '장애인 주차 공간',
    'Dishes and silverware': '접시와 식기',
    Dishwasher: '식기세척기',
    'Dog(s)': '개',
    Doorman: '도어맨',
    Dryer: '건조기',
    'Elevator in building': '건물 내 엘리베이터',
    Essentials: '필수품',
    'Ethernet connection': '이더넷 연결',
    'EV charger': '전기차 충전기',
    'Extra pillows and blankets': '여분 베개 및 이불',
    'Fireplace guards': '난로 가드',
    'Fire extinguisher': '소화기',
    'Firm mattress': '딱딱한 쿠션감의 매트리스',
    'First aid kit': '구급상자 키트',
    'Flat smooth pathway to front door': '정문까지 평평한 통로',
    'Free parking on premises': '구내 무료 주차',
    'Game console': '게임 콘솔',
    'Garden or backyard': '정원 또는 뒤뜰',
    'Grab-rails for shower and toilet': '샤워 및 변기 손잡이',
    Gym: '헬스장',
    'Hair dryer': '헤어 드라이어',
    Hangers: '옷걸이',
    Heating: '난방',
    'High chair': '유아용 하이 체어',
    'Hot tub': '핫텁',
    'Hot water': '온수',
    'Indoor fireplace': '실내 난로',
    Internet: '인터넷',
    Iron: '다리미',
    Kitchen: '부엌',
    'Laptop friendly workspace': '노트북 작업 공간',
    'Long term stays allowed': '장기 체류 가능',
    'Luggage dropoff allowed': '수하물 위탁 허용',
    Microwave: '전자레인지',
    'Outlet covers': '콘센트 커버',
    Oven: '오븐',
    'Pack ’n Play/travel crib': '팩 앤 플레이 / 여행용 유아용 침대',
    'Path to entrance lit at night': '야간 입구 조명',
    'Patio or balcony': '파티오 또는 발코니',
    'Pets allowed': '반려동물 허용',
    'Pets live on this property': '이 숙박 시설에 반려동물 거주',
    'Pocket wifi': '포켓 와이파이',
    Pool: '수영장',
    'Private entrance': '개인용 출입구',
    Refrigerator: '냉장고',
    'Roll-in shower with shower bench or chair':
      '샤워 벤치 또는 의자가 있는 휠체어 사용 가능 샤워 시설',
    'Room-darkening shades': '암막 커튼',
    Shampoo: '샴푸',
    'Single level home': '단층 주택',
    'Smoke detector': '화재 경보기',
    'Smoking allowed': '흡연 허용',
    'Stair gates': '계단 문',
    'Step-free access': '단계없는 액세스',
    Stove: '스토브',
    'Suitable for children (2-12 years)': '아동(2~12세)에게 적합',
    'Suitable for infants (under 2 years)': '유아(2세 미만)에게 적합',
    'Table corner guards': '테이블 코너 가드',
    'Tub with shower bench': '샤워 벤치가 있는 욕조',
    Washer: '세탁기',
    'Wide clearance to bed': '침대 주변 널찍한 공간',
    'Wide clearance to shower and toilet': '샤워 및 변기 주변의 넉넉한 공간',
    'Wide doorway': '넓은 출입구',
    'Wide hallway clearance': '넓은 복도 공간',
    'Window guards': '창문 가드',
    'Wireless Internet': '무선 인터넷',
    'Grab-rails in toilet': '변기 안전 손잡이',
    'Shower bench': '샤워 벤치',
    'Shower chair': '샤워 체어',
    'Wheelchair accessible': '휠체어 접근 가능',
    'Body soap': '바디솝',
    'Cleaning products': '클렌징 제품',
    Conditioner: '컨디셔너',
    'Shower gel': '샤워젤',
    'Towels provided': '타월 제공',
    'Clothing storage': '옷장',
    'Coin Laundry': '코인 세탁기',
    'Dryer in common space': '공용 공간의 건조기',
    'Mosquito net': '모기망',
    'Washer in common space': '공용 공간의 세탁기',
    'DVD player': 'DVD 플레이어',
    'Foosball table': '푸스볼 테이블',
    'Game room': '게임룸',
    Piano: '피아노',
    'Ping pong table': '탁구대',
    'Pool table': '당구대',
    'Sound system': '사운드 시스템',
    'Board games': '보드게임',
    'Family/kid friendly': '가족/어린이 친화적',
    'Portable fans': '휴대용 선풍기',
    'Emergency exit': '비상 탈출구',
    'Baking sheet': '베이킹 시트',
    'Barbeque utensils': '바베큐 용품',
    Blender: '블렌더',
    Coffee: '커피',
    Cookware: '조리기구',
    'Dining table': '식탁',
    Freezer: '냉동고',
    'Ice maker': '아이스머신',
    Kettle: '주전자',
    'Mini fridge': '미니 냉장고',
    'Rice maker': '밥솥',
    Toaster: '토스트기',
    'Trash compactor': '폐기물 압축기',
    'Wine glasses': '와인 잔',
    Beach: '해변',
    'Beach Front': '비치 프론트',
    'Beach View': '비치 뷰',
    'Beach access': '해변 접근 가능',
    'City View': '시티 뷰',
    'Desert View': '사막 뷰',
    Downtown: '시내',
    'Garden View': '가든 뷰',
    'Golf course front': '골프코스 프론트',
    'Golf view': '골프코스 뷰',
    Lake: '호수',
    'Lake Front': '레이크 프론트',
    'Lake access': '호수 접근 가능',
    'Laundromat nearby': '인근 세탁방',
    Mountain: '마운틴',
    'Mountain view': '마운틴 뷰',
    'Near Ocean': '바다 인근',
    'Ocean Front': '오션 프론트',
    Resort: '리조트',
    'Resort access': '리조트 접근 가능',
    Rural: '전원지역',
    'Sea view': '바다 뷰',
    'Ski In': '스키인',
    'Ski Out': '스키아웃',
    'Ski in/Ski out': '스키인/스키아웃',
    Town: '타운',
    Village: '빌리지',
    'Water View': '워터 뷰',
    Waterfront: '워터프론트',
    'Cleaning Disinfection': '소독 청소',
    Desk: '책상',
    'Enhanced cleaning practices': '강화된 청소 방식',
    'High touch surfaces disinfected': '자주 만지는 곳 소독',
    'Bicycles available': '자전거 이용 가능',
    Bikes: '자전거',
    'Boat slip': '보트 슬립',
    'Fire Pit': '화로',
    Hammock: '해먹',
    Kayak: '카약',
    'Outdoor kitchen': '야외 부엌',
    'Outdoor seating (furniture)': '야외 의자(가구)',
    River: '강',
    'Free parking on street': '노상 무료 주차',
    Garage: '차고',
    'Paid parking': '유료 주차',
    'Paid parking off premises': '구외 유료 주차장',
    'Communal pool': '공용 수영장',
    'Indoor pool': '실내 수영장',
    'Outdoor pool': '실외 수영장',
    'Private pool': '프라이빗 수영장',
    'Rooftop pool': '루프탑 수영장',
    Elevator: '엘리베이터',
    TV: 'TV',
    'Ceiling fan': '실링팬',
    'Pocket Wifi': '포켓 와이파이',
    Safe: '금고',
    'Stereo system': '스테레오 시스템',
    Spa: '스파',
    Sauna: '사우나',
    Casinos: '카지노',
    Cycling: '사이클링',
    Fishing: '낚시',
    'Golf - Optional': '골프 - 선택 사항',
    'Horseback Riding': '승마',
    'Mountain Climbing': '등산',
    Museums: '박물관',
    'Rock Climbing': '암벽등반',
    Shopping: '쇼핑',
    'Theme Parks': '놀이공원',
    'Water Parks': '워터파크',
    'Water Sports': '워터 스포츠',
    Zoo: '동물원',
    Apartment: '아파트',
    House: '주택',
    Loft: '로프트',
    Boat: '보트',
    'Camper/RV': '캠핑카/RV',
    Condominium: '콘도',
    Chalet: '샬레',
    'Bed & Breakfast': '베드 앤 브렉퍼스트',
    Villa: '빌라',
    Tent: '텐트',
    Other: '기타',
    Cabin: '통나무집',
    Townhouse: '타운 하우스',
    Bungalow: '방갈로',
    Hut: '오두막',
    Dorm: '기숙사',
    'Parking Space': '주차 공간',
    Plane: '비행기',
    Treehouse: '트리 하우스',
    Yurt: '유르트',
    Tipi: '티피',
    Igloo: '이글루',
    'Earth House': '어스 하우스',
    Island: '아일랜드',
    Cave: '동굴',
    Castle: '캐슬',
    Studio: '스튜디오',
    Cottage: '별장',
  },
  pl: {
    'Load more': 'Załaduj więcej',
    'Sort by': 'Sortuj według',
    'Amenities: All': 'Udogodnienia: wszystkie',
    'Property type: All': 'Typ obiektu: wszystkie',
    'Categories: All': 'Kategorie: wszystkie',
    'Bedrooms: All': 'Sypialnie: wszystkie',
    'Bathrooms: All': 'Łazienki: wszystkie',
    'Price per night: All': 'Cena za noc: wszystkie',
    Max: 'Maks.',
    'Starting from': 'Zaczynając od',
    'View prices': 'Wyświetl ceny',
    'Floor surface/Property size - high to low':
      'Powierzchnia podłogi / rozmiar obiektu — od dużego do małego',
    'Price - high to low': 'Cena — od wysokiej do niskiej',
    'Price - low to high': 'Cena — od niskiej do wysokiej',
    'Maximum occupancy - high to low':
      'Maksymalne obłożenie — od dużego do małego',
    'Count of bedrooms - high to low': 'Liczba sypialni — od dużej do małej',

    'Swimming pool': 'Basen',
    'Accessible-height bed': 'Łóżko o przystosowanej wysokości',
    'Accessible-height toilet': 'Toaleta o przystosowanej wysokości',
    'Air conditioning': 'Klimatyzacja',
    'Babysitter recommendations': 'Możliwość polecenia opiekunki do dzieci',
    'Baby bath': 'Wanienka',
    'Baby monitor': 'Elektroniczna niania',
    Bathtub: 'Wanna',
    'BBQ grill': 'Grill',
    'Beach essentials': 'Sprzęty plażowe',
    'Bed linens': 'Pościel',
    Breakfast: 'Śniadanie',
    'Cable TV': 'Telewizja kablowa',
    'Carbon monoxide detector': 'Detektor tlenku węgla',
    'Changing table': 'Stolik do przewijania',
    'Children’s books and toys': 'Książki i zabawki dla dzieci',
    'Children’s dinnerware': 'Naczynia dla dzieci',
    'Cleaning before checkout': 'Sprzątanie przed wymeldowaniem',
    'Coffee maker': 'Ekspres do kawy',
    'Cooking basics': 'Podstawowy sprzęt do gotowania',
    Crib: 'Łóżeczko dziecięce',
    'Disabled parking spot': 'Miejsca parkingowe dla niepełnosprawnych',
    'Dishes and silverware': 'Naczynia i sztućce',
    Dishwasher: 'Zmywarka do naczyń',
    'Dog(s)': 'Psy',
    Doorman: 'Portier',
    Dryer: 'Suszarka',
    'Elevator in building': 'Winda w budynku',
    Essentials: 'Podstawowe akcesoria',
    'Ethernet connection': 'Połączenie Ethernet',
    'EV charger': 'Ładowarka EV',
    'Extra pillows and blankets': 'Dodatkowe poduszki i koce',
    'Fireplace guards': 'Osłony kominkowe',
    'Fire extinguisher': 'Gaśnica',
    'Firm mattress': 'Twardy materac',
    'First aid kit': 'Apteczka pierwszej pomocy',
    'Flat smooth pathway to front door':
      'Płaska gładka droga do drzwi wejściowych',
    'Free parking on premises': 'Bezpłatny parking na miejscu',
    'Game console': 'Konsola do gier',
    'Garden or backyard': 'Ogród lub podwórko',
    'Grab-rails for shower and toilet': 'Poręcze dla prysznica i ubikacji',
    Gym: 'Siłownia',
    'Hair dryer': 'Suszarka do włosów',
    Hangers: 'Wieszaki',
    Heating: 'Ogrzewanie',
    'High chair': 'Wysokie krzesełko',
    'Hot tub': 'Wanna z hydromasażem',
    'Hot water': 'Gorąca woda',
    'Indoor fireplace': 'Kominek wewnątrz',
    Internet: 'Internet',
    Iron: 'Żelazko',
    Kitchen: 'Kuchnia',
    'Laptop friendly workspace': 'Miejsce do pracy z laptopem',
    'Long term stays allowed': 'Dłuższe pobyty dozwolone',
    'Luggage dropoff allowed': 'Możliwość pozostawienia bagażu',
    Microwave: 'Kuchenka mikrofalowa',
    'Outlet covers': 'Osłony gniazdka',
    Oven: 'Piekarnik',
    'Pack ’n Play/travel crib': "Pack 'n Play / podróżne łóżeczko dziecięce",
    'Path to entrance lit at night': 'Ścieżka do wejścia oświetlona w nocy',
    'Patio or balcony': 'Patio lub balkon',
    'Pets allowed': 'Zwierzęta są dozwolone',
    'Pets live on this property': 'Na terenie obiektu mieszkają zwierzęta',
    'Pocket wifi': 'Kieszonkowe Wi-Fi',
    Pool: 'Basen',
    'Private entrance': 'Prywatne wejście',
    Refrigerator: 'Lodówka',
    'Roll-in shower with shower bench or chair':
      'Kabina prysznicowa z ławką lub krzesłem prysznicowym',
    'Room-darkening shades': 'Rolety zaciemniające pokój',
    Shampoo: 'Szampon',
    'Single level home': 'Dom parterowy',
    'Smoke detector': 'Wykrywacz dymu',
    'Smoking allowed': 'Palenie dozwolone',
    'Stair gates': 'Bramka na schody',
    'Step-free access': 'Bezstopniowy dostęp',
    Stove: 'Kuchenka',
    'Suitable for children (2-12 years)': 'Odpowiedni dla dzieci (2–12 lat)',
    'Suitable for infants (under 2 years)':
      'Odpowiedni dla niemowląt (poniżej 2 lat)',
    'Table corner guards': 'Ochronne narożniki na stół',
    'Tub with shower bench': 'Wanna z ławeczką prysznicową',
    Washer: 'Pralka',
    'Wide clearance to bed': 'Szeroki prześwit do łóżka',
    'Wide clearance to shower and toilet':
      'Szeroki odstęp od prysznica i toalety',
    'Wide doorway': 'Szerokie drzwi',
    'Wide hallway clearance': 'Szeroki korytarz',
    'Window guards': 'Osłony okien',
    'Wireless Internet': 'Internet bezprzewodowy',
    'Grab-rails in toilet': 'Poręcze w toalecie',
    'Shower bench': 'Ławeczka prysznicowa',
    'Shower chair': 'Krzesełko prysznicowe',
    'Wheelchair accessible': 'Dostęp dla wózków inwalidzkich',
    'Body soap': 'Mydło do ciała',
    'Cleaning products': 'Środki czyszczące',
    Conditioner: 'Odżywka',
    'Shower gel': 'Żel pod prysznic',
    'Towels provided': 'Ręczniki na wyposażeniu',
    'Clothing storage': 'Miejsce na odzież',
    'Coin Laundry': 'Pralnia na monety',
    'Dryer in common space': 'Suszarka w pomieszczeniu ogólnodostępnym',
    'Mosquito net': 'Moskitiera',
    'Washer in common space': 'Zmywarka w pomieszczeniu ogólnodostępnym',
    'DVD player': 'Odtwarzacz DVD',
    'Foosball table': 'Piłkarzyki',
    'Game room': 'Pokój gier',
    Piano: 'Pianino',
    'Ping pong table': 'Stół do ping ponga',
    'Pool table': 'Stół bilardowy',
    'Sound system': 'System nagłośnienia',
    'Board games': 'Gry planszowe',
    'Family/kid friendly': 'Przyjazny dla rodzin/dzieci',
    'Portable fans': 'Wentylatory przenośne',
    'Emergency exit': 'Wyjście ewakuacyjne',
    'Baking sheet': 'Blacha do pieczenia',
    'Barbeque utensils': 'Przybory do grillowania',
    Blender: 'Blender',
    Coffee: 'Kawa',
    Cookware: 'Naczynia do gotowania',
    'Dining table': 'Stół jadalniany',
    Freezer: 'Zamrażarka',
    'Ice maker': 'Kostkarka do lodu',
    Kettle: 'Czajnik',
    'Mini fridge': 'Minilodówka',
    'Rice maker': 'Ryżowar',
    Toaster: 'Toster',
    'Trash compactor': 'Zgniatarka do śmieci',
    'Wine glasses': 'Kieliszki do wina',
    Beach: 'Plaża',
    'Beach Front': 'Plaża od przodu',
    'Beach View': 'Widok na plażę',
    'Beach access': 'Dostęp do plaży',
    'City View': 'Widok na miasto',
    'Desert View': 'Widok na pustynię',
    Downtown: 'Śródmieście',
    'Garden View': 'Widok na ogród',
    'Golf course front': 'Pole golfowe z przodu',
    'Golf view': 'Widok na pole golfowe',
    Lake: 'Jezioro',
    'Lake Front': 'Jezioro od przodu',
    'Lake access': 'Dostęp do jeziora',
    'Laundromat nearby': 'Pralnia samoobsługowa w pobliżu',
    Mountain: 'Góry',
    'Mountain view': 'Widok na góry',
    'Near Ocean': 'W pobliżu oceanu',
    'Ocean Front': 'Ocean od przodu',
    Resort: 'Ośrodek wypoczynkowy',
    'Resort access': 'Dostęp do ośrodka wypoczynkowego',
    Rural: 'Obszar wiejski',
    'Sea view': 'Widok na morze',
    'Ski In': 'Wjazd na nartach',
    'Ski Out': 'Wyjazd na nartach',
    'Ski in/Ski out': 'Wjazd i wyjazd na nartach',
    Town: 'Miasto',
    Village: 'Wioska',
    'Water View': 'Widok na wodę',
    Waterfront: 'Nabrzeże',
    'Cleaning Disinfection': 'Czyszczenie i dezynfekcja',
    Desk: 'Biurko',
    'Enhanced cleaning practices': 'Ulepszone praktyki sprzątania',
    'High touch surfaces disinfected':
      'Dezynfekcja często dotykanych powierzchni',
    'Bicycles available': 'Rowery do dyspozycji',
    Bikes: 'Rowery',
    'Boat slip': 'Slip dla łodzi',
    'Fire Pit': 'Palenisko',
    Hammock: 'Hamak',
    Kayak: 'Kajak',
    'Outdoor kitchen': 'Kuchnia na świeżym powietrzu',
    'Outdoor seating (furniture)': 'Meble do siedzenia na zewnątrz',
    River: 'Rzeka',
    'Free parking on street': 'Bezpłatny parking na ulicy',
    Garage: 'Garaż',
    'Paid parking': 'Płatny parking',
    'Paid parking off premises': 'Płatny parking poza obiektem',
    'Communal pool': 'Ogólnodostępny basen',
    'Indoor pool': 'Kryty basen',
    'Outdoor pool': 'Basen na świeżym powietrzu',
    'Private pool': 'Prywatny basen',
    'Rooftop pool': 'Basen na dachu',
    Elevator: 'Winda',
    TV: 'Telewizor',
    'Ceiling fan': 'Wentylator sufitowy',
    'Pocket Wifi': 'Kieszonkowe Wi-Fi',
    Safe: 'Sejf',
    'Stereo system': 'System stereo',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Kasyna',
    Cycling: 'Kolarstwo',
    Fishing: 'Wędkarstwo',
    'Golf - Optional': 'Golf — opcjonalnie',
    'Horseback Riding': 'Jazda konna',
    'Mountain Climbing': 'Wspinaczka górska',
    Museums: 'Muzea',
    'Rock Climbing': 'Wspinaczka skałkowa',
    Shopping: 'Zakupy',
    'Theme Parks': 'Parki tematyczne',
    'Water Parks': 'Parki wodne',
    'Water Sports': 'Sporty wodne',
    Zoo: 'Zoo',
    Apartment: 'Apartament',
    House: 'Dom',
    Loft: 'Loft',
    Boat: 'Łódź',
    'Camper/RV': 'Przyczepa kempingowa/RV',
    Condominium: 'Kondominium',
    Chalet: 'Domek w stylu alpejskim',
    'Bed & Breakfast': 'Pensjonat ze śniadaniem',
    Villa: 'Willa',
    Tent: 'Namiot',
    Other: 'Inne',
    Cabin: 'Domek',
    Townhouse: 'Kamienica',
    Bungalow: 'Parterowy dom',
    Hut: 'Chata',
    Dorm: 'Akademik',
    'Parking Space': 'Miejsce parkingowe',
    Plane: 'Samolot',
    Treehouse: 'Domek na drzewie',
    Yurt: 'Jurta',
    Tipi: 'Tipi',
    Igloo: 'Igloo',
    'Earth House': 'Ziemianka',
    Island: 'Wyspa',
    Cave: 'Jaskinia',
    Castle: 'Zamek',
    Studio: 'Kawalerka',
    Cottage: 'Domek',
  },
  pt: {
    'Load more': 'Carregar mais',
    'Sort by': 'Ordenar por',
    'Amenities: All': 'Comodidades: todas',
    'Property type: All': 'Tipo de propriedade: todas',
    'Categories: All': 'Categorias: todas',
    'Bedrooms: All': 'Quartos: todos',
    'Bathrooms: All': 'Casas de banho: todas',
    'Price per night: All': 'Preço por noite: todos',
    Max: 'Máx.',
    'Starting from': 'A partir de',
    'View prices': 'Ver preços',
    'Floor surface/Property size - high to low':
      'Superfície do pavimento/tamanho da propriedade – da maior para a mais pequena',
    'Price - high to low': 'Preço – do mais alto para o mais baixo',
    'Price - low to high': 'Preço – do mais baixo para o mais alto',
    'Maximum occupancy - high to low':
      'Ocupação máxima – da mais alta para a mais baixa',
    'Count of bedrooms - high to low':
      'Número de quartos – do mais alto para o mais baixo',

    'Swimming pool': 'Piscina',
    'Accessible-height bed': 'Cama com altura acessível',
    'Accessible-height toilet': 'Sanita com altura acessível',
    'Air conditioning': 'Ar condicionado',
    'Babysitter recommendations': 'Recomendações de babysitter',
    'Baby bath': 'Banheira para bebé',
    'Baby monitor': 'Monitor para bebé',
    Bathtub: 'Banheira',
    'BBQ grill': 'Grelhador para churrasco',
    'Beach essentials': 'Básicos para praia',
    'Bed linens': 'Roupa de cama',
    Breakfast: 'Pequeno-almoço',
    'Cable TV': 'Tv por cabo',
    'Carbon monoxide detector': 'Detetor de monóxido de carbono',
    'Changing table': 'Trocador de fraldas',
    'Children’s books and toys': 'Brinquedos e livros para crianças',
    'Children’s dinnerware': 'Loiça para crianças',
    'Cleaning before checkout': 'Limpeza antes do check-out',
    'Coffee maker': 'Máquina de café',
    'Cooking basics': 'Básico de cozinha',
    Crib: 'Berço',
    'Disabled parking spot': 'Estacionamento de deficientes',
    'Dishes and silverware': 'Pratos e talheres',
    Dishwasher: 'Máquina de lavar-louça',
    'Dog(s)': 'Cão/cães',
    Doorman: 'Porteiro',
    Dryer: 'Secador',
    'Elevator in building': 'Elevador no edifício',
    Essentials: 'Básicos',
    'Ethernet connection': 'Ligação Ethernet',
    'EV charger': 'Carregador de VE',
    'Extra pillows and blankets': 'Cobertores e almofadas adicionais',
    'Fireplace guards': 'Proteção de lareira',
    'Fire extinguisher': 'Extintor de incêndio',
    'Firm mattress': 'Colchão firme',
    'First aid kit': 'Kit de primeiros socorros',
    'Flat smooth pathway to front door':
      'Caminho plano e liso até à porta da frente',
    'Free parking on premises': 'Estacionamento gratuito no local',
    'Game console': 'Consola de jogos',
    'Garden or backyard': 'Jardim ou pátio',
    'Grab-rails for shower and toilet': 'Apoios para chuveiro e sanita',
    Gym: 'Ginásio',
    'Hair dryer': 'Secador de cabelo',
    Hangers: 'Cabides',
    Heating: 'Aquecimento',
    'High chair': 'Cadeira alta',
    'Hot tub': 'Banheira de hidromassagem',
    'Hot water': 'Água quente',
    'Indoor fireplace': 'Lareira de interior',
    Internet: 'Internet',
    Iron: 'Ferro de passar',
    Kitchen: 'Cozinha',
    'Laptop friendly workspace': 'Espaço de trabalho adaptado a portáteis',
    'Long term stays allowed': 'Estadias de longo prazo permitidas',
    'Luggage dropoff allowed': 'É permitida a entrega de bagagens',
    Microwave: 'Microondas',
    'Outlet covers': 'Tampas de tomada',
    Oven: 'Forno',
    'Pack ’n Play/travel crib': 'Pack ’n Play/Berço de viagem',
    'Path to entrance lit at night': 'Caminho para entrada iluminado à noite',
    'Patio or balcony': 'Pátio ou varanda',
    'Pets allowed': 'Animais de estimação permitidos',
    'Pets live on this property':
      'Animais de estimação presentes na propriedade',
    'Pocket wifi': 'Wi-Fi portátil',
    Pool: 'Piscina',
    'Private entrance': 'Entrada privada',
    Refrigerator: 'Frigorífico',
    'Roll-in shower with shower bench or chair':
      'Banheira com acesso para deficientes com banco ou cadeira de duche',
    'Room-darkening shades': 'Cortinas de escurecimento',
    Shampoo: 'Champô',
    'Single level home': 'Casa de um piso',
    'Smoke detector': 'Detetor de fumo',
    'Smoking allowed': 'É permitido fumar',
    'Stair gates': 'Porta de segurança para escadas',
    'Step-free access': 'Acesso sem degraus',
    Stove: 'Fogão',
    'Suitable for children (2-12 years)': 'Adequado para crianças (2-12 anos)',
    'Suitable for infants (under 2 years)':
      'Adequado para crianças (com menos de 2 anos)',
    'Table corner guards': 'Protetores de esquinas para mesa',
    'Tub with shower bench': 'Banheira com banco de duche',
    Washer: 'Máquina de lavar',
    'Wide clearance to bed': 'Espaço amplo de acesso à cama',
    'Wide clearance to shower and toilet':
      'Espaço amplo de acesso ao chuveiro e sanita',
    'Wide doorway': 'Espaço amplo de acesso à porta',
    'Wide hallway clearance': 'Espaço amplo de acesso ao hall',
    'Window guards': 'Protetores de janela',
    'Wireless Internet': 'Internet sem fios',
    'Grab-rails in toilet': 'Corrimões na casa de banho',
    'Shower bench': 'Banco de duche',
    'Shower chair': 'Cadeira de duche',
    'Wheelchair accessible': 'Acesso a cadeira de rodas',
    'Body soap': 'Sabonete',
    'Cleaning products': 'Produtos de limpeza',
    Conditioner: 'Condicionador',
    'Shower gel': 'Gel de banho',
    'Towels provided': 'Toalhas fornecidas',
    'Clothing storage': 'Espaço de arrumação para roupa',
    'Coin Laundry': 'Lavandaria automática',
    'Dryer in common space': 'Secador no espaço comum',
    'Mosquito net': 'Rede mosquiteira',
    'Washer in common space': 'Máquina de lavar no espaço comum',
    'DVD player': 'Leitor de DVD',
    'Foosball table': 'Mesa de matraquilhos',
    'Game room': 'Sala de jogos',
    Piano: 'Piano',
    'Ping pong table': 'Mesa de ping pong',
    'Pool table': 'Mesa de bilhar',
    'Sound system': 'Sistema de som',
    'Board games': 'Jogos de tabuleiro',
    'Family/kid friendly': 'Adequado para crianças/famílias',
    'Portable fans': 'Ventoinhas portáteis',
    'Emergency exit': 'Saída de emergência',
    'Baking sheet': 'Tabuleiro de levar ao forno',
    'Barbeque utensils': 'Utensílios de churrasco',
    Blender: 'Liquidificador',
    Coffee: 'Café',
    Cookware: 'Utensílios de cozinha',
    'Dining table': 'Mesa de jantar',
    Freezer: 'Congelador',
    'Ice maker': 'Máquina de gelo',
    Kettle: 'Chaleira',
    'Mini fridge': 'Mini-frigorífico',
    'Rice maker': 'Arrozeira',
    Toaster: 'Torradeira',
    'Trash compactor': 'Compactador de lixo',
    'Wine glasses': 'Copos de vinho',
    Beach: 'Praia',
    'Beach Front': 'Em frente à praia',
    'Beach View': 'Vista para a praia',
    'Beach access': 'Acesso à praia',
    'City View': 'Vista para a cidade',
    'Desert View': 'Vista do deserto',
    Downtown: 'Centro da cidade',
    'Garden View': 'Vista para o jardim',
    'Golf course front': 'Em frente ao campo de golfe',
    'Golf view': 'Vista para o campo de golfe',
    Lake: 'Lago',
    'Lake Front': 'Em frente ao lago',
    'Lake access': 'Acesso ao lago',
    'Laundromat nearby': 'Lavandaria nas proximidades',
    Mountain: 'Montanha',
    'Mountain view': 'Vista para a montanha',
    'Near Ocean': 'Próximo ao oceano',
    'Ocean Front': 'Em frente ao oceano',
    Resort: 'Resort',
    'Resort access': 'Acesso ao resort',
    Rural: 'Rural',
    'Sea view': 'Vista para o mar',
    'Ski In': 'Entrada de esqui',
    'Ski Out': 'Saída de esqui',
    'Ski in/Ski out': 'Entrada/saída de esqui',
    Town: 'Cidade',
    Village: 'Aldeia',
    'Water View': 'Vista para a água',
    Waterfront: 'Em frente à água',
    'Cleaning Disinfection': 'Limpeza desinfetante',
    Desk: 'Secretária',
    'Enhanced cleaning practices': 'Práticas de limpeza melhoradas',
    'High touch surfaces disinfected':
      'Superfícies de alto contacto desinfetadas',
    'Bicycles available': 'Bicicletas disponíveis',
    Bikes: 'Bicicletas',
    'Boat slip': 'Desembarque de barco',
    'Fire Pit': 'Lareira exterior',
    Hammock: 'Cama de rede',
    Kayak: 'Caiaque',
    'Outdoor kitchen': 'Cozinha exterior',
    'Outdoor seating (furniture)': 'Assentos exteriores (mobília)',
    River: 'Rio',
    'Free parking on street': 'Estacionamento gratuito na rua',
    Garage: 'Garagem',
    'Paid parking': 'Estacionamento pago',
    'Paid parking off premises': 'Estacionamento pago fora das instalações',
    'Communal pool': 'Piscina partilhada',
    'Indoor pool': 'Piscina interior',
    'Outdoor pool': 'Piscina exterior',
    'Private pool': 'Piscina privada',
    'Rooftop pool': 'Piscina na cobertura',
    Elevator: 'Elevador',
    TV: 'TV',
    'Ceiling fan': 'Ventoinha de teto',
    'Pocket Wifi': 'Wi-Fi portátil',
    Safe: 'Cofre',
    'Stereo system': 'Sistema estéreo',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Casinos',
    Cycling: 'Ciclismo',
    Fishing: 'Pesca',
    'Golf - Optional': 'Golf – Opcional',
    'Horseback Riding': 'Equitação',
    'Mountain Climbing': 'Escalada',
    Museums: 'Museus',
    'Rock Climbing': 'Escalada em rocha',
    Shopping: 'Shopping',
    'Theme Parks': 'Parques temáticos',
    'Water Parks': 'Parques aquáticos',
    'Water Sports': 'Desportos aquáticos',
    Zoo: 'Zoo',
    Apartment: 'Apartamento',
    House: 'Casa',
    Loft: 'Sótão',
    Boat: 'Barco',
    'Camper/RV': 'Caravana/Autocaravana',
    Condominium: 'Condomínio',
    Chalet: 'Chalet',
    'Bed & Breakfast': 'Alojamento com pequeno-almoço',
    Villa: 'Vila',
    Tent: 'Tenda',
    Other: 'Outro',
    Cabin: 'Cabana',
    Townhouse: 'Moradia',
    Bungalow: 'Bangalô',
    Hut: 'Cabana',
    Dorm: 'Dormitório',
    'Parking Space': 'Espaço de estacionamento',
    Plane: 'Avião',
    Treehouse: 'Casa da árvore',
    Yurt: 'Yurt',
    Tipi: 'Tipi',
    Igloo: 'Iglu',
    'Earth House': 'Casa orgânica',
    Island: 'Ilha',
    Cave: 'Caverna',
    Castle: 'Castelo',
    Studio: 'Estúdio',
    Cottage: 'Casa de campo',
  },
  zh: {
    'Load more': '加載更多',
    'Sort by': '排序方式',
    'Amenities: All': '设施：全部',
    'Property type: All': '物业类型：全部',
    'Categories: All': '类别：全部',
    'Bedrooms: All': '卧室：全部',
    'Bathrooms: All': '浴室：全部',
    'Price per night: All': '每晚价格：全部',
    Max: '最高价格',
    'Starting from': '最低价格',
    'View prices': '查看价格',
    'Floor surface/Property size - high to low': '地板表面/物业面积 - 从高到低',
    'Price - high to low': '价格 - 从高到低',
    'Price - low to high': '价格 - 从低到高',
    'Maximum occupancy - high to low': '最大入住率 - 从高到低',
    'Count of bedrooms - high to low': '卧室数量 - 从高到低',

    'Swimming pool': '游泳池',
    'Accessible-height bed': '无障碍高度床',
    'Accessible-height toilet': '无障碍高度卫生间',
    'Air conditioning': '空调',
    'Babysitter recommendations': '保姆推荐',
    'Baby bath': '婴儿浴池',
    'Baby monitor': '婴儿监视器',
    Bathtub: '浴缸',
    'BBQ grill': '烧烤架',
    'Beach essentials': '海滩必需品',
    'Bed linens': '床上用品',
    Breakfast: '早餐',
    'Cable TV': '有线电视',
    'Carbon monoxide detector': '一氧化碳检测器',
    'Changing table': '换尿布台',
    'Children’s books and toys': '儿童读物和玩具',
    'Children’s dinnerware': '儿童餐具',
    'Cleaning before checkout': '退房前清洁',
    'Coffee maker': '咖啡机',
    'Cooking basics': '烹饪基本用品',
    Crib: '婴儿床',
    'Disabled parking spot': '无障碍停车位',
    'Dishes and silverware': '碗碟和餐具',
    Dishwasher: '洗碗机',
    'Dog(s)': '狗',
    Doorman: '门童',
    Dryer: '烘干机',
    'Elevator in building': '楼内电梯',
    Essentials: '必需品',
    'Ethernet connection': '以太网连接',
    'EV charger': '充电桩',
    'Extra pillows and blankets': '额外的枕头和毯子',
    'Fireplace guards': '壁炉防护罩',
    'Fire extinguisher': '灭火器',
    'Firm mattress': '硬床垫',
    'First aid kit': '急救箱',
    'Flat smooth pathway to front door': '通往前门的平坦光滑通道',
    'Free parking on premises': '设有免费停车场',
    'Game console': '游戏机',
    'Garden or backyard': '花园或后院',
    'Grab-rails for shower and toilet': '淋浴和卫生间用扶手',
    Gym: '健身房',
    'Hair dryer': '吹风机',
    Hangers: '衣架',
    Heating: '暖气',
    'High chair': '高脚椅',
    'Hot tub': '热水浴缸',
    'Hot water': '热水',
    'Indoor fireplace': '室内壁炉',
    Internet: '互联网',
    Iron: '熨斗',
    Kitchen: '厨房',
    'Laptop friendly workspace': '适合使用笔记本电脑的工作区',
    'Long term stays allowed': '允许长期住宿',
    'Luggage dropoff allowed': '允许行李下架',
    Microwave: '微波炉',
    'Outlet covers': '插座盖',
    Oven: '烤箱',
    'Pack ’n Play/travel crib': '即装即走 / 旅行婴儿床',
    'Path to entrance lit at night': '入口通道灯会在晚上打开',
    'Patio or balcony': '露台或阳台',
    'Pets allowed': '允许携带宠物',
    'Pets live on this property': '此房产可养宠物',
    'Pocket wifi': '随身 WiFi ',
    Pool: '泳池',
    'Private entrance': '私人入口',
    Refrigerator: '冰箱',
    'Roll-in shower with shower bench or chair': '带淋浴凳或椅子的无障碍淋浴间',
    'Room-darkening shades': '房间遮阳物',
    Shampoo: '洗发水',
    'Single level home': '单层住宅',
    'Smoke detector': '烟雾探测器',
    'Smoking allowed': '允许吸烟',
    'Stair gates': '楼梯安全护栏',
    'Step-free access': '无台阶通道',
    Stove: '火炉',
    'Suitable for children (2-12 years)': '适合儿童（2-12 岁）',
    'Suitable for infants (under 2 years)': '适合婴儿（2 岁以下）',
    'Table corner guards': '桌子护角',
    'Tub with shower bench': '带淋浴凳的浴缸',
    Washer: '洗衣机',
    'Wide clearance to bed': '宽敞的床铺间距',
    'Wide clearance to shower and toilet': '宽敞的淋浴区和如厕区间距',
    'Wide doorway': '宽阔的门道',
    'Wide hallway clearance': '宽阔的走廊',
    'Window guards': '窗户防护栏',
    'Wireless Internet': '无线互联网',
    'Grab-rails in toilet': '卫生间配置扶手',
    'Shower bench': '淋浴凳',
    'Shower chair': '淋浴椅',
    'Wheelchair accessible': '无障碍通道',
    'Body soap': '沐浴皂',
    'Cleaning products': '清洁用品',
    Conditioner: '护发素',
    'Shower gel': '沐浴露',
    'Towels provided': '毛巾',
    'Clothing storage': '衣物储存',
    'Coin Laundry': '投币式洗衣机',
    'Dryer in common space': '公共空间配置烘干机',
    'Mosquito net': '蚊帐',
    'Washer in common space': '公共空间配置洗衣机',
    'DVD player': 'DVD 播放机',
    'Foosball table': '桌上足球',
    'Game room': '游戏室',
    Piano: '钢琴',
    'Ping pong table': '乒乓球桌',
    'Pool table': '台球桌',
    'Sound system': '音响系统',
    'Board games': '棋盘游戏',
    'Family/kid friendly': '适合家庭/儿童',
    'Portable fans': '便携式风扇',
    'Emergency exit': '紧急出口',
    'Baking sheet': '烤盘',
    'Barbeque utensils': '烧烤用具',
    Blender: '搅拌机',
    Coffee: '咖啡',
    Cookware: '炊具',
    'Dining table': '餐桌',
    Freezer: '冰箱',
    'Ice maker': '制冰机',
    Kettle: '水壶',
    'Mini fridge': '迷你冰箱',
    'Rice maker': '电饭锅',
    Toaster: ' 烤面包机',
    'Trash compactor': '垃圾压实机',
    'Wine glasses': '葡萄酒杯',
    Beach: '海滩',
    'Beach Front': '海滨',
    'Beach View': '海滩景观',
    'Beach access': '海滩通道',
    'City View': '城市景观',
    'Desert View': '沙漠景观',
    Downtown: '市中心',
    'Garden View': '花园景观',
    'Golf course front': '高尔夫球场前面',
    'Golf view': '高尔夫景观',
    Lake: '湖',
    'Lake Front': '湖滨',
    'Lake access': '湖泊通道',
    'Laundromat nearby': '附近有自助洗衣店',
    Mountain: '山',
    'Mountain view': '山景',
    'Near Ocean': '近海',
    'Ocean Front': '海滨',
    Resort: '度假胜地',
    'Resort access': '度假村入口',
    Rural: '乡村',
    'Sea view': '海景',
    'Ski In': '滑雪场入口',
    'Ski Out': '滑雪场出口',
    'Ski in/Ski out': '滑雪场进/出口',
    Town: '市镇',
    Village: '乡村',
    'Water View': '水景',
    Waterfront: '水边',
    'Cleaning Disinfection': '清洁消毒',
    Desk: '桌子',
    'Enhanced cleaning practices': '加强清洁措施',
    'High touch surfaces disinfected': '频繁接触表面消毒',
    'Bicycles available': '提供自行车',
    Bikes: '自行车',
    'Boat slip': '船坡道',
    'Fire Pit': '火坑',
    Hammock: '吊床',
    Kayak: '皮艇',
    'Outdoor kitchen': '室外厨房',
    'Outdoor seating (furniture)': '室外座椅（家具）',
    River: '河流',
    'Free parking on street': '街边免费停车',
    Garage: '车库',
    'Paid parking': '付费停车',
    'Paid parking off premises': '室外有付费停车场',
    'Communal pool': '公共泳池',
    'Indoor pool': '室内游泳池',
    'Outdoor pool': '室外游泳池',
    'Private pool': '私人泳池',
    'Rooftop pool': '屋顶泳池',
    Elevator: '电梯',
    TV: '电视',
    'Ceiling fan': '吊扇',
    'Pocket Wifi': '随身 Wi-Fi',
    Safe: '保险柜',
    'Stereo system': '立体声音响系统',
    Spa: '温泉',
    Sauna: '桑拿',
    Casinos: '娱乐场',
    Cycling: '自行车骑行',
    Fishing: '钓鱼',
    'Golf - Optional': '高尔夫 - 可选',
    'Horseback Riding': '骑马',
    'Mountain Climbing': '登山',
    Museums: '博物馆',
    'Rock Climbing': '攀岩',
    Shopping: '购物',
    'Theme Parks': '主题公园',
    'Water Parks': '水上乐园',
    'Water Sports': '水上运动',
    Zoo: '动物园',
    Apartment: '公寓',
    House: '住房',
    Loft: '阁楼',
    Boat: '小船',
    'Camper/RV': '野营车/房车',
    Condominium: '共管式公寓',
    Chalet: '木屋',
    'Bed & Breakfast': '床和早餐',
    Villa: '别墅',
    Tent: '帐篷',
    Other: '其他',
    Cabin: '小屋',
    Townhouse: '联排别墅',
    Bungalow: '平房',
    Hut: '棚屋',
    Dorm: '宿舍',
    'Parking Space': '停车位',
    Plane: '飞机',
    Treehouse: '树屋',
    Yurt: '蒙古包',
    Tipi: '锥篷',
    Igloo: '穹屋',
    'Earth House': '���宅',
    Island: '岛屿',
    Cave: '洞穴',
    Castle: '城堡',
    Studio: '工作室',
    Cottage: '小屋',
  },
  ro: {
    'Load more': 'Încărcați mai multe',
    'Sort by': 'Sortare după',
    'Amenities: All': 'Dotări: toate',
    'Property type: All': 'Tip de proprietate: toate',
    'Categories: All': 'Categorii: toate',
    'Bedrooms: All': 'Dormitoare: toate',
    'Bathrooms: All': 'Băi: toate',
    'Price per night: All': 'Preț pe noapte: toate',
    Max: 'Max.',
    'Starting from': 'Începând de la',
    'View prices': 'Vizualizare prețuri',
    'Floor surface/Property size - high to low':
      'Suprafață clădire/Dimensiune proprietate - de la mare la mică',
    'Price - high to low': 'Preț - de la mare la mic',
    'Price - low to high': 'Preț - de la mic la mare',
    'Maximum occupancy - high to low': 'Ocupare maximă - de la mare la mică',
    'Count of bedrooms - high to low':
      'Număr de dormitoare - de la mare la mic',

    'Swimming pool': 'Piscină',
    'Accessible-height bed': 'Pat de înălțime accesibilă',
    'Accessible-height toilet': 'Toaletă de înălțime accesibilă',
    'Air conditioning': 'Aer condiționat',
    'Babysitter recommendations': 'Recomandări pentru bonă',
    'Baby bath': 'Baie pentru bebeluși',
    'Baby monitor': 'Monitor pentru bebeluși',
    Bathtub: 'Cadă',
    'BBQ grill': 'Grătar',
    'Beach essentials': 'Produse esențiale pentru plajă',
    'Bed linens': 'Lenjerie de pat',
    Breakfast: 'Mic dejun',
    'Cable TV': 'TV prin cablu',
    'Carbon monoxide detector': 'Detector de monoxid de carbon',
    'Changing table': 'Masă de înfășat',
    'Children’s books and toys': 'Cărți și jucării pentru copii',
    'Children’s dinnerware': 'Tacâmuri pentru copii',
    'Cleaning before checkout': 'Curățare înainte de plecare',
    'Coffee maker': 'Cafetieră',
    'Cooking basics': 'Ustensile esențiale pentru gătit',
    Crib: 'Pătuț de bebeluși',
    'Disabled parking spot': 'Loc de parcare pentru persoane cu dizabilități',
    'Dishes and silverware': 'Vase și tacâmuri',
    Dishwasher: 'Mașină de spălat vase',
    'Dog(s)': 'Câini',
    Doorman: 'Portar',
    Dryer: 'Uscător',
    'Elevator in building': 'Lift în clădire',
    Essentials: 'Produse esențiale',
    'Ethernet connection': 'Conexiune Ethernet',
    'EV charger': 'Încărcător de vehicule electrice',
    'Extra pillows and blankets': 'Perne și pături suplimentare',
    'Fireplace guards': 'Grilaje șemineu',
    'Fire extinguisher': 'Extinctor',
    'Firm mattress': 'Saltea fermă',
    'First aid kit': 'Trusă de prim ajutor',
    'Flat smooth pathway to front door':
      'Drum întins și uniform spre ușa de la intrare',
    'Free parking on premises': 'Parcare gratuită la fața locului',
    'Game console': 'Consolă de jocuri',
    'Garden or backyard': 'Grădină sau curte',
    'Grab-rails for shower and toilet': 'Mânere pentru duș și toaletă',
    Gym: 'Sală de sport',
    'Hair dryer': 'Uscător de păr',
    Hangers: 'Umerașe',
    Heating: 'Încălzire',
    'High chair': 'Scaun înalt',
    'Hot tub': 'Jacuzzi',
    'Hot water': 'Apă caldă',
    'Indoor fireplace': 'Șemineu la interior',
    Internet: 'Internet',
    Iron: 'Fier de călcat',
    Kitchen: 'Bucătărie',
    'Laptop friendly workspace': 'Spațiu de lucru pentru laptop',
    'Long term stays allowed': 'Sunt permise sejururile pe termen lung',
    'Luggage dropoff allowed': 'Este permisă depozitarea bagajelor',
    Microwave: 'Cuptor cu microunde',
    'Outlet covers': 'Protecție priză',
    Oven: 'Cuptor',
    'Pack ’n Play/travel crib': 'Pătuț de bebeluși portabil',
    'Path to entrance lit at night':
      'Drumul către intrare este luminată pe timp de noapte',
    'Patio or balcony': 'Terasă sau balcon',
    'Pets allowed': 'Animalele de companie sunt permise',
    'Pets live on this property':
      'Pe această proprietate locuiesc animale de companie',
    'Pocket wifi': 'Wi-Fi portabil',
    Pool: 'Piscină',
    'Private entrance': 'Intrare privată',
    Refrigerator: 'Frigider',
    'Roll-in shower with shower bench or chair':
      'Duș accesibil cu bancă sau scaun pentru duș',
    'Room-darkening shades': 'Rulouri opace',
    Shampoo: 'Șampon',
    'Single level home': 'Casă pe un singur nivel',
    'Smoke detector': 'Detector de fum',
    'Smoking allowed': 'Fumatul permis',
    'Stair gates': 'Barieră de siguranță pentru scări',
    'Step-free access': 'Acces fără trepte',
    Stove: 'Plită',
    'Suitable for children (2-12 years)':
      'Potrivit pentru copii (între 2 și 12 ani)',
    'Suitable for infants (under 2 years)':
      'Potrivit pentru bebeluși (sub 2 ani)',
    'Table corner guards': 'Protecții colțuri pentru masă',
    'Tub with shower bench': 'Cadă cu bancă de duș',
    Washer: 'Spălător',
    'Wide clearance to bed': 'Spațiu încăpător în jurul patului',
    'Wide clearance to shower and toilet':
      'Spațiu încăpător spre duș și toaletă',
    'Wide doorway': 'Intrare încăpătoare',
    'Wide hallway clearance': 'Hol încăpător',
    'Window guards': 'Grilaje fereastră',
    'Wireless Internet': 'Internet wireless',
    'Grab-rails in toilet': 'Bare de susținere în toaletă',
    'Shower bench': 'Bancă pentru duș',
    'Shower chair': 'Scaun pentru duș',
    'Wheelchair accessible': 'Accesibil pentru scaunul cu rotile',
    'Body soap': 'Săpun de corp',
    'Cleaning products': 'Produse de curățare',
    Conditioner: 'Balsam',
    'Shower gel': 'Gel de duș',
    'Towels provided': 'Prosoape incluse',
    'Clothing storage': 'Spațiu de depozitare pentru îmbrăcăminte',
    'Coin Laundry': 'Spălătorie automată',
    'Dryer in common space': 'Uscător în spațiul comun',
    'Mosquito net': 'Plasă de țânțari',
    'Washer in common space': 'Mașină de spălat în spațiul comun',
    'DVD player': 'Player DVD',
    'Foosball table': 'Masă de foosball',
    'Game room': 'Sală de jocuri',
    Piano: 'Pian',
    'Ping pong table': 'Masă de ping pong',
    'Pool table': 'Masă de biliard',
    'Sound system': 'Sistem de sunet',
    'Board games': 'Jocuri de societate',
    'Family/kid friendly': 'Potrivit pentru familie/copii',
    'Portable fans': 'Ventilatoare portabile',
    'Emergency exit': 'Ieșire de urgență',
    'Baking sheet': 'Tavă de copt',
    'Barbeque utensils': 'Ustensile de grătar',
    Blender: 'Blender',
    Coffee: 'Cafea',
    Cookware: 'Vase de gătit',
    'Dining table': 'Masă de mâncat',
    Freezer: 'Congelator',
    'Ice maker': 'Mașină de înghețată',
    Kettle: 'Fierbător de apă',
    'Mini fridge': 'Mini frigider',
    'Rice maker': 'Aparat de gătit orez',
    Toaster: 'Prăjitor de pâine',
    'Trash compactor': 'Compactor de gunoi',
    'Wine glasses': 'Pahare de vin',
    Beach: 'Plajă',
    'Beach Front': 'Pe plajă',
    'Beach View': 'Vedere spre plajă',
    'Beach access': 'Acces la plajă',
    'City View': 'Vedere spre oraș',
    'Desert View': 'Vedere spre deșert',
    Downtown: 'Centrul orașului',
    'Garden View': 'Vedere spre grădină',
    'Golf course front': 'În fața terenului de golf',
    'Golf view': 'Vedere spre terenul de golf',
    Lake: 'Lac',
    'Lake Front': 'Pe marginea lacului',
    'Lake access': 'Acces la lac',
    'Laundromat nearby': 'Spălătorie în apropiere',
    Mountain: 'Munte',
    'Mountain view': 'Vedere spre munte',
    'Near Ocean': 'Lângă ocean',
    'Ocean Front': 'Pe malul oceanului',
    Resort: 'Resort',
    'Resort access': 'Acces la resort',
    Rural: 'Rural',
    'Sea view': 'Vedere spre mare',
    'Ski In': 'Acces de pe pârtie pe schiuri',
    'Ski Out': 'Acces pe pârtie pe schiuri',
    'Ski in/Ski out': 'Acces pe/de pe pârtie pe schiuri',
    Town: 'Oraș',
    Village: 'Sat',
    'Water View': 'Vedere spre apă',
    Waterfront: 'Pe malul apei',
    'Cleaning Disinfection': 'Curățenie și dezinfecție',
    Desk: 'Birou',
    'Enhanced cleaning practices': 'Practici de curățenie îmbunătățite',
    'High touch surfaces disinfected': 'Dezinfecția suprafețelor atinse des',
    'Bicycles available': 'Biciclete disponibile',
    Bikes: 'Biciclete',
    'Boat slip': 'Ponton pentru barcă',
    'Fire Pit': 'Vatră de foc',
    Hammock: 'Hamac',
    Kayak: 'Caiac',
    'Outdoor kitchen': 'Bucătărie în aer liber',
    'Outdoor seating (furniture)': 'Locuri de stat în exterior (mobilă)',
    River: 'Râu',
    'Free parking on street': 'Parcare gratuită pe stradă',
    Garage: 'Garaj',
    'Paid parking': 'Parcare cu plată',
    'Paid parking off premises': 'Parcare cu plată în afara locației',
    'Communal pool': 'Piscină comună',
    'Indoor pool': 'Piscină la interior',
    'Outdoor pool': 'Piscină la exterior',
    'Private pool': 'Piscină privată',
    'Rooftop pool': 'Piscină pe acoperiș',
    Elevator: 'Lift',
    TV: 'TV',
    'Ceiling fan': 'Ventilator de tavan',
    'Pocket Wifi': 'Wi-Fi portabil',
    Safe: 'Seif',
    'Stereo system': 'Sistem stereo',
    Spa: 'Spa',
    Sauna: 'Saună',
    Casinos: 'Cazinouri',
    Cycling: 'Ciclism',
    Fishing: 'Pescuit',
    'Golf - Optional': 'Golf - Opțional',
    'Horseback Riding': 'Călărie',
    'Mountain Climbing': 'Excursii pe munte',
    Museums: 'Muzee',
    'Rock Climbing': 'Alpinism',
    Shopping: 'Cumpărături',
    'Theme Parks': 'Parcuri tematice',
    'Water Parks': 'Parcuri acvatice',
    'Water Sports': 'Sporturi acvatice',
    Zoo: 'Zoo',
    Apartment: 'Apartament',
    House: 'Casă',
    Loft: 'Mansardă',
    Boat: 'Barcă',
    'Camper/RV': 'Rulotă/vehicul de agrement',
    Condominium: 'Condominium',
    Chalet: 'Cabană',
    'Bed & Breakfast': 'Cazare și mic dejun',
    Villa: 'Vilă',
    Tent: 'Cort',
    Other: 'Altele',
    Cabin: 'Căsuță',
    Townhouse: 'Casă',
    Bungalow: 'Bungalou',
    Hut: 'Colibă',
    Dorm: 'Cămin',
    'Parking Space': 'Loc de parcare',
    Plane: 'Avion',
    Treehouse: 'Casă în copac',
    Yurt: 'Iurtă',
    Tipi: 'Cort indian',
    Igloo: 'Iglu',
    'Earth House': 'Casă în pământ',
    Island: 'Insulă',
    Cave: 'Peșteră',
    Castle: 'Castel',
    Studio: 'Garsonieră',
    Cottage: 'Cabană',
  },
  in: {
    'Load more': 'Muat lebih banyak',
    'Sort by': 'Sortir berdasarkan',
    'Amenities: All': 'Fasilitas: Semua',
    'Property type: All': 'Jenis properti: Semua',
    'Categories: All': 'Kategori: Semua',
    'Bedrooms: All': 'Kamar tidur: Semua',
    'Bathrooms: All': 'Kamar mandi: Semua',
    'Price per night: All': 'Harga per malam: Semua',
    Max: 'Maks.',
    'Starting from': 'Mulai dari',
    'View prices': 'Lihat harga',
    'Floor surface/Property size - high to low':
      'Permukaan lantai/Ukuran properti - tinggi ke rendah',
    'Price - high to low': 'Harga - tinggi ke rendah',
    'Price - low to high': 'Harga - rendah ke tinggi',
    'Maximum occupancy - high to low':
      'Keterhunian maksimal - tinggi ke rendah',
    'Count of bedrooms - high to low': 'Jumlah kamar tidur - tinggi ke rendah',

    'Swimming pool': 'Kolam renang',
    'Accessible-height bed': 'Tempat tidur dengan tinggi yang dapat diakses',
    'Accessible-height toilet': 'Toilet dengan tinggi yang dapat diakses',
    'Air conditioning': 'AC',
    'Babysitter recommendations': 'Rekomendasi pengasuh bayi',
    'Baby bath': 'Bak mandi bayi',
    'Baby monitor': 'Pemantau Bayi',
    Bathtub: 'Bak mandi',
    'BBQ grill': 'Panggangan BBQ',
    'Beach essentials': 'Barang-barang penting di pantai',
    'Bed linens': 'Sprei',
    Breakfast: 'Sarapan',
    'Cable TV': 'TV Kabel',
    'Carbon monoxide detector': 'Detektor karbon monoksida',
    'Changing table': 'Meja ganti popok untuk bayi',
    'Children’s books and toys': 'Buku dan mainan anak',
    'Children’s dinnerware': 'Peralatan makan anak',
    'Cleaning before checkout': 'Dibersihkan sebelum check-out',
    'Coffee maker': 'Mesin pembuat kopi',
    'Cooking basics': 'Alat masak dasar',
    Crib: 'Tempat tidur bayi',
    'Disabled parking spot': 'Tempat parkir difabel',
    'Dishes and silverware': 'Piring dan alat makan perak',
    Dishwasher: 'Pencuci piring',
    'Dog(s)': 'Anjing',
    Doorman: 'Penjaga Pintu',
    Dryer: 'Mesin Pengering',
    'Elevator in building': 'Lift dalam bangunan',
    Essentials: 'Barang Penting',
    'Ethernet connection': 'Koneksi ethernet',
    'EV charger': 'Pengisi daya EV',
    'Extra pillows and blankets': 'Bantal dan selimut tambahan',
    'Fireplace guards': 'Pelindung perapian',
    'Fire extinguisher': 'Pemadam api',
    'Firm mattress': 'Matras padat',
    'First aid kit': 'Kotak P3K',
    'Flat smooth pathway to front door':
      'Jalur datar yang mulus ke pintu depan',
    'Free parking on premises': 'Parkir gratis di tempat',
    'Game console': 'Konsol gim',
    'Garden or backyard': 'Kebun atau halaman belakang',
    'Grab-rails for shower and toilet':
      'Pegangan tangan untuk kamar mandi dan toilet',
    Gym: 'Ruang Olahraga',
    'Hair dryer': 'Pengering rambut',
    Hangers: 'Gantungan Baju',
    Heating: 'Penghangat',
    'High chair': 'Kursi tinggi',
    'Hot tub': 'Bak mandi air panas',
    'Hot water': 'Air panas',
    'Indoor fireplace': 'Perapian dalam ruangan',
    Internet: 'Internet',
    Iron: 'Setrika',
    Kitchen: 'Dapur',
    'Laptop friendly workspace': 'Ruang kerja ramah laptop',
    'Long term stays allowed': 'Menginap lama diperbolehkan',
    'Luggage dropoff allowed': 'Penurunan bagasi diperbolehkan',
    Microwave: 'Microwave',
    'Outlet covers': 'Penutup soket',
    Oven: 'Oven',
    'Pack ’n Play/travel crib': "Pack 'n Play/Tempat tidur bayi di perjalanan",
    'Path to entrance lit at night':
      'Jalan ke pintu masuk diterangi lampu di malam hari',
    'Patio or balcony': 'Teras atau balkon',
    'Pets allowed': 'Hewan peliharaan diperbolehkan',
    'Pets live on this property': 'Ada hewan peliharaaan di properti ini',
    'Pocket wifi': 'WiFi saku',
    Pool: 'Kolam renang',
    'Private entrance': 'Pintu masuk pribadi',
    Refrigerator: 'Kulkas',
    'Roll-in shower with shower bench or chair':
      'Kamar mandi luas dengan kursi mandi',
    'Room-darkening shades': 'Penutup jendela',
    Shampoo: 'Sampo',
    'Single level home': 'Rumah satu lantai',
    'Smoke detector': 'Detektor asap',
    'Smoking allowed': 'Boleh merokok',
    'Stair gates': 'Pintu tangga',
    'Step-free access': 'Akses bebas tangga',
    Stove: 'Kompor',
    'Suitable for children (2-12 years)': 'Cocok untuk anak-anak (2-12 tahun)',
    'Suitable for infants (under 2 years)':
      'Cocok untuk balita (di bawah 2 tahun)',
    'Table corner guards': 'Pelindung sudut meja',
    'Tub with shower bench': 'Bak dengan kursi mandi',
    Washer: 'Mesin Cuci',
    'Wide clearance to bed': 'Ruang bebas yang lebar ke tempat tidur',
    'Wide clearance to shower and toilet':
      'Ruang bebas lebar ke kamar mandi dan toilet',
    'Wide doorway': 'Jalur pintu yang lebar',
    'Wide hallway clearance': 'Ruang bebas lorong yang lebar',
    'Window guards': 'Pelindung jendela',
    'Wireless Internet': 'Internet Nirkabel',
    'Grab-rails in toilet': 'Pegangan tangan di toilet',
    'Shower bench': 'Kursi mandi',
    'Shower chair': 'Kursi mandi',
    'Wheelchair accessible': 'Dapat diakses kursi roda',
    'Body soap': 'Sabun badan',
    'Cleaning products': 'Produk kebersihan',
    Conditioner: 'Kondisioner',
    'Shower gel': 'Gel mandi',
    'Towels provided': 'Handuk disediakan',
    'Clothing storage': 'Penyimpanan baju',
    'Coin Laundry': 'Laundri Koin',
    'Dryer in common space': 'Pengering di ruang bersama',
    'Mosquito net': 'Kasa nyamuk',
    'Washer in common space': 'Mesin cuci di ruang bersama',
    'DVD player': 'Pemutar DVD',
    'Foosball table': 'Meja foosball',
    'Game room': 'Ruang permainan',
    Piano: 'Piano',
    'Ping pong table': 'Meja pingpong',
    'Pool table': 'Meja biliar',
    'Sound system': 'Sound system',
    'Board games': 'Permainan papan',
    'Family/kid friendly': 'Ramah keluarga/anak',
    'Portable fans': 'Kipas portabel',
    'Emergency exit': 'Pintu darurat',
    'Baking sheet': 'Loyang',
    'Barbeque utensils': 'Peralatan barbekyu',
    Blender: 'Blender',
    Coffee: 'Kopi',
    Cookware: 'Alat masak',
    'Dining table': 'Meja makan',
    Freezer: 'Freezer',
    'Ice maker': 'Pembuat es',
    Kettle: 'Teko',
    'Mini fridge': 'Kulkas mini',
    'Rice maker': 'Penanak nasi',
    Toaster: 'Pemanggang',
    'Trash compactor': 'Pemadat sampah',
    'Wine glasses': 'Gelas anggur',
    Beach: 'Pantai',
    'Beach Front': 'Menghadap Pantai',
    'Beach View': 'Pemandangan Pantai',
    'Beach access': 'Akses ke pantai',
    'City View': 'Pemandangan Kota',
    'Desert View': 'Pemandangan Gurun',
    Downtown: 'Pusat Kota',
    'Garden View': 'Pemandangan Kebun',
    'Golf course front': 'Menghadap lapangan golf',
    'Golf view': 'Pemandangan golf',
    Lake: 'Danau',
    'Lake Front': 'Menghadap Danau',
    'Lake access': 'Akses ke danau',
    'Laundromat nearby': 'Landromat di sekitar',
    Mountain: 'Gunung',
    'Mountain view': 'Pemandangan gunung',
    'Near Ocean': 'Dekat Samudera',
    'Ocean Front': 'Menghadap Samudera',
    Resort: 'Resort',
    'Resort access': 'Akses ke resort',
    Rural: 'Pedesaan',
    'Sea view': 'Pemandangan laut',
    'Ski In': 'Ski In',
    'Ski Out': 'Ski Out',
    'Ski in/Ski out': 'Ski in/Ski out',
    Town: 'Kota',
    Village: 'Desa',
    'Water View': 'Pemandangan Air',
    Waterfront: 'Menghadap Air',
    'Cleaning Disinfection': 'Disinfeksi Pembersih',
    Desk: 'Meja',
    'Enhanced cleaning practices': 'Praktek kebersihan yang ditingkatkan',
    'High touch surfaces disinfected':
      'Permukaan yang sering disentuh didisinfeksi',
    'Bicycles available': 'Sepeda tersedia',
    Bikes: 'Motor',
    'Boat slip': 'Dermaga',
    'Fire Pit': 'Lubang Perapian',
    Hammock: 'Kasur Gantung',
    Kayak: 'Kayak',
    'Outdoor kitchen': 'Dapur luar ruangan',
    'Outdoor seating (furniture)': 'Kursi luar ruangan (furnitur)',
    River: 'Sungai',
    'Free parking on street': 'Parkir gratis di jalan',
    Garage: 'Garasi',
    'Paid parking': 'Parkir berbayar',
    'Paid parking off premises': 'Parkir berbayar di luar tempat',
    'Communal pool': 'Kolam renang masyarakat',
    'Indoor pool': 'Kolam renang dalam ruangan',
    'Outdoor pool': 'Kolam renang luar ruangan',
    'Private pool': 'Kolam renang pribadi',
    'Rooftop pool': 'Kolam renang atap',
    Elevator: 'Lift',
    TV: 'TV',
    'Ceiling fan': 'Kipas angin atap',
    'Pocket Wifi': 'WiFi Saku',
    Safe: 'Brankas',
    'Stereo system': 'Sistem stereo',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Kasino',
    Cycling: 'Bersepeda',
    Fishing: 'Memancing',
    'Golf - Optional': 'Golf - Opsional',
    'Horseback Riding': 'Berkuda',
    'Mountain Climbing': 'Mendaki Gunung',
    Museums: 'Museum',
    'Rock Climbing': 'Panjat Tebing',
    Shopping: 'Berbelanja',
    'Theme Parks': 'Taman Tema',
    'Water Parks': 'Taman Air',
    'Water Sports': 'Olahraga Air',
    Zoo: 'Kebun Binatang',
    Apartment: 'Apartemen',
    House: 'Rumah',
    Loft: 'Loteng',
    Boat: 'Perahu',
    'Camper/RV': 'Mobil Camper/RV',
    Condominium: 'Kondominium',
    Chalet: 'Villa kecil',
    'Bed & Breakfast': 'Kamar & Sarapan',
    Villa: 'Villa',
    Tent: 'Tenda',
    Other: 'Lainnya',
    Cabin: 'Kabin',
    Townhouse: 'Townhouse',
    Bungalow: 'Bungalow',
    Hut: 'Pondok',
    Dorm: 'Asrama',
    'Parking Space': 'Tempat Parkir',
    Plane: 'Pesawat',
    Treehouse: 'Rumah Pohon',
    Yurt: 'Tenda Yurt',
    Tipi: 'Tenda Tipi',
    Igloo: 'Rumah Iglo',
    'Earth House': 'Rumah Bawah Tanah',
    Island: 'Pulau',
    Cave: 'Gua',
    Castle: 'Kastil',
    Studio: 'Studio',
    Cottage: 'Pondok',
  },
  es: {
    'Load more': 'Cargar más',
    'Sort by': 'Ordenar por',
    'Amenities: All': 'Servicios: todos',
    'Property type: All': 'Tipo de inmueble: todos',
    'Categories: All': 'Categorías: todas',
    'Bedrooms: All': 'Dormitorios: todos',
    'Bathrooms: All': 'Baños: todos',
    'Price per night: All': 'Precio por noche: todos',
    Max: 'Máx.',
    'Starting from': 'A partir de',
    'View prices': 'Ver precios',
    'Floor surface/Property size - high to low':
      'Superficie/Tamaño del inmueble - de alto a bajo',
    'Price - high to low': 'Precio - de alto a bajo',
    'Price - low to high': 'Precio - de bajo a alto',
    'Maximum occupancy - high to low': 'Ocupación máxima - de alta a baja',
    'Count of bedrooms - high to low': 'Número de dormitorios - de alto a bajo',

    'Swimming pool': 'Piscina',
    'Accessible-height bed': 'Cama de altura accesible',
    'Accessible-height toilet': 'Inodoro de altura accesible',
    'Air conditioning': 'Aire acondicionado',
    'Babysitter recommendations': 'Recomendaciones de canguros',
    'Baby bath': 'Bañera para bebé',
    'Baby monitor': 'Monitor para bebé',
    Bathtub: 'Bañera',
    'BBQ grill': 'Parrilla para barbacoa',
    'Beach essentials': 'Artículos para playa',
    'Bed linens': 'Ropa de cama',
    Breakfast: 'Desayuno',
    'Cable TV': 'TV por cable',
    'Carbon monoxide detector': 'Detector de monóxido de carbono',
    'Changing table': 'Mesa para cambiar pañales',
    'Children’s books and toys': 'Libros y juguetes infantiles',
    'Children’s dinnerware': 'Vajilla infantil',
    'Cleaning before checkout': 'Limpieza antes de la salida',
    'Coffee maker': 'Cafetera',
    'Cooking basics': 'Cocina básica',
    Crib: 'Cuna',
    'Disabled parking spot': 'Aparcamiento para discapacitados',
    'Dishes and silverware': 'Vajilla y cubiertos',
    Dishwasher: 'Lavavajillas',
    'Dog(s)': 'Perro(s)',
    Doorman: 'Portero',
    Dryer: 'Secadora',
    'Elevator in building': 'Ascensor en el edificio',
    Essentials: 'Artículos básicos',
    'Ethernet connection': 'Conexión Ethernet',
    'EV charger': 'Cargador EV',
    'Extra pillows and blankets': 'Almohadas y mantas adicionales',
    'Fireplace guards': 'Protector de chimenea',
    'Fire extinguisher': 'Extintor',
    'Firm mattress': 'Colchón firme',
    'First aid kit': 'Botiquín de primeros auxilios',
    'Flat smooth pathway to front door':
      'Camino liso y plano hacia la puerta delantera',
    'Free parking on premises': 'Aparcamiento gratuito en las instalaciones',
    'Game console': 'Consola de juegos',
    'Garden or backyard': 'Jardín o patio trasero',
    'Grab-rails for shower and toilet': 'Barras de agarre para ducha e inodoro',
    Gym: 'Gimnasio',
    'Hair dryer': 'Secador de pelo',
    Hangers: 'Perchas',
    Heating: 'Calefacción',
    'High chair': 'Silla alta',
    'Hot tub': 'Bañera de hidromasaje',
    'Hot water': 'Agua caliente',
    'Indoor fireplace': 'Chimenea interior',
    Internet: 'Internet',
    Iron: 'Plancha',
    Kitchen: 'Cocina',
    'Laptop friendly workspace': 'Espacio de trabajo apto para portátiles',
    'Long term stays allowed': 'Se permiten estancias de larga duración',
    'Luggage dropoff allowed': 'Se permite dejar el equipaje',
    Microwave: 'Microondas',
    'Outlet covers': 'Cubiertas para enchufes',
    Oven: 'Horno',
    'Pack ’n Play/travel crib': 'Cuna de viaje o practicuna',
    'Path to entrance lit at night': 'Camino de entrada iluminado a la noche',
    'Patio or balcony': 'Patio o balcón',
    'Pets allowed': 'Se admiten mascotas',
    'Pets live on this property': 'En este inmueble se permiten mascotas',
    'Pocket wifi': 'Wifi de bolsillo',
    Pool: 'Piscina',
    'Private entrance': 'Entrada privada',
    Refrigerator: 'Frigorífico',
    'Roll-in shower with shower bench or chair':
      'Ducha accesible con banco o silla',
    'Room-darkening shades': 'Cortinas que oscurecen la habitación',
    Shampoo: 'Champú',
    'Single level home': 'Vivienda de un solo nivel',
    'Smoke detector': 'Detector de humo',
    'Smoking allowed': 'Se permite fumar',
    'Stair gates': 'Puertas de seguridad para escaleras',
    'Step-free access': 'Acceso sin escalones',
    Stove: 'Estufa',
    'Suitable for children (2-12 years)': 'Apto para niños (de 2 a 12 años)',
    'Suitable for infants (under 2 years)':
      'Apto para bebés (menores de 2 años)',
    'Table corner guards': 'Protectores de esquinas de mesa',
    'Tub with shower bench': 'Bañera con banco de ducha',
    Washer: 'Lavadora',
    'Wide clearance to bed': 'Amplio espacio hacia la cama',
    'Wide clearance to shower and toilet':
      'Amplio espacio para ducha e inodoro',
    'Wide doorway': 'Puerta amplia',
    'Wide hallway clearance': 'Pasillo amplio',
    'Window guards': 'Protectores de ventanas',
    'Wireless Internet': 'Conexión inalámbrica a Internet',
    'Grab-rails in toilet': 'Pasamanos en el aseo',
    'Shower bench': 'Banco de ducha',
    'Shower chair': 'Silla de ducha',
    'Wheelchair accessible': 'Acceso para silla de ruedas',
    'Body soap': 'Jabón corporal',
    'Cleaning products': 'Productos de limpieza',
    Conditioner: 'Acondicionador',
    'Shower gel': 'Gel de ducha',
    'Towels provided': 'Toallas incluidas',
    'Clothing storage': 'Almacenamiento de ropa',
    'Coin Laundry': 'Lavandería de monedas',
    'Dryer in common space': 'Secador en espacio común',
    'Mosquito net': 'Mosquitera',
    'Washer in common space': 'Lavadora en espacio común',
    'DVD player': 'Reproductor de DVD',
    'Foosball table': 'Futbolín',
    'Game room': 'Sala de juegos',
    Piano: 'Piano',
    'Ping pong table': 'Mesa de ping pong',
    'Pool table': 'Mesa de billar',
    'Sound system': 'Sistema de sonido',
    'Board games': 'Juegos de mesa',
    'Family/kid friendly': 'Ideal para familia/niños',
    'Portable fans': 'Ventiladores portátiles',
    'Emergency exit': 'Salida de emergencia',
    'Baking sheet': 'Bandeja para hornear',
    'Barbeque utensils': 'Utensilios de barbacoa',
    Blender: 'Licuadora',
    Coffee: 'Café',
    Cookware: 'Utensilios de cocina',
    'Dining table': 'Mesa de comedor',
    Freezer: 'Congelador',
    'Ice maker': 'Máquina de hielo',
    Kettle: 'Tetera',
    'Mini fridge': 'Mininevera',
    'Rice maker': 'Arrocera',
    Toaster: 'Tostadora',
    'Trash compactor': 'Compactador de basura',
    'Wine glasses': 'Copas de vino',
    Beach: 'Playa',
    'Beach Front': 'Frente de playa',
    'Beach View': 'Vista de playa',
    'Beach access': 'Acceso a la playa',
    'City View': 'Vistas a la ciudad',
    'Desert View': 'Vistas al desierto',
    Downtown: 'Centro de la ciudad',
    'Garden View': 'Vistas al jardín',
    'Golf course front': 'Frente al campo de golf',
    'Golf view': 'Vistas al campo de golf',
    Lake: 'Lago',
    'Lake Front': 'Frente al lago',
    'Lake access': 'Acceso al lago',
    'Laundromat nearby': 'Lavandería cercana',
    Mountain: 'Montaña',
    'Mountain view': 'Vistas a la montaña',
    'Near Ocean': 'Cerca del océano',
    'Ocean Front': 'Frente al océano',
    Resort: 'Resort',
    'Resort access': 'Acceso al resort',
    Rural: 'Rural',
    'Sea view': 'Vistas al mar',
    'Ski In': 'Entrada de esquí',
    'Ski Out': 'Salida de esquí',
    'Ski in/Ski out': 'Entrada/Salida de esquí',
    Town: 'Ciudad',
    Village: 'Pueblo',
    'Water View': 'Vistas al mar',
    Waterfront: 'Frente al mar',
    'Cleaning Disinfection': 'Limpieza de desinfección',
    Desk: 'Escritorio',
    'Enhanced cleaning practices': 'Prácticas de limpieza mejoradas',
    'High touch surfaces disinfected':
      'Superficies de alto contacto desinfectadas',
    'Bicycles available': 'Bicicletas disponibles',
    Bikes: 'Motocicletas',
    'Boat slip': 'Embarcadero',
    'Fire Pit': 'Fogata',
    Hammock: 'Hamaca',
    Kayak: 'Kayak',
    'Outdoor kitchen': 'Cocina exterior',
    'Outdoor seating (furniture)': 'Asientos exteriores (muebles)',
    River: 'Río',
    'Free parking on street': 'Aparcamiento gratuito en la calle',
    Garage: 'Garaje',
    'Paid parking': 'Parking de pago',
    'Paid parking off premises':
      'Aparcamiento de pago fuera de las instalaciones',
    'Communal pool': 'Piscina comunitaria',
    'Indoor pool': 'Piscina cubierta',
    'Outdoor pool': 'Piscina exterior',
    'Private pool': 'Piscina privada',
    'Rooftop pool': 'Piscina en la azotea',
    Elevator: 'Ascensor',
    TV: 'TV',
    'Ceiling fan': 'Ventilador de techo',
    'Pocket Wifi': 'Wifi de bolsillo',
    Safe: 'Caja fuerte',
    'Stereo system': 'Sistema estéreo',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Casinos',
    Cycling: 'Ciclismo',
    Fishing: 'Pesca',
    'Golf - Optional': 'Golf - Opcional',
    'Horseback Riding': 'Equitación',
    'Mountain Climbing': 'Escalada',
    Museums: 'Museos',
    'Rock Climbing': 'Escalada en roca',
    Shopping: 'Ir de compras',
    'Theme Parks': 'Parques temáticos',
    'Water Parks': 'Parques acuáticos',
    'Water Sports': 'Deportes acuáticos',
    Zoo: 'Zoo',
    Apartment: 'Apartamento',
    House: 'Casa',
    Loft: 'Loft',
    Boat: 'Barco',
    'Camper/RV': 'Caravana/vehículo recreativo',
    Condominium: 'Condominio',
    Chalet: 'Chalet',
    'Bed & Breakfast': 'Alojamiento y desayuno',
    Villa: 'Villa',
    Tent: 'Tienda de campaña',
    Other: 'Otros',
    Cabin: 'Cabina',
    Townhouse: 'Complejo de viviendas',
    Bungalow: 'Bungalow',
    Hut: 'Cabaña',
    Dorm: 'Dormitorio',
    'Parking Space': 'Espacio para aparcamiento',
    Plane: 'Avión',
    Treehouse: 'Casa del árbol',
    Yurt: 'Yurta',
    Tipi: 'Tipi',
    Igloo: 'Iglú',
    'Earth House': 'Casa-cueva',
    Island: 'Isla',
    Cave: 'Cueva',
    Castle: 'Castillo',
    Studio: 'Estudio',
    Cottage: 'Cabaña',
  },
  fr: {
    'Load more': 'Charger plus',
    'Sort by': 'Trier par',
    'Amenities: All': 'Commodités : toutes',
    'Property type: All': 'Type de propriété : tous',
    'Categories: All': 'Catégories : toutes',
    'Bedrooms: All': 'Chambres : toutes',
    'Bathrooms: All': 'Salles de bain : toutes',
    'Price per night: All': 'Prix par nuit : Tous',
    Max: 'Max',
    'Starting from': 'À partir de',
    'View prices': 'Voir les prix',
    'Floor surface/Property size - high to low':
      'Superficie/taille de la propriété : ordre décroissant',
    'Price - high to low': 'Prix : ordre décroissant',
    'Price - low to high': 'Prix : ordre croissant',
    'Maximum occupancy - high to low':
      'Occupation maximale : ordre décroissant',

    'Swimming pool': 'Piscine',
    'Accessible-height bed': 'Lit à hauteur accessible',
    'Accessible-height toilet': 'Toilettes à hauteur accessible',
    'Air conditioning': 'Air conditionné',
    'Babysitter recommendations': "Recommandations de gardes d'enfants",
    'Baby bath': 'Baignoire pour bébé',
    'Baby monitor': 'Babyphone',
    Bathtub: 'Baignoire',
    'BBQ grill': 'Gril de barbecue',
    'Beach essentials': 'Accessoires de plage',
    'Bed linens': 'Linge de lit',
    Breakfast: 'Petit déjeuner',
    'Cable TV': 'Télévision par câble',
    'Carbon monoxide detector': 'Détecteur de monoxyde de carbone',
    'Changing table': 'Table à langer',
    'Children’s books and toys': 'Livres et jouets pour enfants',
    'Children’s dinnerware': 'Vaisselle pour enfants',
    'Cleaning before checkout': 'Nettoyage avant le départ',
    'Coffee maker': 'Machine à café',
    'Cooking basics': 'Ustensiles essentiels de cuisine',
    Crib: 'Berceau',
    'Disabled parking spot': 'Place de parking pour handicapés',
    'Dishes and silverware': 'Vaisselle et argenterie',
    Dishwasher: 'Lave-vaisselle',
    'Dog(s)': 'Chien(s)',
    Doorman: 'Portier',
    Dryer: 'Sèche-linge',
    'Elevator in building': 'Ascenseur dans le bâtiment',
    Essentials: 'Indispensables',
    'Ethernet connection': 'Connexion Ethernet',
    'EV charger': 'Chargeur de véhicule électrique',
    'Extra pillows and blankets': 'Oreillers et couvertures supplémentaires',
    'Fireplace guards': 'Protecteurs de cheminée',
    'Fire extinguisher': 'Extincteur',
    'Firm mattress': 'Matelas ferme',
    'First aid kit': 'Trousse de premiers soins',
    'Flat smooth pathway to front door':
      "Sentier dégagé vers la porte d'entrée",
    'Free parking on premises': 'Parking gratuit sur place',
    'Game console': 'Console de jeu',
    'Garden or backyard': 'Jardin ou arrière-cour',
    'Grab-rails for shower and toilet': "Barres d'appui pour douche et WC",
    Gym: 'Salle de gym',
    'Hair dryer': 'Sèche-cheveux',
    Hangers: 'Cintres',
    Heating: 'Chauffage',
    'High chair': 'Chaise haute',
    'Hot tub': 'Baignoire',
    'Hot water': 'Eau chaude',
    'Indoor fireplace': 'Cheminée intérieure',
    Internet: 'Internet',
    Iron: 'Fer à repasser',
    Kitchen: 'Cuisine',
    'Laptop friendly workspace':
      'Espace de travail convivial pour ordinateur portable',
    'Long term stays allowed': 'Séjours à long terme autorisés',
    'Luggage dropoff allowed': 'Dépôt des bagages autorisé',
    Microwave: 'Four à micro-ondes',
    'Outlet covers': 'Cache-prises',
    Oven: 'Four',
    'Pack ’n Play/travel crib': "Pack 'n Play/berceau de voyage",
    'Path to entrance lit at night':
      "Chemin d'accès à l'entrée éclairé la nuit",
    'Patio or balcony': 'Patio ou balcon',
    'Pets allowed': 'Animaux domestiques admis',
    'Pets live on this property':
      'Des animaux de compagnie vivent sur cette propriété',
    'Pocket wifi': 'Wi-Fi de poche',
    Pool: 'Piscine',
    'Private entrance': 'Entrée privée',
    Refrigerator: 'Réfrigérateur',
    'Roll-in shower with shower bench or chair':
      "Douche à l'italienne avec banquette ou chaise de douche",
    'Room-darkening shades': 'Chambre avec stores',
    Shampoo: 'Shampooing',
    'Single level home': 'Maison de plain-pied',
    'Smoke detector': 'Détecteur de fumée',
    'Smoking allowed': 'Autorisation de fumer',
    'Stair gates': "Portes d'escalier",
    'Step-free access': 'Accès sans marches',
    Stove: 'Cuisinière',
    'Suitable for children (2-12 years)':
      'Convient aux enfants (de 2 à 12 ans)',
    'Suitable for infants (under 2 years)':
      'Convient aux nourrissons (moins de 2 ans)',
    'Table corner guards': "Protecteurs d'angle de table",
    'Tub with shower bench': 'Baignoire avec banquette de douche',
    Washer: 'Lave-linge',
    'Wide clearance to bed': 'Circulation aisée vers le lit',
    'Wide clearance to shower and toilet':
      'Circulation aisée vers la douche et les toilettes',
    'Wide doorway': 'Large ouverture de porte',
    'Wide hallway clearance': 'Circulation aisée dans le couloir',
    'Window guards': 'Protections des fenêtres',
    'Wireless Internet': 'Internet sans fil',
    'Grab-rails in toilet': "Barre d'appui dans les toilettes",
    'Shower bench': 'Banc de douche',
    'Shower chair': 'Chaise de douche',
    'Wheelchair accessible': 'Accessible en fauteuil roulant',
    'Body soap': 'Savon corporel',
    'Cleaning products': 'Produits de nettoyage',
    Conditioner: 'Après-shampooing',
    'Shower gel': 'Gel douche',
    'Towels provided': 'Serviettes fournies',
    'Clothing storage': 'Espace de rangement pour les vêtements',
    'Coin Laundry': 'Laverie automatique',
    'Dryer in common space': 'Séchoir dans un espace commun',
    'Mosquito net': 'Moustiquaire',
    'Washer in common space': 'Machine à laver dans un espace commun',
    'DVD player': 'Lecteur DVD',
    'Foosball table': 'Babyfoot',
    'Game room': 'Salle de jeux',
    Piano: 'Piano',
    'Ping pong table': 'Table de ping-pong',
    'Pool table': 'Billard',
    'Sound system': 'Chaîne Hi-Fi',
    'Board games': 'Jeux de plateau',
    'Family/kid friendly': 'Idéal pour famille/enfant',
    'Portable fans': 'Ventilateurs portables',
    'Emergency exit': 'Sortie de secours',
    'Baking sheet': 'Plaque de cuisson',
    'Barbeque utensils': 'Ustensiles de barbecue',
    Blender: 'Mixeur',
    Coffee: 'Café',
    Cookware: 'Ustensiles de cuisine',
    'Dining table': 'Table à manger',
    Freezer: 'Congélateur',
    'Ice maker': 'Machine à glaçons',
    Kettle: 'Bouilloire',
    'Mini fridge': 'Mini-frigo',
    'Rice maker': 'Cuiseur de riz',
    Toaster: 'Grille-pain',
    'Trash compactor': 'Compacteur de déchets ménagers',
    'Wine glasses': 'Verres à vin',
    Beach: 'Plage',
    'Beach Front': 'Front de mer',
    'Beach View': 'Vue sur la plage',
    'Beach access': 'Accès à la plage',
    'City View': 'Vue sur la ville',
    'Desert View': 'Vue sur le désert',
    Downtown: 'Centre-ville',
    'Garden View': 'Vue sur le jardin',
    'Golf course front': 'Face au terrain de golf',
    'Golf view': 'Vue sur le terrain de golf',
    Lake: 'Lac',
    'Lake Front': 'Bord du lac',
    'Lake access': 'Accès au lac',
    'Laundromat nearby': 'Blanchisserie à proximité',
    Mountain: 'Montagne',
    'Mountain view': 'Vue sur la montagne',
    'Near Ocean': "Près de l'océan",
    'Ocean Front': "Face à l'océan",
    Resort: 'Complexe hôtelier',
    'Resort access': 'Accès à la station',
    Rural: 'Rural',
    'Sea view': 'Vue sur la mer',
    'Ski In': 'Logement en bas des pistes',
    'Ski Out': 'Logement en haut des pistes',
    'Ski in/Ski out': 'Logement sur les pistes',
    Town: 'Petite ville',
    Village: 'Village',
    'Water View': "Vue sur un plan d'eau",
    Waterfront: "Bord de l'eau",
    'Cleaning Disinfection': 'Désinfection',
    Desk: 'Bureau',
    'Enhanced cleaning practices': 'Pratiques de nettoyage améliorées',
    'High touch surfaces disinfected':
      'Surfaces de contact élevées désinfectées',
    'Bicycles available': 'Bicyclettes disponibles',
    Bikes: 'Vélos',
    'Boat slip': 'Ponton',
    'Fire Pit': 'Foyer',
    Hammock: 'Hamac',
    Kayak: 'Kayak',
    'Outdoor kitchen': 'Cuisine extérieure',
    'Outdoor seating (furniture)': 'Siège extérieur (mobilier)',
    River: 'Rivière',
    'Free parking on street': 'Parking gratuit dans la rue',
    Garage: 'Garage',
    'Paid parking': 'Parking payant',
    'Paid parking off premises': "Parking payant à l'extérieur des lieux",
    'Communal pool': 'Piscine commune',
    'Indoor pool': 'Piscine couverte',
    'Outdoor pool': 'Piscine extérieure',
    'Private pool': 'Piscine privée',
    'Rooftop pool': 'Piscine sur le toit',
    Elevator: 'Ascenseur',
    TV: 'Téléviseur',
    'Ceiling fan': 'Ventilateur de plafond',
    'Pocket Wifi': 'Wi-Fi de poche',
    Safe: 'Coffre-fort',
    'Stereo system': 'Système stéréo',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: 'Casinos',
    Cycling: 'Cyclisme',
    Fishing: 'Pêche',
    'Golf - Optional': 'Golf - en option',
    'Horseback Riding': 'Équitation',
    'Mountain Climbing': 'Alpinisme',
    Museums: 'Musées',
    'Rock Climbing': 'Escalade',
    Shopping: 'Shopping',
    'Theme Parks': "Parcs d'attractions",
    'Water Parks': 'Parcs aquatiques',
    'Water Sports': 'Sports nautiques',
    Zoo: 'Zoo',
    Apartment: 'Appartement',
    House: 'Maison',
    Loft: 'Loft',
    Boat: 'Bateau',
    'Camper/RV': 'Camping-car/véhicule récréatif',
    Condominium: 'Copropriété',
    Chalet: 'Chalet',
    'Bed & Breakfast': "Chambres d'hôtes",
    Villa: 'Villa',
    Tent: 'Tente',
    Other: 'Autre',
    Cabin: 'Cabane',
    Townhouse: 'Maison de ville',
    Bungalow: 'Bungalow',
    Hut: 'Hutte',
    Dorm: 'Dortoir',
    'Parking Space': 'Place de parking',
    Plane: 'Avion',
    Treehouse: 'Maison dans les arbres',
    Yurt: 'Yourte',
    Tipi: 'Tipi',
    Igloo: 'Igloo',
    'Earth House': 'Maison organique',
    Island: 'Île',
    Cave: 'Grotte',
    Castle: 'Château',
    Studio: 'Studio',
    Cottage: 'Chalet',
  },
  nl: {
    'Load more': 'Kä tin gɔw: ŋɔak diaal',
    'Sort by': 'Sorteren op',
    'Amenities: All': 'Voorzieningen: alles',
    'Property type: All': 'Type woning: alles',
    'Categories: All': 'Categorieën: alles',
    'Bedrooms: All': 'Slaapkamers: alles',
    'Bathrooms: All': 'Badkamers: alles',
    'Price per night: All': 'Prijs per nacht: alles',
    Max: 'Max.',
    'Starting from': 'Vanaf',
    'View prices': 'Prijzen weergeven',
    'Floor surface/Property size - high to low':
      'Vloeroppervlak/grootte van woning - van hoog naar laag',
    'Price - high to low': 'Prijs - van hoog naar laag',
    'Price - low to high': 'Prijs - van laag naar hoog',
    'Maximum occupancy - high to low':
      'Maximale bezetting - van hoog naar laag',
    'Count of bedrooms - high to low':
      'Aantal slaapkamers - van hoog naar laag',

    'Swimming pool': 'Zwembad',
    'Accessible-height bed': 'Bed op een toegankelijke hoogte',
    'Accessible-height toilet': 'Toilet op een toegankelijke hoogte',
    'Air conditioning': 'Airconditioning',
    'Babysitter recommendations': 'Aanbevelingen voor babysitters',
    'Baby bath': 'Babybadje',
    'Baby monitor': 'Babyfoon',
    Bathtub: 'Bad',
    'BBQ grill': 'Barbecuegril',
    'Beach essentials': 'Strandbenodigdheden',
    'Bed linens': 'Beddengoed',
    Breakfast: 'Ontbijt',
    'Cable TV': 'Kabel-TV',
    'Carbon monoxide detector': 'Koolmonoxidedetector',
    'Changing table': 'Luiertafel',
    'Children’s books and toys': 'Kinderboeken en speelgoed',
    'Children’s dinnerware': 'Servies voor kinderen',
    'Cleaning before checkout': 'Schoonmaken vóór uitchecken',
    'Coffee maker': 'Koffiezetapparaat',
    'Cooking basics': 'Standaard kookbenodigdheden',
    Crib: 'Wieg',
    'Disabled parking spot': 'Invalidenparkeerplaats',
    'Dishes and silverware': 'Borden en bestek',
    Dishwasher: 'Vaatwasser',
    'Dog(s)': 'Hond(en)',
    Doorman: 'Portier',
    Dryer: 'Droger',
    'Elevator in building': 'Lift in gebouw',
    Essentials: 'Basisbenodigdheden',
    'Ethernet connection': 'Ethernetverbinding',
    'EV charger': 'EV-lader',
    'Extra pillows and blankets': 'Extra kussens en dekens',
    'Fireplace guards': 'Haardschermen',
    'Fire extinguisher': 'Brandblusser',
    'Firm mattress': 'Hard matras',
    'First aid kit': 'EHBO-koffer',
    'Flat smooth pathway to front door': 'Vlak en effen pad naar de voordeur',
    'Free parking on premises': 'Gratis parkeren op het terrein',
    'Game console': 'Gameconsole',
    'Garden or backyard': 'Tuin of achtertuin',
    'Grab-rails for shower and toilet': 'Handgrepen voor douche en toilet',
    Gym: 'Fitnessruimte',
    'Hair dryer': 'Föhn',
    Hangers: 'Kleerhangers',
    Heating: 'Verwarming',
    'High chair': 'Kinderstoel',
    'Hot tub': 'Jacuzzi',
    'Hot water': 'Warm water',
    'Indoor fireplace': 'Open haard',
    Internet: 'Internet',
    Iron: 'Strijkijzer',
    Kitchen: 'Keuken',
    'Laptop friendly workspace': 'Laptopvriendelijke werkomgeving',
    'Long term stays allowed': 'Langdurig verblijf toegestaan',
    'Luggage dropoff allowed': 'Bagage-afgifte toegestaan',
    Microwave: 'Magnetron',
    'Outlet covers': 'Stopcontacten met afdekplaat',
    Oven: 'Oven',
    'Pack ’n Play/travel crib': 'Pack ’n Play/reiswiegje',
    'Path to entrance lit at night': "Pad naar ingang 's nachts verlicht",
    'Patio or balcony': 'Terras of balkon',
    'Pets allowed': 'Huisdieren toegestaan',
    'Pets live on this property': 'In de woning zijn huisdieren aanwezig',
    'Pocket wifi': 'Pocket-wifi',
    Pool: 'Zwembad',
    'Private entrance': 'Eigen ingang',
    Refrigerator: 'Koelkast',
    'Roll-in shower with shower bench or chair':
      'Inrijdbare douche met douchebank of -stoel',
    'Room-darkening shades': 'Verduisteringsgordijnen',
    Shampoo: 'Shampoo',
    'Single level home': 'Gelijkvloerse woning',
    'Smoke detector': 'Rookmelder',
    'Smoking allowed': 'Roken toegestaan',
    'Stair gates': 'Traphekjes',
    'Step-free access': 'Toegang zonder trap',
    Stove: 'Fornuis',
    'Suitable for children (2-12 years)': 'Geschikt voor kinderen (2-12 jaar)',
    'Suitable for infants (under 2 years)':
      'Geschikt voor kleine kinderen (jonger dan 2 jaar)',
    'Table corner guards': 'Tafelhoekbeschermers',
    'Tub with shower bench': 'Bad met douchebank',
    Washer: 'Wasmachine',
    'Wide clearance to bed': 'Ruime doorgang naar het bed',
    'Wide clearance to shower and toilet':
      'Ruime doorgang naar douche en toilet',
    'Wide doorway': 'Ruime deuropening',
    'Wide hallway clearance': 'Ruime doorgang naar hal',
    'Window guards': 'Raambeveiligers',
    'Wireless Internet': 'Draadloos internet',
    'Grab-rails in toilet': 'Handrails op toilet',
    'Shower bench': 'Douchebank',
    'Shower chair': 'Douchestoel',
    'Wheelchair accessible': 'Toegankelijk voor rolstoelen',
    'Body soap': 'Zeep',
    'Cleaning products': 'Schoonmaakproducten',
    Conditioner: 'Conditioner',
    'Shower gel': 'Douchegel',
    'Towels provided': 'Handdoeken',
    'Clothing storage': 'Bergruimte voor kleding',
    'Coin Laundry': 'Wasmachine met munten',
    'Dryer in common space': 'Droger in gemeenschappelijke ruimte',
    'Mosquito net': 'Klamboe',
    'Washer in common space': 'Wasmachine in gemeenschappelijke ruimte',
    'DVD player': 'Dvd-speler',
    'Foosball table': 'Tafelvoetbal',
    'Game room': 'Spelletjesruimte',
    Piano: 'Piano',
    'Ping pong table': 'Pingpongtafel',
    'Pool table': 'Biljart',
    'Sound system': 'Muziekinstallatie',
    'Board games': 'Bordspellen',
    'Family/kid friendly': 'Gezins-/kindvriendelijk',
    'Portable fans': 'Draagbare ventilatoren',
    'Emergency exit': 'Nooduitgang',
    'Baking sheet': 'Bakplaat',
    'Barbeque utensils': 'Barbecuegerei',
    Blender: 'Blender',
    Coffee: 'Koffie',
    Cookware: 'Kookgerei',
    'Dining table': 'Eettafel',
    Freezer: 'Vriezer',
    'Ice maker': 'IJsmaker',
    Kettle: 'Fluitketel',
    'Mini fridge': 'Minibar',
    'Rice maker': 'Rijstkoker',
    Toaster: 'Broodrooster',
    'Trash compactor': 'Afvalcompressor',
    'Wine glasses': 'Wijnglazen',
    Beach: 'Strand',
    'Beach Front': 'Aan het strand',
    'Beach View': 'Uitzicht op strand',
    'Beach access': 'Toegang tot strand',
    'City View': 'Uitzicht op stad',
    'Desert View': 'Uitzicht op woestijn',
    Downtown: 'In het centrum',
    'Garden View': 'Uitzicht op tuin',
    'Golf course front': 'Aan een golfbaan',
    'Golf view': 'Uitzicht op golfbaan',
    Lake: 'Meer',
    'Lake Front': 'Aan het meer',
    'Lake access': 'Toegang tot meer',
    'Laundromat nearby': 'Wasserette in de buurt',
    Mountain: 'Berg',
    'Mountain view': 'Uitzicht op bergen',
    'Near Ocean': 'Bij de oceaan',
    'Ocean Front': 'Aan de oceaan',
    Resort: 'Resort',
    'Resort access': 'Toegang tot resort',
    Rural: 'Landelijk',
    'Sea view': 'Uitzicht op zee',
    'Ski In': 'Ski-in',
    'Ski Out': 'Ski-out',
    'Ski in/Ski out': 'Ski-in/Ski-out',
    Town: 'Stad',
    Village: 'Dorp',
    'Water View': 'Uitzicht op water',
    Waterfront: 'Aan het water',
    'Cleaning Disinfection': 'Schoonmaak met desinfectie',
    Desk: 'Bureau',
    'Enhanced cleaning practices': 'Grondige reiniging',
    'High touch surfaces disinfected':
      'Desinfectie van vaak aangeraakte oppervlakken',
    'Bicycles available': 'Fietsen aanwezig',
    Bikes: 'Motorfietsen',
    'Boat slip': 'Aanlegplaats',
    'Fire Pit': 'Vuurplaats',
    Hammock: 'Hangmat',
    Kayak: 'Kayak',
    'Outdoor kitchen': 'Buitenkeuken',
    'Outdoor seating (furniture)': 'Tuinstoelen (meubels)',
    River: 'Rivier',
    'Free parking on street': 'Gratis parkeren op straat',
    Garage: 'Garage',
    'Paid parking': 'Betaald parkeren',
    'Paid parking off premises': 'Betaald parkeren buiten de accommodatie',
    'Communal pool': 'Gemeenschappelijk zwembad',
    'Indoor pool': 'Binnenbad',
    'Outdoor pool': 'Buitenbad',
    'Private pool': 'Eigen zwembad',
    'Rooftop pool': 'Zwembad op dakterras',
    Elevator: 'Lift',
    TV: 'TV',
    'Ceiling fan': 'Plafondventilator',
    'Pocket Wifi': 'Pocket-wifi',
    Safe: 'Kluis',
    'Stereo system': 'Stereo-installatie',
    Spa: 'Spa',
    Sauna: 'Sauna',
    Casinos: "Casino's",
    Cycling: 'Fietsen',
    Fishing: 'Vissen',
    'Golf - Optional': 'Golfen - optioneel',
    'Horseback Riding': 'Paardrijden',
    'Mountain Climbing': 'Bergbeklimmen',
    Museums: 'Musea',
    'Rock Climbing': 'Rotsklimmen',
    Shopping: 'Winkelen',
    'Theme Parks': 'Themaparken',
    'Water Parks': 'Waterparken',
    'Water Sports': 'Watersporten',
    Zoo: 'Dierentuin',
    Apartment: 'Appartement',
    House: 'Huis',
    Loft: 'Loft',
    Boat: 'Boot',
    'Camper/RV': 'Camper/RV',
    Condominium: 'Condominium',
    Chalet: 'Chalet',
    'Bed & Breakfast': 'Logies en ontbijt',
    Villa: 'Villa',
    Tent: 'Tent',
    Other: 'Overig',
    Cabin: 'Blokhut',
    Townhouse: 'Rijtjeshuis',
    Bungalow: 'Bungalow',
    Hut: 'Hut',
    Dorm: 'Slaapzaal',
    'Parking Space': 'Parkeerplaats',
    Plane: 'Plattegrond',
    Treehouse: 'Boomhut',
    Yurt: 'Joert',
    Tipi: 'Tipi',
    Igloo: 'Iglo',
    'Earth House': 'Lemen woning',
    Island: 'Eiland',
    Cave: 'Grot',
    Castle: 'Kasteel',
    Studio: 'Studio',
    Cottage: 'Cottage',
  },
};

export default translations;

import { translations } from './translations';

class Translations {
  private static instance: Translations;

  private translations: Record<string, string>;

  private constructor(lang: string) {
    // eslint-disable-next-line
    this.translations = translations.hasOwnProperty(lang)
      ? (translations as Record<string, Record<string, string>>)[lang]
      : translations.en;
    return this;
  }

  public static getInstance(lang: string): Translations {
    if (!Translations.instance) {
      Translations.instance = new Translations(lang);
    }
    return Translations.instance;
  }

  public getTranslations(key: string): string {
    return this.translations[key];
  }
}

export default Translations;

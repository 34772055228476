import React, { createContext, useEffect } from 'react';

import { IAppData, IFilterSettings, IListing, IWidgetSettings } from '../common/types';
import useGetWidgetSettings from '../hooks/useGetWidgetSettings';
import { IWidgetParams } from '../api/widgetSettings/getWidgetSettings';
import { DUDA_IFRAME_SETTINGS_CLOSE_BUTTON } from '../common/constants';
import useGetListings from '../hooks/useGetListings';
import useGetFilterSettings from '../hooks/useGetFilterSettings';
import { translations } from '../translations/translations';

interface IDioSettings {
  website_name: string;
  website_url: string;
  guest_origin: string;
  is_mobile?: string;
  account_ID?: string;
}

interface IWidgetSettingsData {
  widgetSettings: IWidgetSettings;
  listings?: IListing[];
  filterSettings: {
    data: IFilterSettings | null;
    isLoading: boolean;
    isError: boolean;
    error: any;
  };
  dioSettings: IDioSettings;
  appData: IAppData;
  isLoading: boolean;
  isListingLoading: boolean;
  isError: boolean;
  error: any;
}

const initialData: IWidgetSettingsData = {
  widgetSettings: {
    url: '',
    currency: 'USD',
    options: {
      locations: [],
      amenities: [],
      propertyTypes: [],
      sortSettings: [],
      tags: [],
      sortBy: [],
    },
    searchSettings: {
      propertyType: '',
      tags: [],
      priceRange: [0, 0],
      sortBy: '',
    },
    filterSettings: {
      propertyType: '',
      propertyTypes: [],
      tags: [],
      priceRange: [0, 0],
      isShowSortBy: false,
      isShowFilters: false,
      isShowPriceRange: false,
      url: '',
    },
  },
  filterSettings: {
    data: null,
    isLoading: false,
    isError: false,
    error: null,
  },
  dioSettings: {
    website_name: '',
    website_url: '',
    guest_origin: '',
    is_mobile: '',
    account_ID: '',
  },
  listings: [],
  appData: {
    siteId: '',
    elementId: '',
    widgetId: '',
    locale: '',
  },
  isLoading: false,
  isListingLoading: false,
  isError: false,
  error: null,
};

const WebsiteSettingsContext = createContext(initialData);

const WebsiteSettingsContextProvider = ({ children, appData }: {
  children: React.ReactNode | React.ReactNode[],
  appData: IAppData
}) => {
  const requestParams = {
    websiteId: appData?.siteId,
    elementId: Number(appData?.elementId),
    widgetId: appData?.widgetId,
    lang: appData?.locale,
  } as IWidgetParams;

  const {
    data: widgetSettings,
    isLoading,
    isError,
    error,
    refetch: refetchWidgetSettings,
  } = useGetWidgetSettings(requestParams);
  const { refetch: refetchListings, isLoading: isListingLoading, data: listings } = useGetListings(requestParams);
  const {
    data: filterSettings,
    isLoading: isFilterSettingsLoading,
    isError: isFilterSettingsError,
    error: filterSettingsError,
    refetch: refetchFilterSettings,
  } = useGetFilterSettings(requestParams);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (
        e.origin.includes('.guesty.com') &&
        e?.data?.name === 'guestyLandingWidgetReload'
      ) {
        refetchFilterSettings()
          .then(() => refetchWidgetSettings())
          .then(() => refetchListings())
          // close modal settings window
          // @ts-ignore
          .then(() => (window?.parent?.document?.querySelector(DUDA_IFRAME_SETTINGS_CLOSE_BUTTON) as HTMLElement)?.click());
      }
    };

    window.addEventListener('message', handleMessage, true);

    return () => {
      window.removeEventListener('message', handleMessage, true);
    };
  }, [refetchWidgetSettings, refetchListings, refetchFilterSettings]);

  return (
    <WebsiteSettingsContext.Provider
      value={{
        widgetSettings: {
          ...initialData.widgetSettings,
          ...widgetSettings,
        },
        filterSettings: {
          isError: isFilterSettingsError,
          error: filterSettingsError,
          data: {
            ...filterSettings,
            amenities: filterSettings?.amenities?.map((amenity) =>  (translations as any)[appData.locale][amenity]) || [],
            propertyTypes: filterSettings?.propertyTypes?.map((propertyType) => (translations as any)[appData.locale][propertyType]) || [],
          },
          isLoading: isFilterSettingsLoading,
        },
        dioSettings: {
          website_name: appData?.siteId,
          website_url: filterSettings?.url,
          guest_origin: window.location.origin,
          is_mobile: appData.device,
          account_ID: appData.accountId,
        },
        listings,
        appData,
        isLoading,
        isListingLoading,
        isError,
        error,
      }}
    >
      {children}
    </WebsiteSettingsContext.Provider>
  );
};

export { WebsiteSettingsContext };
export default WebsiteSettingsContextProvider;
import Resource from '@guestyci/agni';

const QUERY_URLS = {
  WIDGET_SETTINGS: '/settings',
  LISTINGS: '/listings',
  FILTER_SETTING: '/filter-settings',
};

const QUERY_IDS = {
  WIDGET_SETTINGS: 'WIDGET_SETTINGS',
  LISTINGS: 'LISTINGS',
  FILTER_SETTING: 'FILTER_SETTING',
};

const { api, env } = Resource.create({
  domain: 'booking-engine-widget/widget/landing-widget',
  env: ((cluster) => {
    if (cluster === 'staging') return 'staging7';
    return cluster;
  })(process.env.REACT_APP_ENV_CLUSTER),
  development: 'localhost',
});
export { api, env, QUERY_IDS, QUERY_URLS };

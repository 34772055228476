import { api, QUERY_URLS } from '../index';
import { IListing } from '../../common/types';

const { LISTINGS } = QUERY_URLS;

export interface IWidgetParams {
  widgetId: string;
  websiteId: string;
  elementId: number;
  lang: string;
}

export const getListings = async ({
  widgetId,
  websiteId,
  elementId,
  lang,
}: IWidgetParams): Promise<IListing[]> => {
  const { data } = await api.get(LISTINGS, {
    params: {
      widgetId,
      websiteId,
      elementId,
      lang,
    },
  });

  return data;
};

export default getListings;

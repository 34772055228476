import React, { FC, useContext } from 'react';

import { Col, Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import CurrencySymbols from '@guestyci/foundation/constants/currencySymbols';

import { ListingImage } from './ListingImage';
import TTheme, { IListing } from '../../common/types';
import { ListingRate } from '../ListingRate';
import { Amenities } from './Amenities';
import { WebsiteSettingsContext } from '../../context/SettingsProvider';
import { Price } from './Price';

type TCurrencySymbols = typeof CurrencySymbols;

const useStyles = createStyles((theme: TTheme) => ({
  root: {
    flexDirection: 'column',
    flexBasis: '30%',
    maxWidth: 320,
    gap: theme.spacer(2),
    justifyContent: 'flex-start',
    lineHeight: `20px`,
  },
  button: {
    position: 'relative',
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
  },
  title: {
    color: theme.palette.label,
    marginTop: theme.spacer(3),
    marginBottom: theme.spacer(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  description: {
    color: theme.palette.disabledText,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 300,
    marginBottom: 3,
    minHeight: 20,
  },
  price: {
    width: 'auto',
    marginTop: 3,

    '& > div': {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },

    '& .startingFrom': {
      color: theme.palette.text.secondaryText,
      marginRight: theme.spacer(1),
    },

    '& .price': {
      fontSize: '16px',
    }
  },
  descriptionContainer: {
    flexDirection: 'column',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listingRate: {
    marginBottom: theme.spacer(2),
  },
}));

interface IListingGridProps {
  listing: IListing,
  onClick: () => void;
}

export const ListingGrid: FC<IListingGridProps> = ({ listing, onClick }) => {
  const classes = useStyles();
  const { appData } = useContext(WebsiteSettingsContext);
  const { title, pictures = [], publicDescription: { summary: description } = {}, prices, amenities = [] } = listing;
  // @ts-ignore
  const currencySymbol: string = (CurrencySymbols as TCurrencySymbols)[prices?.currency]?.symbol;

  return (
    <button className={classes.button} onClick={onClick} type="button" aria-label="Listings">
      <Amenities amenities={amenities} />
      <Row justify="between" className={classes.root}>
        <Row>
          <ListingImage isGridView name={title} pictures={pictures} />
        </Row>
        <div className="clearfix">
          <Row className={classes.descriptionContainer}>
            <Col>
              <Col className={classes.headerContainer}>
                <TextField align="left" semiBold variant="h4" className={classes.title}>{title}</TextField>
                <ListingRate className={classes.listingRate} listing={listing} />
              </Col>
              <TextField align="left" variant="h4" className={classes.description}>{description}</TextField>
            </Col>
            <Price className={classes.price} currencySymbol={currencySymbol} prices={prices} locale={appData.locale} />
          </Row>
        </div>
      </Row>
    </button>
  );
};

export default ListingGrid;

import { useQuery } from '@tanstack/react-query';

import { QUERY_IDS } from '../api';
import { IWidgetParams } from '../api/widgetSettings/getWidgetSettings';
import { getListings } from '../api/widgetSettings/getListings';

const { LISTINGS } = QUERY_IDS;

const useGetListings = ({
  widgetId,
  websiteId,
  elementId,
  lang,
}: IWidgetParams) => {
  const { data, error, isLoading, isFetching, isSuccess, isError, refetch } =
    useQuery({
      queryKey: [LISTINGS],
      queryFn: () => getListings({ widgetId, websiteId, elementId, lang }),
      retry: false,
      refetchOnWindowFocus: false,
    });

  return {
    data,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  };
};

export default useGetListings;

import { api, QUERY_URLS } from '../index';

const { FILTER_SETTING } = QUERY_URLS;

export interface IWidgetParams {
  widgetId: string;
  websiteId: string;
  elementId: number;
  lang: string;
}

export const getFilterSettings = async ({
  widgetId,
  websiteId,
  elementId,
  lang,
}: IWidgetParams) => {
  const { data } = await api.get(FILTER_SETTING, {
    params: {
      widgetId,
      websiteId,
      elementId,
      lang,
    },
  });

  return data;
};

export default getFilterSettings;

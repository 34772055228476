import Translations from '../translations';

export const allAmenities = [
  { icon: 'guard', text: 'Window guards' },
  { icon: 'guard', text: 'Table corner guards' },
  { icon: 'guard', text: 'Fireplace guards' },
  { icon: 'guard', text: 'Outlet covers' },
  { icon: 'parking', text: 'Disabled parking spot' },
  { icon: 'accessibility', text: 'Wide hallway clearance' },
  { icon: 'accessibility', text: 'Step-free access' },
  { icon: 'accessibility', text: 'Wide doorway' },
  { icon: 'accessibility', text: 'Flat smooth pathway to front door' },
  { icon: 'accessibility', text: 'Path to entrance lit at night' },
  { icon: 'accessibility', text: 'Grab-rails for shower and toilet' },
  { icon: 'accessibility', text: 'Roll-in shower with shower bench or chair' },
  { icon: 'accessibility', text: 'Wide clearance to shower and toilet' },
  { icon: 'table', text: 'Changing table' },
  { icon: 'chair', text: 'High chair' },
  { icon: 'shades', text: 'Room darkening shades' },
  { icon: 'console', text: 'Game console' },
  { icon: 'microwave', text: 'Microwave' },
  { icon: 'coffee', text: 'Coffee maker' },
  { icon: 'refrigerator', text: 'Refrigerator' },
  { icon: 'dishwasher', text: 'Dishwasher' },
  { icon: 'dishes', text: 'Dishes and silverware' },
  { icon: 'kitchen', text: 'Cookware' },
  { icon: 'oven', text: 'Oven' },
  { icon: 'stove', text: 'Stove' },
  { icon: 'bbq', text: 'BBQ grill' },
  { icon: 'patio', text: 'Patio or balcony' },
  { icon: 'garden', text: 'Garden or backyard' },
  { icon: 'beach', text: 'Beach essentials' },
  { icon: 'wheelchair', text: 'Accessible-height bed' },
  { icon: 'wheelchair', text: 'Accessible-height toilet' },
  { icon: 'parking', text: 'Disabled parking spot' },
  { icon: 'accessibility', text: 'Grab-rails for shower and toilet' },
  { icon: 'accessibility', text: 'Grab-rails in toilet' },
  { icon: 'accessibility', text: 'Path to entrance lit at night' },
  { icon: 'accessibility', text: 'Roll-in shower with shower bench or chair' },
  { icon: 'accessibility', text: 'Shower bench' },
  { icon: 'accessibility', text: 'Shower chair' },
  { icon: 'accessibility', text: 'Single level home' },
  { icon: 'accessibility', text: 'Step-free access' },
  { icon: 'bath', text: 'Tub with shower bench' },
  { icon: 'wheelchair', text: 'Wheelchair accessible' },
  { icon: 'bed', text: 'Wide clearance to bed' },
  { icon: 'accessibility', text: 'Wide clearance to shower and toilet' },
  { icon: 'accessibility', text: 'Wide doorway' },
  { icon: 'accessibility', text: 'Wide hallway clearance' },
  { icon: 'bath', text: 'Body soap' },
  { icon: 'bath', text: 'Cleaning products' },
  { icon: 'bath', text: 'Conditioner' },
  { icon: 'bath', text: 'Hot water' },
  { icon: 'shampoo', text: 'Shampoo' },
  { icon: 'bath', text: 'Shower gel' },
  { icon: 'bath', text: 'Towels provided' },
  { icon: 'hangers', text: 'Clothing storage' },
  { icon: 'washer', text: 'Coin Laundry' },
  { icon: 'dryer', text: 'Dryer in common space' },
  { icon: 'bed', text: 'Mosquito net' },
  { icon: 'washer', text: 'Washer in common space' },
  { icon: 'tv', text: 'DVD player' },
  { icon: 'family_friendly', text: 'Foosball table' },
  { icon: 'family_friendly', text: 'Game room' },
  { icon: 'family_friendly', text: 'Piano' },
  { icon: 'family_friendly', text: 'Ping pong table' },
  { icon: 'family_friendly', text: 'Pool table' },
  { icon: 'family_friendly', text: 'Sound system' },
  { icon: 'baby', text: 'Baby bath' },
  { icon: 'baby', text: 'Baby monitor' },
  { icon: 'baby', text: 'Babysitter recommendations' },
  { icon: 'bath', text: 'Bathtub' },
  { icon: 'family_friendly', text: 'Board games' },
  { icon: 'table', text: 'Changing table' },
  { icon: 'family_friendly', text: 'Children’s books and toys' },
  { icon: 'family_friendly', text: 'Children’s dinnerware' },
  { icon: 'baby', text: 'Crib' },
  { icon: 'family_friendly', text: 'Family/kid friendly' },
  { icon: 'guard', text: 'Fireplace guards' },
  { icon: 'console', text: 'Game console' },
  { icon: 'chair', text: 'High chair' },
  { icon: 'guard', text: 'Outlet covers' },
  { icon: 'baby', text: 'Pack ’n Play/travel crib' },
  { icon: 'family_friendly', text: 'Room-darkening shades' },
  { icon: 'guard', text: 'Stair gates' },
  { icon: 'guard', text: 'Table corner guards' },
  { icon: 'guard', text: 'Window guards' },
  { icon: 'air_condition', text: 'Portable fans' },
  { icon: 'CM_detector', text: 'Carbon monoxide detector' },
  { icon: 'guard', text: 'Emergency exit' },
  { icon: 'fire_extinguisher', text: 'Fire extinguisher' },
  { icon: 'first_aid', text: 'First aid kit' },
  { icon: 'smoke_detector', text: 'Smoke detector' },
  { icon: 'kitchen', text: 'Baking sheet' },
  { icon: 'bbq', text: 'Barbeque utensils' },
  { icon: 'kitchen', text: 'Blender' },
  { icon: 'breakfast', text: 'Breakfast' },
  { icon: 'coffee', text: 'Coffee' },
  { icon: 'coffee', text: 'Coffee maker' },
  { icon: 'kitchen', text: 'Cookware' },
  { icon: 'table', text: 'Dining table' },
  { icon: 'dishes', text: 'Dishes and silverware' },
  { icon: 'dishwasher', text: 'Dishwasher' },
  { icon: 'refrigerator', text: 'Freezer' },
  { icon: 'refrigerator', text: 'Ice maker' },
  { icon: 'kitchen', text: 'Kettle' },
  { icon: 'microwave', text: 'Microwave' },
  { icon: 'refrigerator', text: 'Mini fridge' },
  { icon: 'oven', text: 'Oven' },
  { icon: 'refrigerator', text: 'Refrigerator' },
  { icon: 'kitchen', text: 'Rice maker' },
  { icon: 'stove', text: 'Stove' },
  { icon: 'kitchen', text: 'Toaster' },
  { icon: 'kitchen', text: 'Trash compactor' },
  { icon: 'dishes', text: 'Wine glasses' },
  { icon: 'beach', text: 'Beach' },
  { icon: 'beach', text: 'Beach Front' },
  { icon: 'beach', text: 'Beach View' },
  { icon: 'beach', text: 'Beach access' },
  { icon: 'garden', text: 'City View' },
  { icon: 'garden', text: 'Desert View' },
  { icon: 'garden', text: 'Downtown' },
  { icon: 'garden', text: 'Garden View' },
  { icon: 'garden', text: 'Golf course front' },
  { icon: 'garden', text: 'Golf view' },
  { icon: 'beach', text: 'Lake' },
  { icon: 'beach', text: 'Lake Front' },
  { icon: 'beach', text: 'Lake access' },
  { icon: 'washer', text: 'Laundromat nearby' },
  { icon: 'garden', text: 'Mountain' },
  { icon: 'garden', text: 'Mountain view' },
  { icon: 'beach', text: 'Near Ocean' },
  { icon: 'beach', text: 'Ocean Front' },
  { icon: 'beach', text: 'Resort' },
  { icon: 'beach', text: 'Resort access' },
  { icon: 'garden', text: 'Rural' },
  { icon: 'beach', text: 'Sea view' },
  { icon: 'events', text: 'Ski In' },
  { icon: 'events', text: 'Ski Out' },
  { icon: 'events', text: 'Ski in/Ski out' },
  { icon: 'events', text: 'Town' },
  { icon: 'garden', text: 'Village' },
  { icon: 'beach', text: 'Water View' },
  { icon: 'beach', text: 'Waterfront' },
  { icon: 'logistics', text: 'Cleaning Disinfection' },
  { icon: 'cleaning', text: 'Cleaning before checkout' },
  { icon: 'table', text: 'Desk' },
  { icon: 'cleaning', text: 'Enhanced cleaning practices' },
  { icon: 'cleaning', text: 'High touch surfaces disinfected' },
  { icon: 'laptop', text: 'Laptop friendly workspace' },
  { icon: 'guard', text: 'Long term stays allowed' },
  { icon: 'lockbox', text: 'Luggage dropoff allowed' },
  { icon: 'bbq', text: 'BBQ grill' },
  { icon: 'beach', text: 'Beach essentials' },
  { icon: 'gym', text: 'Bicycles available' },
  { icon: 'gym', text: 'Bikes' },
  { icon: 'beach', text: 'Boat slip' },
  { icon: 'doorman', text: 'Doorman' },
  { icon: 'fireplace', text: 'Fire Pit' },
  { icon: 'garden', text: 'Garden or backyard' },
  { icon: 'garden', text: 'Hammock' },
  { icon: 'beach', text: 'Kayak' },
  { icon: 'kitchen', text: 'Outdoor kitchen' },
  { icon: 'garden', text: 'Outdoor seating (furniture)' },
  { icon: 'garden', text: 'River' },
  { icon: 'parking', text: 'Free parking on premises' },
  { icon: 'parking', text: 'Free parking on street' },
  { icon: 'parking', text: 'Garage' },
  { icon: 'parking', text: 'Paid parking' },
  { icon: 'parking', text: 'Paid parking off premises' },
  { icon: 'pool', text: 'Communal pool' },
  { icon: 'pool', text: 'Indoor pool' },
  { icon: 'pool', text: 'Outdoor pool' },
  { icon: 'pool', text: 'Private pool' },
  { icon: 'pool', text: 'Rooftop pool' },
  { icon: 'pool', text: 'Swimming pool' },
  { icon: 'air_condition', text: 'Air conditioning' },
  { icon: 'bed', text: 'Bed linens' },
  { icon: 'tv', text: 'Cable TV' },
  { icon: 'dryer', text: 'Dryer' },
  { icon: 'elevator', text: 'Elevator' },
  { icon: 'essentials', text: 'Essentials' },
  { icon: 'hair_dryer', text: 'Hair dryer' },
  { icon: 'hangers', text: 'Hangers' },
  { icon: 'heating', text: 'Heating' },
  { icon: 'fireplace', text: 'Indoor fireplace' },
  { icon: 'internet', text: 'Internet' },
  { icon: 'iron', text: 'Iron' },
  { icon: 'kitchen', text: 'Kitchen' },
  { icon: 'patio', text: 'Patio or balcony' },
  { icon: 'tv', text: 'TV' },
  { icon: 'washer', text: 'Washer' },
  { icon: 'wifi', text: 'Wireless Internet' },
  { icon: 'air_condition', text: 'Ceiling fan' },
  { icon: 'ev', text: 'EV charger' },
  { icon: 'bed', text: 'Extra pillows and blankets' },
  { icon: 'wifi', text: 'Pocket Wifi' },
  { icon: 'private_entrance', text: 'Private entrance' },
  { icon: 'guard', text: 'Safe' },
  { icon: 'events', text: 'Stereo system' },
  { icon: 'gym', text: 'Gym' },
  { icon: 'hot_tub', text: 'Hot tub' },
  { icon: 'bath', text: 'Spa' },
  { icon: 'bath', text: 'Sauna' },
  { icon: 'events', text: 'Casinos' },
  { icon: 'events', text: 'Cycling' },
  { icon: 'events', text: 'Fishing' },
  { icon: 'events', text: 'Golf - Optional' },
  { icon: 'events', text: 'Horseback Riding' },
  { icon: 'events', text: 'Mountain Climbing' },
  { icon: 'events', text: 'Museums' },
  { icon: 'events', text: 'Rock Climbing' },
  { icon: 'events', text: 'Shopping' },
  { icon: 'events', text: 'Theme Parks' },
  { icon: 'events', text: 'Water Parks' },
  { icon: 'events', text: 'Water Sports' },
  { icon: 'events', text: 'Zoo' },
];

export const supportedLanguages = [
  'en',
  'de',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'pt',
  'el',
  'pl',
  'ro',
  'in',
  'zh',
  'nl',
];

export const DIO_EVENTS = {
  CLICK_SEARCH_RESULT_PAGE_SORT_BY_OPTION:
    'click_search_result_page_sort_by_option',
  CLICK_SEARCH_RESULT_PAGE_CATEGORIES_FILTER_OPTION:
    'click_search_result_page_categories_filter_option',
  CLICK_SEARCH_RESULT_PAGE_PRICE_PER_NIGHT_FILTER_OPTION:
    'click_search_result_page_price_per_night_filter_option',
  CLICK_SEARCH_RESULT_PAGE_PROPERTY_TYPE_FILTER_OPTION:
    'click_search_result_page_property_type_filter_option',
  CLICK_SEARCH_RESULT_PAGE_AMENITIES_FILTER_OPTION:
    'click_search_result_page_amenities_filter_option',
  CLICK_SEARCH_RESULT_PAGE_NUMBER_OF_BED_FILTER_OPTION:
    'click_search_result_page_number_of_bed_filter_option',
  CLICK_SEARCH_RESULT_PAGE_NUMBER_OF_BATH_FILTER_OPTION:
    'click_search_result_page_number_of_bath_filter_option',
};

export const getAmenities = (t: Translations['getTranslations']) => ({
  SWIMMING_POOL: {
    label: t('Swimming pool'),
    value: 'Swimming pool',
  },
  ACCESSIBLE_HEIGHT_BED: {
    label: t('Accessible-height bed'),
    value: 'Accessible-height bed',
  },
  ACCESSIBLE_HEIGHT_TOILET: {
    label: t('Accessible-height toilet'),
    value: 'Accessible-height toilet',
  },
  AIR_CONDITIONING: {
    label: t('Air conditioning'),
    value: 'Air conditioning',
  },
  BABYSITTER_RECOMMENDATIONS: {
    label: t('Babysitter recommendations'),
    value: 'Babysitter recommendations',
  },
  BABY_BATH: {
    label: t('Baby bath'),
    value: 'Baby bath',
  },
  BABY_MONITOR: {
    label: t('Baby monitor'),
    value: 'Baby monitor',
  },
  BATHTUB: {
    label: t('Bathtub'),
    value: 'Bathtub',
  },
  BBQ_GRILL: {
    label: t('BBQ grill'),
    value: 'BBQ grill',
  },
  BEACH_ESSENTIALS: {
    label: t('Beach essentials'),
    value: 'Beach essentials',
  },
  BED_LINENS: {
    label: t('Bed linens'),
    value: 'Bed linens',
  },
  BREAKFAST: {
    label: t('Breakfast'),
    value: 'Breakfast',
  },
  CABLE_TV: {
    label: t('Cable TV'),
    value: 'Cable TV',
  },
  CARBON_MONOXIDE_DETECTOR: {
    label: t('Carbon monoxide detector'),
    value: 'Carbon monoxide detector',
  },
  CATS: {
    label: t('Cat(s)'),
    value: 'Cat(s)',
  },
  CHANGING_TABLE: {
    label: t('Changing table'),
    value: 'Changing table',
  },
  CHILDREN_BOOKS_AND_TOYS: {
    label: t('Children’s books and toys'),
    value: 'Children’s books and toys',
  },
  CHILDREN_DINNERWARE: {
    label: t('Children’s dinnerware'),
    value: 'Children’s dinnerware',
  },
  CLEANING_BEFORE_CHECKOUT: {
    label: t('Cleaning before checkout'),
    value: 'Cleaning before checkout',
  },
  COFFEE_MAKER: {
    label: t('Coffee maker'),
    value: 'Coffee maker',
  },
  COOKING_BASICS: {
    label: t('Cooking basics'),
    value: 'Cooking basics',
  },
  CRIB: {
    label: t('Crib'),
    value: 'Crib',
  },
  DISABLED_PARKING_SPOT: {
    label: t('Disabled parking spot'),
    value: 'Disabled parking spot',
  },
  DISHES_AND_SILVERWARE: {
    label: t('Dishes and silverware'),
    value: 'Dishes and silverware',
  },
  DISHWASHER: {
    label: t('Dishwasher'),
    value: 'Dishwasher',
  },
  DOGS: {
    label: t('Dog(s)'),
    value: 'Dog(s)',
  },
  DOORMAN: {
    label: t('Doorman'),
    value: 'Doorman',
  },
  DRYER: {
    label: t('Dryer'),
    value: 'Dryer',
  },
  ELEVATOR_IN_BUILDING: {
    label: t('Elevator in building'),
    value: 'Elevator in building',
  },
  ESSENTIALS: {
    label: t('Essentials'),
    value: 'Essentials',
  },
  ETHERNET_CONNECTION: {
    label: t('Ethernet connection'),
    value: 'Ethernet connection',
  },
  EV_CHARGER: {
    label: t('EV charger'),
    value: 'EV charger',
  },
  EXTRA_PILLOWS_AND_BLANKETS: {
    label: t('Extra pillows and blankets'),
    value: 'Extra pillows and blankets',
  },
  FIREPLACE_GUARDS: {
    label: t('Fireplace guards'),
    value: 'Fireplace guards',
  },
  FIRE_EXTINGUISHER: {
    label: t('Fire extinguisher'),
    value: 'Fire extinguisher',
  },
  FIRM_MATTRESS: {
    label: t('Firm mattress'),
    value: 'Firm mattress',
  },
  FIRST_AID_KIT: {
    label: t('First aid kit'),
    value: 'First aid kit',
  },
  FLAT_SMOOTH_PATHWAY_TO_FRONT_DOOR: {
    label: t('Flat smooth pathway to front door'),
    value: 'Flat smooth pathway to front door',
  },
  FREE_PARKING_ON_PREMISES: {
    label: t('Free parking on premises'),
    value: 'Free parking on premises',
  },
  GAME_CONSOLE: {
    label: t('Game console'),
    value: 'Game console',
  },
  GARDEN_OR_BACKYARD: {
    label: t('Garden or backyard'),
    value: 'Garden or backyard',
  },
  GRAB_RAILS_FOR_SHOWER_AND_TOILET: {
    label: t('Grab-rails for shower and toilet'),
    value: 'Grab-rails for shower and toilet',
  },
  GYM: {
    label: t('Gym'),
    value: 'Gym',
  },
  HAIR_DRYER: {
    label: t('Hair dryer'),
    value: 'Hair dryer',
  },
  HANGERS: {
    label: t('Hangers'),
    value: 'Hangers',
  },
  HEATING: {
    label: t('Heating'),
    value: 'Heating',
  },
  HIGH_CHAIR: {
    label: t('High chair'),
    value: 'High chair',
  },
  HOT_TUB: {
    label: t('Hot tub'),
    value: 'Hot tub',
  },
  HOT_WATER: {
    label: t('Hot water'),
    value: 'Hot water',
  },
  INDOOR_FIREPLACE: {
    label: t('Indoor fireplace'),
    value: 'Indoor fireplace',
  },
  INTERNET: {
    label: t('Internet'),
    value: 'Internet',
  },
  IRON: {
    label: t('Iron'),
    value: 'Iron',
  },
  KITCHEN: {
    label: t('Kitchen'),
    value: 'Kitchen',
  },
  LAPTOP_FRIENDLY_WORKSPACE: {
    label: t('Laptop friendly workspace'),
    value: 'Laptop friendly workspace',
  },
  LONG_TERM_STAYS_ALLOWED: {
    label: t('Long term stays allowed'),
    value: 'Long term stays allowed',
  },
  LUGGAGE_DROPOFF_ALLOWED: {
    label: t('Luggage dropoff allowed'),
    value: 'Luggage dropoff allowed',
  },
  MICROWAVE: {
    label: t('Microwave'),
    value: 'Microwave',
  },
  OTHER_PET: {
    label: t('Other pet(s)'),
    value: 'Other pet(s)',
  },
  OUTLET_COVERS: {
    label: t('Outlet covers'),
    value: 'Outlet covers',
  },
  OVEN: {
    label: t('Oven'),
    value: 'Oven',
  },
  PACK_N_PLAY_TRAVEL_CRIB: {
    label: t('Pack ’n Play/travel crib'),
    value: 'Pack ’n Play/travel crib',
  },
  PATH_TO_ENTRANCE_LIT_AT_NIGHT: {
    label: t('Path to entrance lit at night'),
    value: 'Path to entrance lit at night',
  },
  PATIO_OR_BALCONY: {
    label: t('Patio or balcony'),
    value: 'Patio or balcony',
  },
  PETS_ALLOWED: {
    label: t('Pets allowed'),
    value: 'Pets allowed',
  },
  PETS_LIVE_ON_THIS_PROPERTY: {
    label: t('Pets live on this property'),
    value: 'Pets live on this property',
  },
  POCKET_WIFI: {
    label: t('Pocket wifi'),
    value: 'Pocket wifi',
  },
  POOL: {
    label: t('Pool'),
    value: 'Pool',
  },
  PRIVATE_ENTRANCE: {
    label: t('Private entrance'),
    value: 'Private entrance',
  },
  REFRIGERATOR: {
    label: t('Refrigerator'),
    value: 'Refrigerator',
  },
  ROLL_IN_SHOWER_WITH_SHOWER_BENCH_OR_CHAIR: {
    label: t('Roll-in shower with shower bench or chair'),
    value: 'Roll-in shower with shower bench or chair',
  },
  ROOM_DARKENING_SHADES: {
    label: t('Room-darkening shades'),
    value: 'Room-darkening shades',
  },
  SHAMPOO: {
    label: t('Shampoo'),
    value: 'Shampoo',
  },
  SINGLE_LEVEL_HOME: {
    label: t('Single level home'),
    value: 'Single level home',
  },
  SMOKE_DETECTOR: {
    label: t('Smoke detector'),
    value: 'Smoke detector',
  },
  SMOKING_ALLOWED: {
    label: t('Smoking allowed'),
    value: 'Smoking allowed',
  },
  STAIR_GATES: {
    label: t('Stair gates'),
    value: 'Stair gates',
  },
  STEP_FREE_ACCESS: {
    label: t('Step-free access'),
    value: 'Step-free access',
  },
  STOVE: {
    label: t('Stove'),
    value: 'Stove',
  },
  SUITABLE_FOR_CHILDREN: {
    label: t('Suitable for children (2-12 years)'),
    value: 'Suitable for children (2-12 years)',
  },
  SUITABLE_FOR_INFANTS: {
    label: t('Suitable for infants (under 2 years)'),
    value: 'Suitable for infants (under 2 years)',
  },
  TABLE_CORNER_GUARDS: {
    label: t('Table corner guards'),
    value: 'Table corner guards',
  },
  TUB_WITH_SHOWER_BENCH: {
    label: t('Tub with shower bench'),
    value: 'Tub with shower bench',
  },
  TV: {
    label: t('TV'),
    value: 'TV',
  },
  WASHER: {
    label: t('Washer'),
    value: 'Washer',
  },
  WIDE_CLEARANCE_TO_BED: {
    label: t('Wide clearance to bed'),
    value: 'Wide clearance to bed',
  },
  WIDE_CLEARANCE_TO_SHOWER_AND_TOILET: {
    label: t('Wide clearance to shower and toilet'),
    value: 'Wide clearance to shower and toilet',
  },
  WIDE_DOORWAY: {
    label: t('Wide doorway'),
    value: 'Wide doorway',
  },
  WIDE_HALLWAY_CLEARANCE: {
    label: t('Wide hallway clearance'),
    value: 'Wide hallway clearance',
  },
  WINDOW_GUARDS: {
    label: t('Window guards'),
    value: 'Window guards',
  },
  WIRELESS_INTERNET: {
    label: t('Wireless Internet'),
    value: 'Wireless Internet',
  },
});

export const getNewAmenities = (t: Translations['getTranslations']) => ({
  'Accessible-height bed': {
    label: t('Accessible-height bed'),
    value: 'Accessible-height bed',
  },
  'Accessible-height toilet': {
    label: t('Accessible-height toilet'),
    value: 'Accessible-height toilet',
  },
  'Disabled parking spot': {
    label: t('Disabled parking spot'),
    value: 'Disabled parking spot',
  },
  'Grab-rails for shower and toilet': {
    label: t('Grab-rails for shower and toilet'),
    value: 'Grab-rails for shower and toilet',
  },
  'Grab-rails in toilet': {
    label: t('Grab-rails in toilet'),
    value: 'Grab-rails in toilet',
  },
  'Path to entrance lit at night': {
    label: t('Path to entrance lit at night'),
    value: 'Path to entrance lit at night',
  },
  'Roll-in shower with shower bench or chair': {
    label: t('Roll-in shower with shower bench or chair'),
    value: 'Roll-in shower with shower bench or chair',
  },
  'Shower bench': {
    label: t('Shower bench'),
    value: 'Shower bench',
  },
  'Shower chair': {
    label: t('Shower chair'),
    value: 'Shower chair',
  },
  'Single level home': {
    label: t('Single level home'),
    value: 'Single level home',
  },
  'Step-free access': {
    label: t('Step-free access'),
    value: 'Step-free access',
  },
  'Tub with shower bench': {
    label: t('Tub with shower bench'),
    value: 'Tub with shower bench',
  },
  'Wheelchair accessible': {
    label: t('Wheelchair accessible'),
    value: 'Wheelchair accessible',
  },
  'Wide clearance to bed': {
    label: t('Wide clearance to bed'),
    value: 'Wide clearance to bed',
  },
  'Wide clearance to shower and toilet': {
    label: t('Wide clearance to shower and toilet'),
    value: 'Wide clearance to shower and toilet',
  },
  'Wide doorway': {
    label: t('Wide doorway'),
    value: 'Wide doorway',
  },
  'Wide hallway clearance': {
    label: t('Wide hallway clearance'),
    value: 'Wide hallway clearance',
  },
  'Body soap': {
    label: t('Body soap'),
    value: 'Body soap',
  },
  'Cleaning products': {
    label: t('Cleaning products'),
    value: 'Cleaning products',
  },
  Conditioner: {
    label: t('Conditioner'),
    value: 'Conditioner',
  },
  'Hot water': {
    label: t('Hot water'),
    value: 'Hot water',
  },
  Shampoo: {
    label: t('Shampoo'),
    value: 'Shampoo',
  },
  'Shower gel': {
    label: t('Shower gel'),
    value: 'Shower gel',
  },
  'Towels provided': {
    label: t('Towels provided'),
    value: 'Towels provided',
  },
  'Clothing storage': {
    label: t('Clothing storage'),
    value: 'Clothing storage',
  },
  'Coin Laundry': {
    label: t('Coin Laundry'),
    value: 'Coin Laundry',
  },
  'Dryer in common space': {
    label: t('Dryer in common space'),
    value: 'Dryer in common space',
  },
  'Mosquito net': {
    label: t('Mosquito net'),
    value: 'Mosquito net',
  },
  'Washer in common space': {
    label: t('Washer in common space'),
    value: 'Washer in common space',
  },
  'DVD player': {
    label: t('DVD player'),
    value: 'DVD player',
  },
  'Foosball table': {
    label: t('Foosball table'),
    value: 'Foosball table',
  },
  'Game room': {
    label: t('Game room'),
    value: 'Game room',
  },
  Piano: {
    label: t('Piano'),
    value: 'Piano',
  },
  'Ping pong table': {
    label: t('Ping pong table'),
    value: 'Ping pong table',
  },
  'Pool table': {
    label: t('Pool table'),
    value: 'Pool table',
  },
  'Sound system': {
    label: t('Sound system'),
    value: 'Sound system',
  },
  'Baby bath': {
    label: t('Baby bath'),
    value: 'Baby bath',
  },
  'Baby monitor': {
    label: t('Baby monitor'),
    value: 'Baby monitor',
  },
  'Babysitter recommendations': {
    label: t('Babysitter recommendations'),
    value: 'Babysitter recommendations',
  },
  Bathtub: {
    label: t('Bathtub'),
    value: 'Bathtub',
  },
  'Board games': {
    label: t('Board games'),
    value: 'Board games',
  },
  'Changing table': {
    label: t('Changing table'),
    value: 'Changing table',
  },
  'Children’s books and toys': {
    label: t('Children’s books and toys'),
    value: 'Children’s books and toys',
  },
  Crib: {
    label: t('Crib'),
    value: 'Crib',
  },
  'Family/kid friendly': {
    label: t('Family/kid friendly'),
    value: 'Family/kid friendly',
  },
  'Fireplace guards': {
    label: t('Fireplace guards'),
    value: 'Fireplace guards',
  },
  'Game console': {
    label: t('Game console'),
    value: 'Game console',
  },
  'High chair': {
    label: t('High chair'),
    value: 'High chair',
  },
  'Outlet covers': {
    label: t('Outlet covers'),
    value: 'Outlet covers',
  },
  'Pack ’n Play/travel crib': {
    label: t('Pack ’n Play/travel crib'),
    value: 'Pack ’n Play/travel crib',
  },
  'Room-darkening shades': {
    label: t('Room-darkening shades'),
    value: 'Room-darkening shades',
  },
  'Stair gates': {
    label: t('Stair gates'),
    value: 'Stair gates',
  },
  'Table corner guards': {
    label: t('Table corner guards'),
    value: 'Table corner guards',
  },
  'Window guards': {
    label: t('Window guards'),
    value: 'Window guards',
  },
  'Portable fans': {
    label: t('Portable fans'),
    value: 'Portable fans',
  },
  'Carbon monoxide detector': {
    label: t('Carbon monoxide detector'),
    value: 'Carbon monoxide detector',
  },
  'Emergency exit': {
    label: t('Emergency exit'),
    value: 'Emergency exit',
  },
  'Fire extinguisher': {
    label: t('Fire extinguisher'),
    value: 'Fire extinguisher',
  },
  'First aid kit': {
    label: t('First aid kit'),
    value: 'First aid kit',
  },
  'Smoke detector': {
    label: t('Smoke detector'),
    value: 'Smoke detector',
  },
  'Baking sheet': {
    label: t('Baking sheet'),
    value: 'Baking sheet',
  },
  'Barbeque utensils': {
    label: t('Barbeque utensils'),
    value: 'Barbeque utensils',
  },
  Blender: {
    label: t('Blender'),
    value: 'Blender',
  },
  Breakfast: {
    label: t('Breakfast'),
    value: 'Breakfast',
  },
  Coffee: {
    label: t('Coffee'),
    value: 'Coffee',
  },
  'Coffee maker': {
    label: t('Coffee maker'),
    value: 'Coffee maker',
  },
  Cookware: {
    label: t('Cookware'),
    value: 'Cookware',
  },
  'Dining table': {
    label: t('Dining table'),
    value: 'Dining table',
  },
  'Dishes and silverware': {
    label: t('Dishes and silverware'),
    value: 'Dishes and silverware',
  },
  Dishwasher: {
    label: t('Dishwasher'),
    value: 'Dishwasher',
  },
  Freezer: {
    label: t('Freezer'),
    value: 'Freezer',
  },
  'Ice maker': {
    label: t('Ice maker'),
    value: 'Ice maker',
  },
  Kettle: {
    label: t('Kettle'),
    value: 'Kettle',
  },
  Microwave: {
    label: t('Microwave'),
    value: 'Microwave',
  },
  'Mini fridge': {
    label: t('Mini fridge'),
    value: 'Mini fridge',
  },
  Oven: {
    label: t('Oven'),
    value: 'Oven',
  },
  Refrigerator: {
    label: t('Refrigerator'),
    value: 'Refrigerator',
  },
  'Rice maker': {
    label: t('Rice maker'),
    value: 'Rice maker',
  },
  Stove: {
    label: t('Stove'),
    value: 'Stove',
  },
  Toaster: {
    label: t('Toaster'),
    value: 'Toaster',
  },
  'Trash compactor': {
    label: t('Trash compactor'),
    value: 'Trash compactor',
  },
  'Wine glasses': {
    label: t('Wine glasses'),
    value: 'Wine glasses',
  },
  Beach: {
    label: t('Beach'),
    value: 'Beach',
  },
  'Beach Front': {
    label: t('Beach Front'),
    value: 'Beach Front',
  },
  'Beach View': {
    label: t('Beach View'),
    value: 'Beach View',
  },
  'Beach access': {
    label: t('Beach access'),
    value: 'Beach access',
  },
  'City View': {
    label: t('City View'),
    value: 'City View',
  },
  'Desert View': {
    label: t('Desert View'),
    value: 'Desert View',
  },
  Downtown: {
    label: t('Downtown'),
    value: 'Downtown',
  },
  'Garden View': {
    label: t('Garden View'),
    value: 'Garden View',
  },
  'Golf course front': {
    label: t('Golf course front'),
    value: 'Golf course front',
  },
  'Golf view': {
    label: t('Golf view'),
    value: 'Golf view',
  },
  Lake: {
    label: t('Lake'),
    value: 'Lake',
  },
  'Lake Front': {
    label: t('Lake Front'),
    value: 'Lake Front',
  },
  'Lake access': {
    label: t('Lake access'),
    value: 'Lake access',
  },
  'Laundromat nearby': {
    label: t('Laundromat nearby'),
    value: 'Laundromat nearby',
  },
  Mountain: {
    label: t('Mountain'),
    value: 'Mountain',
  },
  'Mountain view': {
    label: t('Mountain view'),
    value: 'Mountain view',
  },
  'Near Ocean': {
    label: t('Near Ocean'),
    value: 'Near Ocean',
  },
  'Ocean Front': {
    label: t('Ocean Front'),
    value: 'Ocean Front',
  },
  Resort: {
    label: t('Resort'),
    value: 'Resort',
  },
  'Resort access': {
    label: t('Resort access'),
    value: 'Resort access',
  },
  Rural: {
    label: t('Rural'),
    value: 'Rural',
  },
  'Sea view': {
    label: t('Sea view'),
    value: 'Sea view',
  },
  'Ski In': {
    label: t('Ski In'),
    value: 'Ski In',
  },
  'Ski Out': {
    label: t('Ski Out'),
    value: 'Ski Out',
  },
  'Ski in/Ski out': {
    label: t('Ski in/Ski out'),
    value: 'Ski in/Ski out',
  },
  Town: {
    label: t('Town'),
    value: 'Town',
  },
  Village: {
    label: t('Village'),
    value: 'Village',
  },
  'Water View': {
    label: t('Water View'),
    value: 'Water View',
  },
  Waterfront: {
    label: t('Waterfront'),
    value: 'Waterfront',
  },
  'Cleaning Disinfection': {
    label: t('Cleaning Disinfection'),
    value: 'Cleaning Disinfection',
  },
  'Cleaning before checkout': {
    label: t('Cleaning before checkout'),
    value: 'Cleaning before checkout',
  },
  Desk: {
    label: t('Desk'),
    value: 'Desk',
  },
  'Enhanced cleaning practices': {
    label: t('Enhanced cleaning practices'),
    value: 'Enhanced cleaning practices',
  },
  'High touch surfaces disinfected': {
    label: t('High touch surfaces disinfected'),
    value: 'High touch surfaces disinfected',
  },
  'Laptop friendly workspace': {
    label: t('Laptop friendly workspace'),
    value: 'Laptop friendly workspace',
  },
  'Long term stays allowed': {
    label: t('Long term stays allowed'),
    value: 'Long term stays allowed',
  },
  'Luggage dropoff allowed': {
    label: t('Luggage dropoff allowed'),
    value: 'Luggage dropoff allowed',
  },
  'BBQ grill': {
    label: t('BBQ grill'),
    value: 'BBQ grill',
  },
  'Beach essentials': {
    label: t('Beach essentials'),
    value: 'Beach essentials',
  },
  'Bicycles available': {
    label: t('Bicycles available'),
    value: 'Bicycles available',
  },
  Bikes: {
    label: t('Bikes'),
    value: 'Bikes',
  },
  'Boat slip': {
    label: t('Boat slip'),
    value: 'Boat slip',
  },
  Doorman: {
    label: t('Doorman'),
    value: 'Doorman',
  },
  'Fire Pit': {
    label: t('Fire Pit'),
    value: 'Fire Pit',
  },
  'Garden or backyard': {
    label: t('Garden or backyard'),
    value: 'Garden or backyard',
  },
  Hammock: {
    label: t('Hammock'),
    value: 'Hammock',
  },
  Kayak: {
    label: t('Kayak'),
    value: 'Kayak',
  },
  'Outdoor kitchen': {
    label: t('Outdoor kitchen'),
    value: 'Outdoor kitchen',
  },
  'Outdoor seating (furniture)': {
    label: t('Outdoor seating (furniture)'),
    value: 'Outdoor seating (furniture)',
  },
  River: {
    label: t('River'),
    value: 'River',
  },
  'Free parking on premises': {
    label: t('Free parking on premises'),
    value: 'Free parking on premises',
  },
  'Free parking on street': {
    label: t('Free parking on street'),
    value: 'Free parking on street',
  },
  Garage: {
    label: t('Garage'),
    value: 'Garage',
  },
  'Paid parking': {
    label: t('Paid parking'),
    value: 'Paid parking',
  },
  'Paid parking off premises': {
    label: t('Paid parking off premises'),
    value: 'Paid parking off premises',
  },
  'Communal pool': {
    label: t('Communal pool'),
    value: 'Communal pool',
  },
  'Indoor pool': {
    label: t('Indoor pool'),
    value: 'Indoor pool',
  },
  'Outdoor pool': {
    label: t('Outdoor pool'),
    value: 'Outdoor pool',
  },
  'Private pool': {
    label: t('Private pool'),
    value: 'Private pool',
  },
  'Rooftop pool': {
    label: t('Rooftop pool'),
    value: 'Rooftop pool',
  },
  'Swimming pool': {
    label: t('Swimming pool'),
    value: 'Swimming pool',
  },
  'Air conditioning': {
    label: t('Air conditioning'),
    value: 'Air conditioning',
  },
  'Bed linens': {
    label: t('Bed linens'),
    value: 'Bed linens',
  },
  'Cable TV': {
    label: t('Cable TV'),
    value: 'Cable TV',
  },
  Dryer: {
    label: t('Dryer'),
    value: 'Dryer',
  },
  Elevator: {
    label: t('Elevator'),
    value: 'Elevator',
  },
  Essentials: {
    label: t('Essentials'),
    value: 'Essentials',
  },
  'Hair dryer': {
    label: t('Hair dryer'),
    value: 'Hair dryer',
  },
  Hangers: {
    label: t('Hangers'),
    value: 'Hangers',
  },
  Heating: {
    label: t('Heating'),
    value: 'Heating',
  },
  'Indoor fireplace': {
    label: t('Indoor fireplace'),
    value: 'Indoor fireplace',
  },
  Internet: {
    label: t('Internet'),
    value: 'Internet',
  },
  Iron: {
    label: t('Iron'),
    value: 'Iron',
  },
  Kitchen: {
    label: t('Kitchen'),
    value: 'Kitchen',
  },
  'Patio or balcony': {
    label: t('Patio or balcony'),
    value: 'Patio or balcony',
  },
  TV: {
    label: t('TV'),
    value: 'TV',
  },
  Washer: {
    label: t('Washer'),
    value: 'Washer',
  },
  'Wireless Internet': {
    label: t('Wireless Internet'),
    value: 'Wireless Internet',
  },
  'Ceiling fan': {
    label: t('Ceiling fan'),
    value: 'Ceiling fan',
  },
  'EV charger': {
    label: t('EV charger'),
    value: 'EV charger',
  },
  'Extra pillows and blankets': {
    label: t('Extra pillows and blankets'),
    value: 'Extra pillows and blankets',
  },
  'Pocket Wifi': {
    label: t('Pocket Wifi'),
    value: 'Pocket Wifi',
  },
  'Private entrance': {
    label: t('Private entrance'),
    value: 'Private entrance',
  },
  Safe: {
    label: t('Safe'),
    value: 'Safe',
  },
  'Stereo system': {
    label: t('Stereo system'),
    value: 'Stereo system',
  },
  Gym: {
    label: t('Gym'),
    value: 'Gym',
  },
  'Hot tub': {
    label: t('Hot tub'),
    value: 'Hot tub',
  },
  Spa: {
    label: t('Spa'),
    value: 'Spa',
  },
  Sauna: {
    label: t('Sauna'),
    value: 'Sauna',
  },
  Casinos: {
    label: t('Casinos'),
    value: 'Casinos',
  },
  Cycling: {
    label: t('Cycling'),
    value: 'Cycling',
  },
  Fishing: {
    label: t('Fishing'),
    value: 'Fishing',
  },
  'Golf - Optional': {
    label: t('Golf - Optional'),
    value: 'Golf - Optional',
  },
  'Horseback Riding': {
    label: t('Horseback Riding'),
    value: 'Horseback Riding',
  },
  'Mountain Climbing': {
    label: t('Mountain Climbing'),
    value: 'Mountain Climbing',
  },
  Museums: {
    label: t('Museums'),
    value: 'Museums',
  },
  'Rock Climbing': {
    label: t('Rock Climbing'),
    value: 'Rock Climbing',
  },
  Shopping: {
    label: t('Shopping'),
    value: 'Shopping',
  },
  'Theme Parks': {
    label: t('Theme Parks'),
    value: 'Theme Parks',
  },
  'Water Parks': {
    label: t('Water Parks'),
    value: 'Water Parks',
  },
  'Water Sports': {
    label: t('Water Sports'),
    value: 'Water Sports',
  },
  Zoo: {
    label: t('Zoo'),
    value: 'Zoo',
  },
});

export const getAllAmenities = (t: Translations['getTranslations']) => ({
  ...getAmenities(t),
  ...getNewAmenities(t),
});

export const getPropertyTypes = (t: Translations['getTranslations']) => ({
  APARTMENT: {
    label: t('Apartment'),
    value: 'Apartment',
  },
  HOUSE: {
    label: t('House'),
    value: 'House',
  },
  LOFT: {
    label: t('Loft'),
    value: 'Loft',
  },
  BOAT: {
    label: t('Boat'),
    value: 'Boat',
  },
  CAMPER_RV: {
    label: t('Camper/RV'),
    value: 'Camper/RV',
  },
  CONDOMINIUM: {
    label: t('Condominium'),
    value: 'Condominium',
  },
  CHALET: {
    label: t('Chalet'),
    value: 'Chalet',
  },
  BED_Breakfast: {
    label: t('Bed & Breakfast'),
    value: 'Bed & Breakfast',
  },
  VILLA: {
    label: t('Villa'),
    value: 'Villa',
  },
  TENT: {
    label: t('Tent'),
    value: 'Tent',
  },
  OTHER: {
    label: t('Other'),
    value: 'Other',
  },
  CABIN: {
    label: t('Cabin'),
    value: 'Cabin',
  },
  TOWNHOUSE: {
    label: t('Townhouse'),
    value: 'Townhouse',
  },
  BUNGALOW: {
    label: t('Bungalow'),
    value: 'Bungalow',
  },
  HUT: {
    label: t('Hut'),
    value: 'Hut',
  },
  DORM: {
    label: t('Dorm'),
    value: 'Dorm',
  },
  PARKING_SPACE: {
    label: t('Parking Space'),
    value: 'Parking Space',
  },
  PLANE: {
    label: t('Plane'),
    value: 'Plane',
  },
  TREEHOUSE: {
    label: t('Treehouse'),
    value: 'Treehouse',
  },
  YURT: {
    label: t('Yurt'),
    value: 'Yurt',
  },
  TIPI: {
    label: t('Tipi'),
    value: 'Tipi',
  },
  IGLOO: {
    label: t('Igloo'),
    value: 'Igloo',
  },
  EARTH_HOUSE: {
    label: t('Earth House'),
    value: 'Earth House',
  },
  ISLAND: {
    label: t('Island'),
    value: 'Island',
  },
  CAVE: {
    label: t('Cave'),
    value: 'Cave',
  },
  CASTLE: {
    label: t('Castle'),
    value: 'Castle',
  },
  STUDIO: {
    label: t('Studio'),
    value: 'Studio',
  },
  COTTAGE: {
    label: t('Cottage'),
    value: 'Cottage',
  },
});

export const DUDA_IFRAME_SETTINGS_CLOSE_BUTTON =
  '#widgetEditorWrapper .widgetCloseBtn';

export default {
  allAmenities,
  DIO_EVENTS,
  getAllAmenities,
};

import React, { FC } from 'react';
import cn from 'classnames';

import { Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';

import TTheme from '../../common/types';
import { Amenity } from './Amenity';

const useStyles = createStyles((theme: TTheme) => ({
  amenities: {
    position: 'absolute',
    top: theme.spacer(4),
    left: theme.spacer(3),
    display: 'flex',
    gap: theme.spacer(2),
  }
}));

interface IAmenitiesProps {
  amenities: string[];
  className?: string;
}

export const Amenities: FC<IAmenitiesProps> = ({ amenities, className }) => {
  const classes = useStyles();
  return (
    <Row className={cn(classes.amenities, className)}>
      {amenities?.slice(0, 2).map((amenity) => <Amenity key={amenity} name={amenity} />) }
    </Row>
)};

export default Amenities;

import React, { FC, useContext } from 'react';
import cn from 'classnames';

import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

import TTheme, { IPrice } from '../../common/types';
import { WebsiteSettingsContext } from '../../context/SettingsProvider';
import Translations from '../../translations';

const useStyles = createStyles((theme: TTheme) => ({
  price: {
    width: '30%',
    minWidth: 140,

    '& .startingFrom': {
      color: theme.palette.text.secondaryText,
    },
  },
}));

interface IPriceProps {
  className?: string,
  currencySymbol: string,
  prices: IPrice,
  locale: string,
}

export const Price: FC<IPriceProps> = ({ className, currencySymbol, prices }) => {
  const classes = useStyles();
  const { appData } = useContext(WebsiteSettingsContext);
  const t = Translations.getInstance(appData.locale).getTranslations.bind(Translations.getInstance(appData.locale));
  return (<Col className={cn(classes.price, className )}>
    <div>
      <TextField className="price" variant="h2" align="right">
        {`${currencySymbol}${Math.floor(prices?.basePrice)?.toLocaleString(appData.locale)}`}
      </TextField>
      <TextField variant="h7" align="right" className="startingFrom">{t('Starting from')}</TextField>
    </div>
  </Col>)
};

export default Price;

import React, { useRef, useState } from 'react';
import noop from 'lodash/noop';

import { Menu } from '@guestyci/foundation/Menu';
import createStyles from '@guestyci/foundation/createStyles';
import DropdownLabelInput from '@guestyci/foundation/Dropdown/DropdownLabelInput';

import TTheme from '../../common/types';

const useStyles = createStyles((theme: TTheme) => ({
  rotate: {
    transform: 'rotate(90deg)',
  },
  button: {
    border: `1px solid ${theme.palette.uiBorder}`,
    padding: theme.spacer(2),
    borderRadius: theme.spacer(1),
    cursor: 'pointer',
    color: theme.palette.text.default,
    position: 'relative',
    height: '100%',
    width: '100%',
    '& [class*="active"]': {
      color: theme.palette.text.default,
    },
    '& div[class*=row-wrapper] > div[class*=row-wrapper] > div[class*=text-field-wrapper]': {
      whiteSpace: 'nowrap',
    }
  },
  menuList: {
    maxHeight: 'min(calc(50vh - 44px), 260px)',
    padding: 0,
    [theme.breakpoints.create('sm')]: {
      maxHeight: 260,
    },
  },
}));

interface IDropdownMenu {
  children: React.ReactNode;
  className?: string;
  placeholder: string;
  onClose?: (e: React.MouseEvent<HTMLLIElement>) => void;
}

export const DropdownMenu = ({
  children,
  className,
  placeholder,
  onClose = noop,
}: IDropdownMenu) => {
  const classes = useStyles();
  const menuAnchor = useRef();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOnClose = (e: React.MouseEvent<HTMLLIElement>)  => {
    setOpen(false);
    onClose(e);
  };

  return (
    <div className={className}>
      <DropdownLabelInput placeholder={placeholder} open={open} className={classes.button} onClick={handleClick} color="secondary" ref={menuAnchor} />
      <Menu
        anchor={menuAnchor}
        open={open}
        onClose={handleOnClose}
        variant="menu"
        fitAnchor
        originDirection="bottom"
      >
        <Menu
          variant="list"
          elevation={0}
          className={classes.menuList}
        >
          {children}
        </Menu>
      </Menu>
    </div>
  );
};

export default DropdownMenu;

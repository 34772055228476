import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { IAppData } from './common/types';
import LandingPage from './pages/LandingPage';
import WebsiteSettingsContextProvider from './context/SettingsProvider';
import useDio from './hooks/useDio';

const queryClient = new QueryClient();

const App = ({ data }: { data: IAppData }) => {
  const { initDio } = useDio();

  useEffect(() => {
    initDio();
  }, [initDio]);

  return (<QueryClientProvider client={queryClient}>
    <WebsiteSettingsContextProvider appData={data}>
      <LandingPage />
    </WebsiteSettingsContextProvider>
  </QueryClientProvider>)
};

export default App;

import { useCallback } from 'react';
import { isEmpty } from 'lodash';
// @ts-ignore
import Dio from '@guestyci/dio';
import Resource from '@guestyci/agni';

const GUESTY_WEBSITES_CONTEXT = 'guesty-websites';

const { config, env } = Resource.create();

const mock = {
  track: (key: string, type: string, target: any) =>
    console.table({ key, type, target }),
};

interface DioType {
  guestyWebsites?: {
    track: (key: string, type: string, target: any) => void;
  };
}

const dio: DioType = {};

const useDio = () => {
  const initDio = useCallback(() => {
    if (env !== 'production') {
      console.warn(
        'Dio is not initialized in development. Would only send mock events.'
      );
    }
    if (!isEmpty(dio.guestyWebsites)) return;
    dio.guestyWebsites =
      env === 'production'
        ? Dio.init({
            context: GUESTY_WEBSITES_CONTEXT,
            baseUrl: config.API_GATEWAY,
          })
        : mock;
  }, []);

  const dioTrack = useCallback(
    ({
      eventName,
      eventType = 'click',
      properties = {},
    }: {
      eventName: string;
      eventType?: string;
      properties: any;
    }) => {
      dio?.guestyWebsites?.track(eventName, eventType, {
        ...properties,
      });
    },
    []
  );

  return {
    initDio,
    dioTrack,
  };
};

export default useDio;

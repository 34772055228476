import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { QUERY_IDS } from '../api';
import {
  getFilterSettings,
  IWidgetParams,
} from '../api/widgetSettings/getFilterSettings';
import { IFilterSettings } from '../common/types';

const { FILTER_SETTING } = QUERY_IDS;

interface IUseGetFilterSettings {
  data: IFilterSettings;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  refetch: () => Promise<UseQueryResult>;
}

const useGetFilterSettings = ({
  widgetId,
  websiteId,
  elementId,
  lang,
}: IWidgetParams): IUseGetFilterSettings => {
  const { data, error, isLoading, isFetching, isSuccess, isError, refetch } =
    useQuery({
      queryKey: [FILTER_SETTING],
      queryFn: () =>
        getFilterSettings({ widgetId, websiteId, elementId, lang }),
      retry: false,
      refetchOnWindowFocus: false,
    });

  return {
    data,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  };
};

export default useGetFilterSettings;

import { FC, useContext } from 'react';
import cn from 'classnames';

import Spinner from '@guestyci/foundation/Spinner'
import createStyles from '@guestyci/foundation/createStyles';

import { Listing } from './Listing';
import TTheme from '../common/types';
import { WebsiteSettingsContext } from '../context/SettingsProvider';

interface IListingProps {
  isGridView: boolean;
  onClick: (id: string) => void;
}

const useStyles = createStyles((theme: TTheme) => ({
  root: {
    width: '100%',
    '&.gridView': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacer(6),
      marginTop: theme.spacer(4),
      justifyContent: 'center',

      '@media (min-width: 0) and (max-width: 695px)': {
        flexBasis: '33%',
      },
      '@media (min-width: 696px) and (min-width: 1023px)': {
        justifyContent: 'center',
      },
      '@media (min-width: 1023px)': {
        justifyContent: 'space-between',
      },
    }
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 'calc(50% - 25px)',
    top: 'calc(50vh - 25px)',
  }
}));

export const Listings: FC<IListingProps> = ({ isGridView, onClick }) => {
  const { listings, isListingLoading } = useContext(WebsiteSettingsContext);
  const { root, loader } = useStyles();

  if (isListingLoading) {
    return <Spinner className={loader} />;
  }

  return (<div className={cn(root, { gridView: isGridView })}>
    {listings?.map((listing) => <Listing onClick={() => onClick(listing?._id)} isGridView={isGridView} key={listing._id} listing={listing} />)}
  </div>)
};

export default Listings;

import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import { IAppData, IOptionTag, IWidgetSettings } from './types';
import { IWidgetParams } from '../api/widgetSettings/getListings';
import {
  getAllAmenities,
  getPropertyTypes,
  supportedLanguages,
} from './constants';
import Translations from '../translations';

export const getWidgetParams = (data: IAppData): IWidgetParams => ({
  websiteId: data.siteId, // websiteId
  elementId: Number(data?.elementId),
  widgetId: data?.widgetId,
  lang: data?.locale, // lang
});

export const formatOptions = (options: string[]) =>
  options?.map((value) => ({
    value,
    label: startCase(camelCase(value)),
  }));

export const formatOptionsToArray = (
  options: { value: string; label: string }[]
): string[] => options?.map((option) => option?.value);

function isPrimitiveWithValue(value: any) {
  return typeof value !== 'object' && value !== null && value !== undefined;
}

export const removeEmptyFields = (
  obj: Record<string, any>
): Record<string, any> => {
  const result: Record<string, any> = {};
  reduce(
    obj,
    (acc, val, key) => {
      if (
        (key && !isPrimitiveWithValue(val) && !isEmpty(val)) ||
        isPrimitiveWithValue(val)
      ) {
        acc[key] = val;
      }
      return acc;
    },
    result
  );

  return result;
};

interface IRedirectParams {
  params: Record<string, any>;
  widgetSettings: IWidgetSettings;
  locale: string;
  id?: string;
}

const getEntityIds = ({
  options,
  locale,
  getOptions,
}: {
  options: { value: string; label: string }[];
  locale: string;
  getOptions: (
    t: (key: string) => string
  ) => Record<string, { label: string; value: string }>;
}) => {
  const t = Translations.getInstance(locale).getTranslations.bind(
    Translations.getInstance(locale)
  );
  const allOptions = getOptions(t);
  const formattedOptions = formatOptionsToArray(options);

  return formattedOptions?.reduce((acc, item: string) => {
    const translation = Object.entries(allOptions).find(
      ([, value]) => value.label === item
    );
    if (translation?.length) {
      acc.push(translation[1]?.value);
    }
    return acc;
  }, [] as string[]);
};

export const getRedirectUrl = ({
  params,
  widgetSettings,
  locale,
  id = '',
}: IRedirectParams) => {
  const formattedParams = removeEmptyFields({
    ...params,
    includeAmenities: getEntityIds({
      options: params.includeAmenities,
      locale,
      getOptions: getAllAmenities,
    }),
    propertyType: getEntityIds({
      options: [params.propertyType],
      locale,
      getOptions: getPropertyTypes,
    }),
    tags: params.tags?.map((tag: IOptionTag) => tag.id),
  });
  const searchParams = new URLSearchParams(formattedParams);
  const query = searchParams.toString();

  if (!widgetSettings.url) {
    return '';
  }

  return `//${widgetSettings.url}/${locale}/properties${id ? `/${id}` : ''}${
    query ? `?${query}` : ''
  }`;
};

export const getSupportedLocales = (locale: string) => {
  if (!locale || !supportedLanguages.includes(locale)) {
    return 'en';
  }

  return locale;
};

export default {
  getWidgetParams,
  formatOptions,
  formatOptionsToArray,
  getRedirectUrl,
  getSupportedLocales,
};

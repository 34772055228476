import React, { FC, useContext } from 'react';
import cn from 'classnames';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import DropdownLabelInput from '@guestyci/foundation/Dropdown/DropdownLabelInput';

import TTheme, { getSortOptions } from '../../../common/types';
import { IFormPropsValues } from '../TopFiltersGroup/TopFiltersGroup';
import { WebsiteSettingsContext } from '../../../context/SettingsProvider';
import Translations from '../../../translations';
import useDio from '../../../hooks/useDio';
import { DIO_EVENTS } from '../../../common/constants';

interface ISearchFilterProps {
  options: string[];
  locale: string;
  onSelect: (params: IFormPropsValues) => void;
  onClose: (params: IFormPropsValues) => void;
  className?: string;
}

const useStyles = createStyles((theme: TTheme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  option: {
    color: theme.palette.text.default,
  },
  inputLabel: {
    '& [class*="active"]': {
      color: theme.palette.text.default,
    },
  },
  dropdown: {
    fontSize: theme.typography.fontSize.h5,
  },
}));

export const SearchByFilter: FC<ISearchFilterProps> = ({ onClose, onSelect, className }) => {
  const classes = useStyles();
  const { dioTrack } = useDio();
  const { appData, widgetSettings: { options: { sortSettings } }, dioSettings } = useContext(WebsiteSettingsContext);
  const sortOptions = getSortOptions(appData?.locale);
  const t = Translations.getInstance(appData.locale).getTranslations.bind(Translations.getInstance(appData.locale));
  const mappedSortOptions = sortSettings?.map((option) => sortOptions[option]);
  const handleSetSortOrder = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;
    if (!value) {
      return e.stopPropagation();
    }
    const params = { sortBy: value.sortBy, sortOrder: value.sortOrder };
    onSelect(params);

    dioTrack({
      eventName: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_SORT_BY_OPTION,
      properties: {
        ...dioSettings,
        sort_by_choice: params,
      }
    });
  };

  if (!mappedSortOptions?.length) {
    return <div className={cn(classes.root, className)} />;
  }

  return (
    <div className={cn(classes.root, className)}>
      <Dropdown
        className={classes.dropdown}
        dataKey="label"
        renderSelected="label"
        placeholder={t('Sort by')}
        input={<DropdownLabelInput className={classes.inputLabel} />}
        onChange={handleSetSortOrder}
        onClose={onClose}
      >
        {mappedSortOptions?.map((option) => (
          <Option value={option} key={option.label}>
            <TextField variant="h5" className={classes.option}>{option.label}</TextField>
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default SearchByFilter;

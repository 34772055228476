import { FC } from 'react';

import { Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';
import Divider from '@guestyci/foundation/Divider';

import { ReactComponent as GridView } from '../../assets/grid_view.svg';
import { ReactComponent as GridViewNa } from '../../assets/grid_view_na.svg';
import { ReactComponent as ListView } from '../../assets/list_view.svg';
import { ReactComponent as ListViewNa } from '../../assets/list_view_na.svg';
import TTheme from '../../common/types';

interface ILayoutSwitcherProps {
  toggled: boolean;
  toggleGrid: () => void;
  toggleList: () => void;
}

const useStyles = createStyles((theme: TTheme) => ({
  separator: {
    borderRight: `1px solid ${theme.palette.secondary.background}`,
    width: 1,
    marginRight: 7,
    marginLeft: 7,
    height: 16,
  },
  icon: {
    cursor: 'pointer',
  }
}));

export const LayoutSwitcher: FC<ILayoutSwitcherProps> = ({ toggled = false, toggleGrid, toggleList }) => {
  const GridIcon = toggled ? GridView : GridViewNa;
  const ListIcon = toggled ? ListView : ListViewNa;
  const { separator, icon } = useStyles();

  return (
    <Row align="center">
      <GridIcon className={icon} onClick={toggleGrid} />
      {/* @ts-ignore */}
      <Divider className={separator} />
      <ListIcon className={icon} onClick={toggleList} />
    </Row>
  );
};

export default LayoutSwitcher;

import React, { useContext } from 'react';
import reduce from 'lodash/reduce';
import cn from 'classnames';
import createStyles from '@guestyci/foundation/createStyles';
import Tooltip from '@guestyci/foundation/Tooltip';
import { allAmenities } from '../../common/constants';
import TTheme from '../../common/types';
import Translations from '../../translations';
import { WebsiteSettingsContext } from '../../context/SettingsProvider';

interface IAmenityProps {
  name: string;
}

interface IIcon {
  icon: string;
}

interface IconProps {
  icon: string;
  className?: string;
  size?: string;
  onClick?: () => void;
}

const useStyles = createStyles((theme: TTheme) => ({
  img: {
    width: '1em',
    height: '1em',
    minWidth: '1em',
    minHeight: '1em',
    fontWeight: 'bold',
    fontSize: ({ fontSize }: { fontSize: number }) => fontSize,
  },
  tooltip: {
    backgroundColor: theme.palette.background.white,
    color: theme.palette.text.primary,
  },
  amenity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    minWidth: 24,
    height: 24,
    borderRadius: theme.spacer(3),
    padding: `${theme.spacer(2)}px ${theme.spacer(5)}px`,
    backgroundColor: '#fff',
    border: '1px solid #fff',
    opacity: 0.7,

    '& > img': {
      minWidth: 24,
      minHeight: 24,
    },
  },
}));

const requiredIconMap = reduce<IIcon, Record<string, string>>(
  allAmenities,
  (acc, amenity) => {
    const { icon } = amenity;
    try {
      // eslint-disable-next-line import/no-dynamic-require
      acc[icon] = require(`../../assets/amenities/${icon}.svg`);
    } catch (error) {
      console.error(`SVG not found: ${icon}`);
    }
    return acc;
  },
  {}
);

const Icon: React.FC<IconProps> = ({ icon, className, size = 24 }) => {
  const { img } = useStyles({ fontSize: size });
  const iconUrl = requiredIconMap[icon];

  if (!iconUrl) return null;

  return <img className={cn(img, className)} src={iconUrl} alt={icon} width={size} height={size} />;
};

export const Amenity: React.FC<IAmenityProps> = ({ name }) => {
  const classes = useStyles();
  const { appData } = useContext(WebsiteSettingsContext);
  const t = Translations.getInstance(appData?.locale).getTranslations.bind(
    Translations.getInstance(appData?.locale)
  );
  const icon = allAmenities.find((amenity) => amenity.text === name)?.icon;
  if (!name || !icon) return null;

  return (
    <div className={classes.amenity}>
    {/* @ts-ignore */}
      <Tooltip
        className={classes.tooltip}
        body={t(name)}
        placement="top"
      >
        <Icon icon={icon} />
    </Tooltip>
  </div>)
};

export default Amenity;

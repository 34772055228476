import toUpper from 'lodash/toUpper';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { QUERY_IDS } from '../api';
import {
  getWidgetSettings,
  IWidgetParams,
} from '../api/widgetSettings/getWidgetSettings';
import { IAppData, IWidgetSettings } from '../common/types';

const { WIDGET_SETTINGS } = QUERY_IDS;

const formatToUpperSnakeCase = (options: string[] = []) =>
  options.map((option) => toUpper(option).replace(/\s/, '_'));

interface IUseGetWidgetSettings {
  data: IWidgetSettings;
  appData: IAppData;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  refetch: () => Promise<UseQueryResult>;
}

const useGetWidgetSettings = ({
  widgetId,
  websiteId,
  elementId,
  lang,
}: IWidgetParams): IUseGetWidgetSettings => {
  const { data, error, isLoading, isFetching, isSuccess, isError, refetch } =
    useQuery({
      queryKey: [WIDGET_SETTINGS],
      queryFn: () =>
        getWidgetSettings({ widgetId, websiteId, elementId, lang }),
      retry: false,
      refetchOnWindowFocus: false,
      select: (settings) => ({
        ...settings,
        filterSettings: {
          ...settings.filterSettings,
          amenities: formatToUpperSnakeCase(
            settings?.filterSettings?.amenities
          ),
        },
        options: {
          ...settings.options,
          amenities: formatToUpperSnakeCase(settings?.options?.amenities),
        },
        searchSettings: {
          ...settings.searchSettings,
          amenities: formatToUpperSnakeCase(
            settings?.searchSettings?.amenities
          ),
        },
      }),
    });

  return {
    data,
    appData: {
      siteId: websiteId,
      elementId: String(elementId),
      widgetId: String(widgetId),
      locale: lang,
    },
    isFetching,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  };
};

export default useGetWidgetSettings;

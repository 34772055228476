import React, { FC } from 'react';

import { IListing } from '../common/types';
import { ListingGrid } from './Listing/ListingGrid';
import { ListingList } from './Listing/ListingList';

interface IListingProps {
  listing: IListing,
  isGridView: boolean,
  onClick: () => void;
}

export const Listing: FC<IListingProps> = ({ onClick, listing, isGridView = true }) => {
  const ListingComponent = isGridView ? ListingGrid : ListingList;

  return <ListingComponent onClick={onClick} listing={listing} />;
};

export default Listing;
